import React, { useState } from "react";
import ChatList from "./ChatList";
import { getApiCall } from "../../data/Index";
import ChatMedia from "./ChatMedia";
import ChatFiles from "./ChatFiles";
function SearchChat({
  id,
  type,
  pinChatElement,
  removeChatElement,
  userToken,
}) {
  const [chat, setChat] = useState([]);
  async function getChat(val) {
    if (type == 2) {
      const response = await getApiCall(
        "group-chat-history?rundev=rundev&keyword=" + val + "&group_id=" + id
      );
      if (response) {
        setChat(response.data.chats.data);
      }
    } else {
      const response = await getApiCall(
        "chat-history?rundev=rundev&keyword=" + val + "&chat_partner=" + id
      );
      if (response) {
        setChat(response.data.chats.data);
      }
    }
  }

  return (
    <div className="modal fade audiocallModal" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-fullscreen modal-dialog-scrollable">
        <div className="modal-content modal-header-colored shadow-lg border-0">
          <div className="modal-header">
            <h5 className="modal-title text-white font-size-16">Search</h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-0">
            <div className="row d-flex justify-content-center user-chat-topbar p-2">
              <div className="col-sm-8 p-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search in chat.."
                  id="searchChatMessage"
                  onKeyUp={(e) => getChat(e.target.value)}
                />
              </div>
            </div>
            <div className="w-100 overflow-hidden position-relative">
              <div id="users-chat" className="position-relative">
                <div
                  className="chat-conversation p-3 p-lg-4 "
                  id=""
                  data-simplebar
                >
                  <ul className="list-unstyled" id="users-conversation">
                    <ChatList
                      chats={chat}
                      pinChatElement={pinChatElement}
                      removeChatElement={removeChatElement}
                      type={type}
                    />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function ShowAllImage({ data }) {
  return (
    <div className="modal fade allImageModal" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content modal-header-colored shadow-lg border-0">
          <div className="modal-header">
            <h5 className="modal-title text-white font-size-16">All Media</h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <div className="w-100 overflow-hidden position-relative">
              <ChatMedia data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function ShowAllFiles({ data, deleteFiles }) {
  return (
    <div className="modal fade allFilesModal" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content modal-header-colored shadow-lg border-0">
          <div className="modal-header">
            <h5 className="modal-title text-white font-size-16">
              All Attach Files
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <div className="w-100 overflow-hidden position-relative">
              <ChatFiles data={data} deleteFiles={deleteFiles} ShowAllChatFiles={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function ShareChat({
  SelectedUser,
  GroupMembers,
  handleOnChange,
  ShareMessage,
  chat_id,
  checkedState,
}) {
  return (
    <div
      className="modal fade"
      id="addcontactModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addgroup-exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <form className="modal-content modal-header-colored shadow-lg border-0">
          <div className="modal-header">
            <h5 className="modal-title text-white font-size-16">
              Share Message
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <ul className="list-unstyled chat-list">
              {SelectedUser &&
                SelectedUser?.map((row, index) => (
                  <li key={index}>
                    <div className="d-flex">
                      <div className="flex-shrink-0 avatar-xs me-3">
                        <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                          <img
                            src={row?.profile?.path}
                            alt="media img"
                            className="rounded-circle avatar-xs"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-14 text-left">{row?.name}</h5>
                      </div>
                      <div className="flex-shrink-0 ms-3">
                        <input
                          type="checkbox"
                          value={row.id}
                          onChange={(e) => handleOnChange(e, row.id, 1)}
                        />
                      </div>
                    </div>
                    <hr className="my-2" />
                  </li>
                ))}
              {GroupMembers &&
                GroupMembers?.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className="d-flex">
                        <div className="flex-shrink-0 avatar-xs me-3">
                          <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                            <span>#</span>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="font-size-14 text-left">
                            {item.name}
                          </h5>
                        </div>
                        <div className="flex-shrink-0 ms-3">
                          <input
                            type="checkbox"
                            value={item.id}
                            onChange={(e) => handleOnChange(e, item.id, 2)}
                          />
                        </div>
                      </div>
                      <hr className="my-2" />
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-link"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => ShareMessage(chat_id, checkedState)}
            >
              Share Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
function ImportantChat({
  SelectedUser,
  handleOnChange,
  markImportant,
  chat_id,
  checkedState,
  userToken,
}) {
  return (
    <div
      className="modal fade"
      id="addimportantModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addgroup-exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <form className="modal-content modal-header-colored shadow-lg border-0">
          <div className="modal-header">
            <h5 className="modal-title text-white font-size-16">
              Inbox
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <ul className="list-unstyled chat-list">
              {SelectedUser &&
                SelectedUser?.map(
                  (row, index) =>
                    row?.employee_id !== userToken?.user?.employee_id && (
                      <li key={index}>
                        <div className="d-flex">
                          <div className="flex-shrink-0 avatar-xs me-3">
                            <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                              <img
                                src={row?.profile?.path}
                                alt="media img"
                                className="rounded-circle avatar-xs"
                              />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-14 text-left">
                              {row.name}
                            </h5>
                          </div>
                          <div className="flex-shrink-0 ms-3">
                            <input
                              type="checkbox"
                              value={row.id}
                              onChange={(e) => handleOnChange(e, row.id, 1)}
                            />
                          </div>
                        </div>
                        <hr className="my-2" />
                      </li>
                    )
                )}
            </ul>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-link"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => markImportant(chat_id, checkedState)}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export { SearchChat, ShowAllImage, ShowAllFiles, ShareChat, ImportantChat };
