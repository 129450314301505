import React, { useState } from "react";
import { Link } from "react-router-dom";

const AdminNotification = ({ userstatus }) => {

  return (
    <>
      <div className="dashboard-sections mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="header">
            <img src="../../assets/svg/time-dark.svg" alt="date" />
            <p className="m-0">Employee Log</p>
          </div>
          <Link to="/employeereport">View All Logs</Link>
        </div>
        <table className="mt-4">
          <thead>
            <tr>
            <th>Name</th>
            <th>Clock In</th>
            <th>Current Status</th>
            <th>To Do</th>
            <th>Summary</th>
            </tr>
          </thead>
          <tbody>
          {userstatus.map((section, index) => (
            <tr key={index}>
              <td className={section.was_late ? 'helightColor' : '' }>{section.name}</td>
              <td>{section.currentstatus?.entry_time}</td>
              <th className={section.activity_status>0 ? '' : 'helightColor' }>{(section?.activity_status > 0)?'Active':'Inactive' }</th>
              <td className={section.istodo>7.59 ? '' : 'helightColor' }>{section?.istodo}</td>
              <td>{(section.summary?.totalhours > 0 || section.summary?.totalminutes>0)? section.summary?.totalhours+":"+section.summary?.totalminutes :''}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminNotification;
