
import React, { useEffect, useState, useRef } from "react";
import { getApiCall, postApiCall, loginDetail } from "../../Service/Api";
function ReportHR({userProjectData}) {
  const Today = new Date().toISOString().split("T")[0];
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const Week = `${firstDayOfMonth.getFullYear()}-${String(firstDayOfMonth.getMonth() + 1).padStart(2, '0')}-${String(firstDayOfMonth.getDate()).padStart(2, '0')}`;
    

  
  const [userData, setUserData] = useState("");
  const [FromData, setFromData] = useState(Week);
  const [ToData, setToData] = useState(Today);
  const [ProjectFilter, setProjectFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(loginDetail());
  
  const handleSummeryData = async () => {
    //setUserData("");
    setProjectFilter("");
    setFromData(Week);
    setToData(Today);
    handleFilterData();
  };

  const handleFilterData = async () => {
    //setLoading(true);
    const response = await getApiCall("chat-manager/report-employee-attandance?rundev=rundev&user_id="+ProjectFilter+"&start_date="+FromData+"&end_date="+ToData);
    if(response) {
      setUserData(response.data);
    }
  };

  const handleResetData = () => {
    handleSummeryData();
  };

  return (
    <>
        <div className="row align-items-center">
            <div className="col-sm-2 ">
              <div className="mb-4">
                <label className="mb-2">Employee</label>
                <select
                  className="form-select"
                  onChange={(e) => setProjectFilter(e.target.value)}
                  defaultValue={ProjectFilter}
                  value={ProjectFilter}
                >
                  <option value="">Select Option</option>
                  {profile?.user?.is_admin && (
                    <option value="">All</option>
                  )}
                  <option value={profile?.user?.id}>Self</option>
                  {userProjectData &&
                    userProjectData?.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="mb-4">
                <label className="mb-2">From Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Start Date"
                  onChange={(e) => setFromData(e.target.value)}
                  value={FromData}
                />
              </div>
            </div>
            <div className="col-sm-2">
              <div className="mb-4">
                <label className="mb-2">To Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="End Date"
                  onChange={(e) => setToData(e.target.value)}
                  value={ToData}
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="mt-2">
                <input
                  type="button"
                  className="btn btn-primary"
                  value="Filter"
                  onClick={handleFilterData}
                />
                &nbsp;&nbsp;
                <input
                  type="button"
                  className="btn btn-warning"
                  value="Reset Filter"
                  onClick={handleResetData}
                />
              </div>
            </div>
        </div>
        <div className="row mt-3" style={{ overflowY: "auto", height: "70vh" }}>
            <table className="table table-striped table-bordered">
                <thead>
                <tr>
                    <th>Employee Name</th>
                    <th>Total Working Days</th>
                </tr>
                </thead>
                <tbody>
                  {userData &&
                    userData?.map((item, index) => (
                      <tr key={index}>
                          <td>{item.username}</td>
                          <td>{item.days}</td>
                      </tr>
                    ))
                  }
                
                </tbody>
            </table>
        </div>
      </>
  );
}

export default ReportHR;


