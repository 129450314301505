/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import linkifyHtml from "linkify-html";
import { postApiCall } from "../data/Api";
function PinList({ data, pinChatElement }) {
  const handelPin = (id, type) => {
    pinChatElement(id, type);
  };
  return (
    <>
      <div className="row">
      <div className="col-sm-12">
        <table className="table w-100">
          <thead>
          <tr>
          {pinChatElement?<th>Action</th>:''}
            <th>Time</th>
            <th>Pinned By</th>
            <th className="theme-color">Pinned In</th>
            <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data?.map((chat, index) => (
                  <tr key={index}>
                  {pinChatElement?
                    <td>
                      <a
                        className="dropdown-item d-flex align-items-center justify-content-between"
                        href="#!"
                        onClick={() =>
                          handelPin(chat?.id, chat.group ? "2" : "1")
                        }
                      >
                        {chat.is_pinned === 1
                          ? <i className='bx bx-x-circle text-muted ms-2'></i>
                          : <i className="bx bx-bookmarks text-muted ms-2"></i>}{" "}
                        
                      </a>
                    </td>:''}
                    <td>{chat.created_at}</td>
                    <td>{chat?.sender?.name ? chat?.sender?.name : chat?.user?.name}</td>
                    <td className="font-weight-bold theme-color">
                      {chat.group?.type ? (
                        <p className="mb-0">{chat.group?.name}</p>
                      ) : (
                        <p className="mb-0">Direct Message</p>
                      )}
                    </td>
                    <td>
                      {" "}
                      <div className="ctext-wrap-content">
                        {chat.documents?.chat_media.length > 0 && (
                          <div className="message-img mb-2">
                            {chat.documents?.chat_media.length > 0 &&
                              chat.documents?.chat_media?.map(
                                (img, img_index) => (
                                  <div
                                    className="message-img-list"
                                    key={img_index}
                                  >
                                    <div>
                                      <a
                                        className="popup-img d-inline-block"
                                        href={img.path}
                                        target="_blank"
                                        download
                                      >
                                        <img
                                          src={img.path}
                                          alt=""
                                          className="rounded border"
                                          width={100}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        )}
                        {chat.documents?.chat_files.length > 0 && (
                          <div className="message-img mb-2">
                            {chat.documents?.chat_files.length > 0 &&
                              chat.documents?.chat_files?.map(
                                (file, file_index) => (
                                  <div
                                    className="card p-2 border mb-2"
                                    key={file_index}
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0 avatar-xs ms-1 me-3">
                                        <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                          <i className="bx bx-file"></i>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 overflow-hidden">
                                        <h5 className="font-size-14 text-truncate mb-1">
                                          {file.original_name}
                                        </h5>
                                      </div>
                                      <div className="flex-shrink-0 ms-3">
                                        <div className="d-flex gap-2">
                                          <div>
                                            <a
                                              href={file.path}
                                              target="_blank"
                                              download
                                              className="text-muted px-1"
                                            >
                                              <i className="bx bxs-download"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        )}
                        {chat.message != "" && (
                          <div className="mb-0 ctext-content">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: linkifyHtml(chat.message, {
                                  target: "_blank",
                                }),
                              }} 
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
              ))
            ) : (
              <div className="container">
                <div className="row mt-3">
                  <div className="col-sm-12 text-center p-5">
                    <h2>Search in the system</h2>
                    <p>Search your conversion in an agile way.</p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 text-center p-5">
                    
                  </div>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>
      </div>
      {/* {data.length > 0 ? (
        data?.map((chat, index) => (
          <>
            <li key={index} className="chat-list">
              <div className="conversation-list">
                <div className="chat-avatar chat-user-img online">
                  {chat.sender?.profile ? (
                    <img
                      className="rounded-circle avatar-xs"
                      src={chat.sender?.profile.path}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="user-chat-content">
                  <div className="ctext-wrap">
                    {chat.group?.type ? (
                      <p>
                        {chat.group?.type == 1 ? (
                          <i className="bx bx-lock"></i>
                        ) : (
                          "#"
                        )}
                        &nbsp;{chat.group?.name}
                      </p>
                    ) : (
                      <p>Direct Message</p>
                    )}
                    <div className="conversation-name mt-10">
                      <small>{chat?.sender?.name}</small>
                      <small className="text-muted time">
                        {chat.created_at}
                      </small>
                      <div className="dropdown align-self-start message-box-drop">
                        <a
                          className="dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="ri-more-2-fill"></i>
                        </a>
                        <div className="dropdown-menu">
                          <a
                            className="dropdown-item d-flex align-items-center justify-content-between"
                            href="#"
                            onClick={() =>
                              handelPin(chat?.id, chat.group ? "2" : "1")
                            }
                          >
                            {chat.is_pinned === 1
                              ? "Remove Bookmark"
                              : "Bookmark"}{" "}
                            <i className="bx bx-bookmarks text-muted ms-2"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="ctext-wrap-content">
                      {chat.documents?.chat_media.length > 0 && (
                        <div className="message-img mb-2">
                          {chat.documents?.chat_media.length > 0 &&
                            chat.documents?.chat_media?.map(
                              (img, img_index) => (
                                <div
                                  className="message-img-list"
                                  key={img_index}
                                >
                                  <div>
                                    <a
                                      className="popup-img d-inline-block"
                                      href={img.path}
                                      target="_blank"
                                      download
                                    >
                                      <img
                                        src={img.path}
                                        alt=""
                                        className="rounded border"
                                      />
                                    </a>
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      )}
                      {chat.documents?.chat_files.length > 0 && (
                        <div className="message-img mb-2">
                          {chat.documents?.chat_files.length > 0 &&
                            chat.documents?.chat_files?.map(
                              (file, file_index) => (
                                <div
                                  className="card p-2 border mb-2"
                                  key={file_index}
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 avatar-xs ms-1 me-3">
                                      <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                        <i className="bx bx-file"></i>
                                      </div>
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden">
                                      <h5 className="font-size-14 text-truncate mb-1">
                                        {file.original_name}
                                      </h5>
                                    </div>
                                    <div className="flex-shrink-0 ms-3">
                                      <div className="d-flex gap-2">
                                        <div>
                                          <a
                                            href={file.path}
                                            target="_blank"
                                            download
                                            className="text-muted px-1"
                                          >
                                            <i className="bx bxs-download"></i>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      )}
                      {chat.message != "" && (
                        <div className="mb-0 ctext-content">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: linkifyHtml(chat.message, {
                                target: "_blank",
                              }),
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <></>
          </>
        ))
      ) : (
        <div className="container">
          <div className="row mt-3">
            <div className="col-sm-12 text-center p-5">
              <h2>Search in the system</h2>
              <p>Search your conversion in an agile way.</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12 text-center p-5">
              <video
                src="https://work.clientmeets.com/assets/dashboard.mp4"
                muted
                autoPlay
                loop
                style={{ width: "70%", height: "70%" }}
              />
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}
export default PinList;
