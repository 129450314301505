/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getApiCall, postApiCall, deleteApiCall } from "../../Service/Api";
import { FaTrashAlt, FaCheck, FaEdit, FaSave, FaTimes } from "react-icons/fa";
import DeleteConfirmation from "../component/DeleteConfirmation";

const Checklist = () => {
  const [checklistSection, setChecklistSection] = useState();
  const [sections, setSections] = useState();
  const [newItemText, setNewItemText] = useState("");
  const [newSectionTitle, setNewSectionTitle] = useState("");
  const [listIndex, setListIndex] = useState("");
  const [selectedSectionIndex, setSelectedSectionIndex] = useState("");
  const [editItemText, setEditItemText] = useState("");
  const [editItemTime, setEditItemTime] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [Section_id, setSection_id] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const { slug } = useParams();
  useEffect(() => {
    api();
  }, [id]);

  const api = async () => {
    const response = await getApiCall(
      "task-manager/get-sections?rundev=rundev&project_id=" + id
    );
    if (response.status) {
      if (response.data.length > 0) {
        setSections(response.data);
        setChecklistSection([]);
      } else {
        setSections([]);
        const response = await getApiCall(
          "chat-manager/get-checklists-sections?rundev=rundev"
        );
        if (response.status) {
          setChecklistSection(response.data.checklist);
        }
      }
    }
  };

  const handleAddNewItem = async (sectionId) => {
    let payload = {
      checklist: newItemText,
      section_id: sectionId,
    };

    const response = await postApiCall(
      "task-manager/manage-checklist",
      payload
    );
    if (response.status) {
      api();
    }
    setNewItemText("");
  };

  const handleRemoveItem = async (sectionId, itemId) => {
    const response = await postApiCall(
      "task-manager/delete-checklist/" + itemId
    );
    if (response.status) {
      api();
    }
  };

  const handleMarkAsDone = async (sectionId, itemId) => {
    const response = await postApiCall("task-manager/done-checklist/" + itemId);
    if (response.status) {
      api();
    }
  };

  const handleEditItem = (sectionId, itemId, newListIndex, sectionIndex) => {
    const updatedTodos = [...sections];
    updatedTodos[sectionIndex].todos[0].editing = 0;
    if (listIndex != "") {
      updatedTodos[sectionIndex].todos[newListIndex].editing = 0;
    }
    setListIndex(newListIndex);
    setSelectedSectionIndex(sectionIndex);
    updatedTodos[sectionIndex].todos[newListIndex].editing = 1;
    setEditItemText(updatedTodos[sectionIndex].todos[newListIndex].checklist);
    setSections(updatedTodos);
  };

  const handleSaveItem = async (sectionId, itemId) => {
    let payload = {
      section_id: sectionId,
      checklist: editItemText,
      checklist_id: itemId,
    };
    const response = await postApiCall(
      "task-manager/manage-checklist/",
      payload
    );
    if (response.status) {
      api();
    }
  };

  const handleCancelEdit = (sectionId, itemId) => {
    const updatedTodos = [...sections];
    updatedTodos[selectedSectionIndex].todos[listIndex].editing = 0;
    setSections(updatedTodos);
  };

  const handleKeyPress = (event, sectionId) => {
    if (event.key === "Enter") {
      handleAddNewItem(sectionId);
    }
  };
  const handleSectionKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddNewSection();
    }
  };
  
  const handleAddNewSection = async () => {
    if (newSectionTitle.trim() !== "") {
      let payload = {
        section_name: newSectionTitle,
        project_id: id,
      };
      const response = await postApiCall(
        "task-manager/manage-sections",
        payload
      );
      if (response.status) {
        api();
      }
      setNewSectionTitle("");
    }
  };

  const handleDeleteSection = async (section) => {
    window["showModal"]();
    setSection_id(section);
  };
  async function confirmDelete() {
    let payload = {
      section_id: Section_id,
    };
    const response = await postApiCall("task-manager/delete-section", payload);
    if (response.status) {
      api();
      window["closeModal"]();
    }
  }

  const handleOnChange = (position) => {
    const index = checkedState.findIndex((day) => day === position);
    if (index > -1) {
      setCheckedState([
        ...checkedState.slice(0, index),
        ...checkedState.slice(index + 1),
      ]);
    } else {
      setCheckedState((oldArray) => [...oldArray, position]);
    }
  };

  const GroupSubmit = async () => {
    const data = new FormData();
    data.append("project_id", id);
    checkedState?.forEach((tag) => data.append("sections_id[]", tag));
    const response = await postApiCall(
      "chat-manager/insert-general-sections?rundev=rundev",
      data
    );
    if (response) {
      api();
      setCheckedState([]);
    }
  };

  return (
    <>
      <DeleteConfirmation fElement={confirmDelete} />
      {checklistSection?.length > 0 ? (
        <div className="chat-conversation" style={{ "overflow-y": "scroll" }}>
          <div className="px-5 mt-4 d-flex flex-column">
            <h5 className="d-flex align-items-center gap-2 mb-4">
              <img src="../../../assets/svg/to-do.svg" alt="to-do" /> Add From
              List
            </h5>
            <ul className="list-unstyled row w-100 checklist-ul">
              {checklistSection?.map((section, index) => (
                <li className="col-sm-12" key={index}>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <h4 className="font-size-16 text-left mb-0">
                        {section.section}
                      </h4>
                    </div>
                    <div className="flex-shrink-0 ms-3">
                      <input
                        type="checkbox"
                        value={section.id}
                        onChange={() => handleOnChange(section.id)}
                      />
                    </div>
                  </div>
                  {/* <hr className="my-2" /> */}
                </li>
              ))}
            </ul>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="btn btn-primary mt-2"
                  type="button"
                  onClick={() => GroupSubmit()}
                  value="Add Checklist"
                />
              </div>
            </div>
          </div>
        </div>
      ) : ("")}
       <div
            className="chat-conversation"
            style={{ "overflow-y": "scroll", height: "83vh" }}
          >
            <div className="px-3 mt-4">
              <div className="accordion" id="accordion">
                {sections?.map((section, index) => (
                  <div className="accordion-item" key={section.id}>
                    <h2
                      className="accordion-header"
                      id={`heading-${section.id}`}
                    >
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${section.id}`}
                        aria-expanded="false"
                        aria-controls={`collapse-${section.id}`}
                      >
                        {section.section_name} &nbsp;{" "}
                        <Link
                          className="text-danger btn-sm m-l-2 text-right"
                          onClick={() => handleDeleteSection(section.id)}
                        >
                          <FaTrashAlt />
                        </Link>
                      </button>
                    </h2>
                    <div
                      id={`collapse-${section.id}`}
                      className={`accordion-collapse ${
                        index === 0 ? " collapse show" : "collapse"
                      }  `}
                      aria-labelledby={`heading-${section.id}`}
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        <ul className="list-group list-group-flush">
                          {section.todos.map((item, rowindex) => (
                            <li key={item.id} className="mb-2 list-group-item">
                              {item.editing ? (
                                <>
                                  <input
                                    type="text"
                                    className="form-control d-inline-block"
                                    value={editItemText}
                                    onChange={(e) =>
                                      setEditItemText(e.target.value)
                                    }
                                  />
                                  <button
                                    className="btn btn-primary mt-2"
                                    onClick={() =>
                                      handleSaveItem(section.id, item.id)
                                    }
                                  >
                                    Save
                                  </button>
                                  &nbsp;&nbsp;
                                  <button
                                    className="btn btn-primary mt-2"
                                    onClick={() =>
                                      handleCancelEdit(section.id, item.id)
                                    }
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <div className="row">
                                  <div
                                    style={{
                                      textDecoration: item.is_completed
                                        ? "line-through"
                                        : "none",
                                    }}
                                    className="col-sm-10"
                                    onClick={() =>
                                      handleEditItem(
                                        section.id,
                                        item.id,
                                        rowindex,
                                        index
                                      )
                                    }
                                  >
                                    {item.checklist}
                                  </div>
                                  <div className="col-sm-2 text-end">
                                    {item.is_completed != 1 ? (
                                      <>
                                        <a
                                          className="text-danger btn-sm m-l-2"
                                          onClick={() =>
                                            handleRemoveItem(
                                              section.id,
                                              item.id
                                            )
                                          }
                                          href="#!"
                                        >
                                          <FaTrashAlt />
                                        </a>
                                        &nbsp;&nbsp;
                                        <a
                                          className="btn-sm m-l-2"
                                          onClick={() =>
                                            handleMarkAsDone(
                                              section.id,
                                              item.id
                                            )
                                          }
                                          href="#!"
                                        >
                                          <FaCheck />
                                        </a>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              )}
                            </li>
                          ))}
                        </ul>
                        <div className="input-group mb-3 gap-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter new task"
                            value={newItemText}
                            onChange={(e) => setNewItemText(e.target.value)}
                            onKeyPress={(e) => handleKeyPress(e, section.id)}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-primary"
                              onClick={() => handleAddNewItem(section.id)}
                            >
                              Add Task
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="px-3 mt-4 bottomFixed">
            <div className="input-group mb-3 gap-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter section title"
                value={newSectionTitle}
                onChange={(e) => setNewSectionTitle(e.target.value)}
                onKeyPress={(e) => handleSectionKeyPress(e)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  onClick={handleAddNewSection}
                >
                  Add Section
                </button>
              </div>
            </div>
          </div>
    </>
  );
};

export default Checklist;
