import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useToken from "../Auth/useToken";
import { loginDetail } from "../ChatPortal/data/Index";
import Tooltip from "@mui/material/Tooltip";

function LeftSideBarMenu() {
  const navigate = useNavigate();
  const userToken = loginDetail();
  const [profile, setProfile] = useState(loginDetail());
  const { token, setToken } = useToken();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  function handleLogout() {
    setToken("");
    navigate("/");
    window.location.reload();
  }

  let menus = [
    { link: "", icon: "/assets/svg/home.svg", title: "Dashboard" },
    {
      link: "todo",
      icon: "/assets/svg/sidebar-to-do.svg",
      title: "To do",
    },
    {
      link: "summary",
      icon: "/assets/svg/summary.svg",
      title: "Summary",
    },
    {
      link: "chat",
      icon: "/assets/svg/sidebar-channels.svg",
      title: "Channel",
    },
    /*{
      link: "inbox",
      icon: "/assets/svg/sidebar-message.svg",
      title: "Inbox",
    },*/
    {
      link: "bookmark",
      icon: "/assets/svg/bookmark.svg",
      title: "Bookmark",
    },
    profile?.user?.is_admin && {
      link: "files",
      icon: "/assets/svg/files.svg",
      title: "Files",
    },
    userToken.user.employee_type == "inhouse" && {
      link: "employeereport",
      icon: "/assets/svg/sidebar-time.svg",
      title: "Report",
    },
    /*profile?.user?.is_admin && {
      link: "projectreport",
      icon: "/assets/svg/date.svg",
      title: "Project Report",
    },*/
    // { link: "search", icon: 'bx bx-search',title:'Search' },
    userToken.user.employee_type == "inhouse" && {
      link: "feedback",
      icon: "/assets/svg/feedbacks.svg",
      title: "Suggestion",
    },
  ];
  return (
    <>
      <div className="side-menu flex-lg-column">
        <div className="navbar-brand-box">
          <h4 className="siteName">TT</h4>
        </div>
        <div className="flex-lg-column my-0 sidemenu-navigation">
          <ul className="nav nav-pills side-menu-nav" role="tablist">
            {menus.map(
              (item, index) =>
                item && (
                  <li className="nav-item cursor-pointer" key={index}>
                    <Link
                      to={"/" + item.link}
                      className={
                        item.link == splitLocation[1]
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <img src={item?.icon} alt="icon" />
                    <h6 className="nav-li-title mb-0 text-center">
                      {item?.title}
                    </h6>
                    </Link>
                  </li>
                )
            )}

            <li className="nav-item mt-auto"></li>
            <li className="nav-item dropdown profile-user-dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src={profile.user?.profile?.path}
                  className="profile-user rounded-circle"
                />
              </a>
              <div className="dropdown-menu">
                <Link
                  className="dropdown-item d-flex align-items-center justify-content-between"
                  to="/profile"
                >
                  Profile <i className="bx bx-user-circle text-muted ms-1"></i>
                </Link>

                {userToken.user.employee_type == "inhouse" && (
                  <Link
                    className="dropdown-item d-flex align-items-center justify-content-between"
                    to="/setting"
                  >
                    Setting <i className="bx bx-setting text-muted ms-1"></i>
                  </Link>
                )}

                <div className="dropdown-divider"></div>
                <Link
                  onClick={handleLogout}
                  to="#"
                  className="dropdown-item d-flex align-items-center justify-content-between"
                >
                  Log out
                  <i className="bx bx-log-out-circle text-muted ms-1"></i>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default LeftSideBarMenu;
