function ChatFiles({ data, ShowAllChatFiles }) {
  const commonFiles = data?.map((file, index) =>
    ShowAllChatFiles ? (
      <div className="card p-2 border mb-2" key={index}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs ms-1 me-3">
            <div className="avatar-title bg-soft-primary text-primary rounded-circle">
              <i className="bx bx-file"></i>
            </div>
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <h5 className="font-size-14 text-truncate mb-1">
              <a
                  href={file.path}
                  target="_blank"
                  download
                  className="text-muted px-1"
                >{file.original_name}
              </a>
            </h5>
          </div>
          <div className="flex-shrink-0 ms-3">
            <div className="d-flex gap-2">
              <div>
                <a
                  href={file.path}
                  target="_blank"
                  download
                  className="text-muted px-1"
                >
                  <i className="bx bxs-download"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : index <= 4 ? (
      <div className="card p-2 border mb-2" key={index}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs ms-1 me-3">
            <div className="avatar-title bg-soft-primary text-primary rounded-circle">
              <i className="bx bx-file"></i>
            </div>
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <h5 className="font-size-14 text-truncate mb-1">
              {file.original_name}
              <br />
            </h5>
          </div>
          <div className="flex-shrink-0 ms-3">
            <div className="d-flex gap-2">
              <div>
                <a
                  href={file.path}
                  target="_blank"
                  download
                  className="text-muted px-1"
                >
                  <i className="bx bxs-download"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    )
  );
  return <div>{commonFiles} </div>;
}
export default ChatFiles;
