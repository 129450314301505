import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {postApiCall,loginDetail,getApiCall} from "../data/Api";
import FullScreenLoader from '../../FullScreenLoader';
import InfiniteScroll from "react-infinite-scroll-component";
import { Modal, Button } from 'react-bootstrap';
import {
  FaTrashAlt,
  FaCheck,
  FaEdit,
  FaSave,
  FaTimes,
  FaArrowRight,
} from "react-icons/fa";
function AttendeeSummary() {
  const Today = new Date().toISOString().split("T")[0];
  const week = new Date();
  week.setDate(week.getDate() - 7);
  let Week = week.toISOString().split("T")[0];
  const [profile, setProfile] = useState(loginDetail());
  const [SummeryData, setSummeryData] = useState("");
  const [mergedProjects, setMergedProjects] = useState([]);
  const [userData, setUserData] = useState("");
  const [FromData, setFromData] = useState(Week);
  const [ToData, setToData] = useState(Today);
  const [loading, setLoading] = useState(true);
  const chatSection = useRef(null);
  const [fireBottom, setFireBottom] = useState(1);
  const { id } = useParams();
  const mergedData = {};


  
  const [selectedItem, setSelectedItem] = useState(null);
  const [modelShow, setModelShow] = useState(false);
  const [modelReview, setModelReview] = useState('');
  const [modelTaskTag, setModelTaskTag] = useState('');
  const [modelTaskHours, setModelTaskHours] = useState('');
  const [modelTaskMinute, setModelTaskMinute] = useState('');
  const [modelTags, setModelTags] = useState([]); 
  const [modelIsWaiting, setModelIsWaiting] = useState(false);
  const [modelCurrentTask, setModelCurrentTask] = useState(null);

  const handleUpdateHours = async () => {
    // Implement your autofill logic here

    let payload = {
      task_log_id:modelCurrentTask,
      spent_hours: modelTaskHours,
      spent_minutes: modelTaskMinute,
      remarks: modelReview,
      tag_id:modelTaskTag,
      rundev:'rundev'
    };

    const response = await postApiCall("task-update-log", payload);
    setModelIsWaiting(false);
    if (response.status) {
      setModelReview('');
      setModelTaskTag('');
      setModelTaskHours('');
      setModelTaskMinute('');
      setModelCurrentTask('');
      handleFilterData();
    }

    handleClose();
  };

  const handleClose = () => setModelShow(false);
  const handleShow = (task) => {
    // Implement your autofill logic here
    setModelReview(task.remarks);
    setModelTaskTag(task.tag_id);
    setModelTaskHours(task.total_spent_hours);
    setModelTaskMinute(task.total_spent_minutes);
    setModelCurrentTask(task.id);
    setModelShow(true);
  };

  useEffect(() => {
    handleSummeryData();
    AllTags();
  }, [id]);

  const AllTags = async () => {
    const response = await getApiCall("tag?rundev=rundev");
    if (response.status) {
      setModelTags(response.data);
    }
  };

  useEffect(() => {
    chatSection.current?.scrollIntoView({ block: "end" }); 
    
  }, [fireBottom]);
  const scrollToBottom = () => {
    setFireBottom(fireBottom + 1);
  };

  const handleSummeryData = async () => {
    setLoading(true);
    const response = await postApiCall("get-daily-work-summary", {
      user_id: id,
      rundev: "rundev"
    });
    if (response.status) {
      setSummeryData(response.data[0].summary);
      setUserData(response.data[0].user);

      
      response.data[0].summary?.forEach((item) => {
        const date = item.date;
        item?.work?.forEach((task) => {
          const { project, ...rest } = task;
          if (!mergedData[date]) {
            mergedData[date] = {};
          }
          if (mergedData[date][project]) {
            mergedData[date][project].push(rest);
          } else {
            mergedData[date][project] = [rest];
          }
        });
      });
      
      setMergedProjects(mergedData);
      setTimeout(() => {
        setLoading(false);
        scrollToBottom();
      }, 1000);
      
    }else{
      setSummeryData([]);
      setUserData([]);
      setLoading(false);
    }
  };

  const handleFilterData = async () => {
    setLoading(true);
    let payload = {
      from_date: FromData,
      to_date: ToData,
      rundev: "rundev",
      user_id: id
    };
    const response = await postApiCall("get-daily-work-summary", payload);
    if (response.status) {
      setSummeryData(response.data[0].summary);
      setUserData(response.data[0].user);

      
      response.data[0].summary?.forEach((item) => {
        const date = item.date;
        item?.work?.forEach((task) => {
          const { project, ...rest } = task;
          if (!mergedData[date]) {
            mergedData[date] = {};
          }
          if (mergedData[date][project]) {
            mergedData[date][project].push(rest);
          } else {
            mergedData[date][project] = [rest];
          }
        });
      });
      
      setMergedProjects(mergedData);

      setTimeout(() => {
        setLoading(false);
        scrollToBottom();
      }, 1000);
    }else{
      setSummeryData([]);
      setUserData([]);
      setLoading(false);
    }
  };

  const handleResetData = () => {
    setFromData(Week);
    setToData(Today);
    handleSummeryData();
  };

  
  return (
    <>
    
      {loading ? <FullScreenLoader /> :
      <div className="user-chat w-100 overflow-hidden">
        <div className="user-chat-overlay"></div>

        <div className="chat-content d-lg-flex">
          <div className="w-100 overflow-hidden position-relative">
            <div id="users-chat" className="employe-report-header position-relative p-2">
              <div className="p-1 p-lg-2">
                <div className="row align-items-center">
                  <div className="col-sm-12">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 d-block d-lg-none me-3">
                        <Link to="/summary">
                          <a
                            href="#"
                            className="user-chat-remove font-size-18 p-1"
                          >
                            <i className="bx bx-chevron-left align-middle"></i>
                          </a>
                        </Link>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                            <img
                              src={userData.profile?.path}
                              className="rounded-circle avatar-sm"
                              alt=""
                            />
                            {userData.activity_status == 1 && (
                              <span className="user-status"></span>
                            )}
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            <h6 className="text-truncate mb-0 font-size-18">
                              <a
                                href="#"
                                className="user-profile-show text-reset"
                              >
                                {userData.name}
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <ul className="flex-shrink-2 list-inline user-chat-nav text-end mb-0">
                        <li className="list-inline-item d-lg-inline-block me-2 ms-0">
                          <button
                            type="button"
                            className="btn nav-btn user-profile-show-1"
                          >
                            <i className="bx bx-search"></i>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-white chat-conversation"
              id="chat-conversation"
              data-simplebar
              style={{ height: "calc(100vh - 60px)" }}
            >
              <div className="container mt-4 mb-1 p-0">
                <ul
                  className="list-unstyled chat-conversation-list"
                  id="users-conversation"
                  
                >
                  {SummeryData &&
                    SummeryData?.map((item, index) => (
                      <li className="w-100 chat-list left" key={index}>
                        <div className="w-100 p-3">
                          <div className="user-chat-content">
                            <div className="ctext-wrap">
                              <div className="row summary-blocks">
                                <div className="col-sm-12 mb-2 summary-blocks-heading">
                                  <strong>{item.date}</strong>
                                </div>
                              </div>
                              <table className="w-100">
                                <thead>
                                  <th>Entry Time</th>
                                  <th>Exit Time</th>
                                  <th>Total Time</th>
                                  <th>Total Working Time</th>
                                </thead>
                                <tbody>
                                  <tr>
                                  {item?.clock_tracks && (
                                      <>
                                        {/* Display first entry */}
                                        <td>{item.clock_tracks.log[0]?.entry_time}</td>
                                        
                                        {/* Display last entry */}
                                        <td>{item.clock_tracks.log[item.clock_tracks.log.length - 1]?.entry_time}</td>
                                      </>
                                    )}
                                    
                                    <td>{item.clock_tracks.clock_activity?.working_time}</td>
                                    <td>
                                      {item.clock_tracks.total_spent_hours} hours
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <hr />
                              <div className="ctext-wrap-content p-1">
                                <div className="mb-0 ctext-content">
                                  {item?.work?.length > 0 ? (
                                    Object.keys(mergedProjects[item?.date])?.map((project, projectIndex) => (
                                      
                                        <div key={projectIndex}>
                                          <h6>
                                            <strong>{project}</strong>
                                          </h6>
                                          <ul>
                                            {mergedProjects[item.date][project]?.map((task, taskIndex) => (
                                              
                                              <li key={taskIndex} className="mb-2">
                                                  <div className="row">
                                                    <div className="col-sm-12">
                                                      {task.task_name}
                                                    </div>
                                                    <div className="col-sm-12">
                                                      <strong>&nbsp;- ({task.spent_hours})</strong>
                                                        &nbsp;<strong className="text-danger">{task.tag}</strong> -  &nbsp;{task.remarks} 
                                                        {(profile?.user?.is_admin || profile?.user?.employee_id=='krishna') && (
                                                          <a
                                                          className=" m-l-2"
                                                          style={{display: "inline"}}
                                                          href="#"
                                                          onClick={() => handleShow(task, index)}
                                                          >
                                                            <FaEdit />
                                                          </a>
                                                        )}
                                                    </div>
                                                  </div>
                                                <hr />
                                              </li>
                                              
                                            ))}
                                          </ul>
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <p>No tasks recorded for this date.</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </li>
                    ))}
                    
                </ul>
                <div ref={chatSection}></div>
              </div>
            </div>
            
          </div>
          <div className="user-profile-sidebar" id="user-profile-sidebar">
            <div className="p-4 user-profile-desc" data-simplebar>
            <div className="text-center"><button type="button" className="btn btn-light user-profile-show d-none d-lg-block"><i className="bx bx-x"></i></button></div>
              <h4 className="mt-3">Filter</h4>
              <hr className="mb-4" />
              <div className="row">
                <div className="col-sm-12">
                  <div className="mb-4">
                    <label>From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Start Date"
                      onChange={(e) => setFromData(e.target.value)}
                      max={Today}
                      value={FromData}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="mb-4">
                    <label>To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="End Date"
                      onChange={(e) => setToData(e.target.value)}
                      max={Today}
                      value={ToData}
                    />
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div className="">
                    <div className="mb-4">
                      <input
                        type="button"
                        className="btn btn-primary"
                        value="Filter"
                        onClick={handleFilterData}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="mb-4">
                      <input
                        type="button"
                        className="btn btn-warning"
                        value="Reset Filter"
                        onClick={handleResetData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
}
<Modal show={modelShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Task Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <textarea
              autoComplete="off"
              className="form-control form-control-lg"
              autoFocus
              placeholder="Type your message..."
              onChange={(e) => setModelReview(e.target.value)}
              value={modelReview}
              rows={1}
            />
          </div>
          <div className="form-group mt-2">
            <select
              className="form-select form-select-lg"
              onChange={(e) => setModelTaskTag(e.target.value)}
              style={{ width: '100%' }}
              value={modelTaskTag}
            >
              <option value="">Select Tag</option>
              {modelTags.map((obj) => (
                <option key={obj.id} value={obj.id}>
                  {obj.tag_name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group mt-2">
            <input
              autoComplete="off"
              type="text"
              className="form-control form-control-lg"
              placeholder="Hours..."
              onChange={(e) =>
                setModelTaskHours(e.target.validity.valid ? e.target.value : modelTaskHours)
              }
              value={modelTaskHours}
              pattern="[0-9]*"
              maxLength={2}
            />
          </div>
          <div className="form-group mt-2">
            <input
              autoComplete="off"
              type="text"
              className="form-control form-control-lg"
              placeholder="Minutes..."
              onChange={(e) =>
                setModelTaskMinute(e.target.validity.valid ? e.target.value : modelTaskMinute)
              }
              value={modelTaskMinute}
              pattern="[0-9]*"
              maxLength={2}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdateHours()}
            disabled={modelIsWaiting}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default AttendeeSummary;
