import React, { useState , useEffect} from 'react';
import { useParams, Link, useNavigate } from "react-router-dom";
import { getApiCall, postApiCall, deleteApiCall } from "../../Service/Api";
import { FaTrashAlt, FaCheck, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
const ProjectTimeline = () => {

  const [newItemText, setNewItemText] = useState('');
  const [newItemTime, setNewItemTime] = useState('');

  const [timeline, setTimeline] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const { slug } = useParams();

  useEffect(() => {
    api();
  }, [id]);

  const api = async () => {
    const response = await getApiCall("task-manager/project-timeline?rundev=rundev&project_id="+id);
    if(response.status){
      setTimeline(response.data.timeline);
    }
   
  };

  const handleDelete = async (itemId) => {
    const response = await postApiCall('task-manager/delete-timeline/',{timeline_id:itemId});
    if(response.status){
      api();
    }
  };

  const handleAddItem = async () => {
    let payload = {
      project_id:id,
      target_date: newItemTime,
      milestone: newItemText
    }

    const response = await postApiCall('task-manager/manage-timeline', payload);
    if(response.status){
      console.log('sdf');
      setNewItemText('');
      setNewItemTime('');
      api();
      
    }
    
  };

  const printTable = () => {
    window.print();
  };

  return (
    <>
        <div className="bg-white p-4" style={{"overflow-y": "scroll","height": "83vh"}}>

            <button className="btn btn-primary mb-4" onClick={printTable}>Print Time Line</button>
            <table className="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Line Item</th>
                        <th>Delivery Deadline</th>
                        <th className="removePrint">Actions</th>
                    </tr>
                </thead>
                <tbody>
                {timeline?.map(item => (
                    <tr key={item.id}>
                    <td>{item.milestone}</td>
                    <td>{item.target_date}</td>
                    <td className="removePrint">
                        <a href="#!" className="text-danger" onClick={() => handleDelete(item.id)}><FaTrashAlt /></a>
                    </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
        <div className="px-3 mt-4 bottomFixed">
            <div className="input-group mb-1 gap-3">
                <input
                type="text"
                style={{"width":"60%"}}
                className="form-control w-80"
                placeholder="Enter Milestone"
                value={newItemText}
                onChange={(e) => setNewItemText(e.target.value)}
                
                />
                <input
                type="date"
                className="form-control"
                placeholder="Delivery Deadline"
                value={newItemTime}
                onChange={(e) => setNewItemTime(e.target.value)}
                
                />
                <div className="input-group-append">
                    <button
                        className="btn btn-primary"
                        onClick={handleAddItem}
                    >
                        Add New
                    </button>
                </div>  
            </div>
        </div>
    </>
  );
};

export default ProjectTimeline;
