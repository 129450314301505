/* eslint-disable jsx-a11y/anchor-is-valid */
import linkifyHtml from "linkify-html";
import { useState, useEffect } from "react";
import { getApiCall, loginDetail, postApiCall } from "../../data/Api";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { ShareChat, ImportantChat } from "./SearchChat";
function ChatList({
  dashboard,
  EditChat,
  chats,
  pinChatElement,
  removeChatElement,
  editChatID,
  handelSubmit,
  message,
  setMessage,
  handleCancleMessage,
  Seen,
  chatType,
}) {
  const [SelectedUser, setSelectedUser] = useState("");
  const [GroupMembers, setGroupMembers] = useState("");
  const [selectedGroupMembers, setselectedGroupMembers] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [chat_id, setchat_id] = useState("");
  const { id } = useParams();
  const userToken = loginDetail();
  useEffect(() => {
    
  }, [id]);

  const handleOnChange = (e, id, type) => {
    if (e.target.checked) {
      let array = { message_type: type, user_id: id };
      setCheckedState((oldArray) => [...oldArray, array]);
    } else {
      const index = checkedState.findIndex((day) => day.user_id === id);
      setCheckedState([
        ...checkedState.slice(0, index),
        ...checkedState.slice(index + 1),
      ]);
    }
  };

  const getSelectedUsers = async () => {
    const response = await getApiCall("users?rundev=rundev");
    if (response) {
      const sortedData = response.sort((a, b) => a.name.localeCompare(b.name));
      setSelectedUser(sortedData);
    }
      
  };
  const getGroupMembers = async () => {
    const response = await getApiCall(
      "view-group-members?rundev=rundev&group_id=" + id
    );
    const sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name));
    setselectedGroupMembers(sortedData);
  };
  const getGroups = async () => {
    const response = await getApiCall("get-user-groups?rundev=rundev");
    const sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name));
    setGroupMembers(sortedData);
  };

  const getGroupAndMembers = async (id) => {
    setchat_id(id);

    getSelectedUsers();
    getGroups();
    if (chatType == 2) {
      getGroupMembers();
    }
  };

  // edit message
  const handleEditorChange = (e) => {
    setMessage(e);
  };

  const ShareMessage = async (id, shareArray) => {
    const response = await postApiCall("share-message", {
      type: chatType,
      message_id: id,
      shareTo: shareArray,
    });
    if (response.status) {
      dashboard();
      window["closeModal"]();
    }
  };

  const markImportant = async (id, shareArray) => {
    if (shareArray) {
      let user_id = [];
      shareArray?.forEach((employee, index) => {
        user_id.push(employee.user_id);
      });
      const response = await postApiCall("mark-as-important", {
        type: chatType,
        message_id: id,
        user_id,
      });
      if (response.status) {
        dashboard();
        window["closeModal"]();
      }
    }
  };
  const group = async (title, e_id) => {
    // setCreatedId(2);
    let payload = {
      name: title,
      type: "1",
      description: "",
      enquiry_id: e_id,
    };
    // console.log(payload);
    // return;
    const response = await postApiCall("create-group", payload);
    if (response.status) {
      dashboard();
    }
  };

  return (
    <>
      <ShareChat
        SelectedUser={SelectedUser}
        GroupMembers={GroupMembers}
        handleOnChange={handleOnChange}
        ShareMessage={ShareMessage}
        chat_id={chat_id}
        checkedState={checkedState}
        userToken={userToken}
      />
      <ImportantChat
        SelectedUser={selectedGroupMembers}
        handleOnChange={handleOnChange}
        markImportant={markImportant}
        chat_id={chat_id}
        checkedState={checkedState}
        userToken={userToken}
      />
      {chats?.map((chat, index) => (
        <li
          className={
            "chat-list " + (chat.is_loggedin_user == 1 ? "left" : "left")
          }
          key={index}
        >
          <div className="conversation-list p-3">
            <div className="chat-avatar chat-user-img online">
              <img src={chat.user?.profile?.path} alt="" />
              {/*chat.user?.activity_status==1 &&<span className="user-status"></span>*/}
            </div>
            <div className="user-chat-content">
              <div className="ctext-wrap">
                <div className="conversation-name">
                  <small>{chat.user?.name}</small>
                  <small className="text-muted time">{chat.created_at}</small>
                  <span className="text-success check-message-icon">
                    <i className="bx bx-check-double"></i>
                  </span>
                  {/* Double ticks */}
                  {chat.is_seen ? (
                    <span>
                      {chat.is_loggedin_user ? (
                        <i
                          className="bx bx-check-double"
                          style={{ color: "#0c63ff" }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  ) : (
                    ""
                  )}

                  {chat.is_pinned === 1 ? (
                    <i className="bx bx-bookmarks text-muted ms-2"></i>
                  ) : (
                    ""
                  )}
                  <div className="dropdown align-self-start message-box-drop">
                    {" "}
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      <i className="ri-more-2-fill"></i>{" "}
                    </a>
                    <div className="dropdown-menu">
                      <a
                        className="dropdown-item d-flex align-items-center justify-content-between"
                        href="#"
                        onClick={() => pinChatElement(chat.id, index)}
                      >
                        {chat.is_pinned === 1 ? "Remove Bookmark" : "Bookmark"}{" "}
                        <i className="bx bx-bookmarks text-muted ms-2"></i>
                      </a>
                      {userToken?.user?.is_admin==1 && (
                        <a
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#addcontactModal"
                          className="dropdown-item d-flex align-items-center justify-content-between"
                          href="#"
                          onClick={() => getGroupAndMembers(chat.id)}
                        >
                          Share{" "}
                          <i className="bx bx-share-alt text-muted ms-2 flip-icon"></i>
                        </a>
                      )}
                      {userToken?.user?.is_admin==1 && (
                        <div>
                          {chatType == 1 ? (
                            <a
                              type="button"
                              className="dropdown-item d-flex align-items-center justify-content-between"
                              href="#"
                              onClick={() =>
                                markImportant(chat.id, [{ user_id: id }])
                              }
                            >
                              Add to inbox
                              <i className="bx bx-star text-muted ms-2 flip-icon"></i>
                            </a>
                          ) : (
                            <a
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#addimportantModal"
                              className="dropdown-item d-flex align-items-center justify-content-between"
                              href="#"
                              onClick={() => getGroupAndMembers(chat.id)}
                            >
                              Add to inbox
                              <i className="bx bx-star text-muted ms-2 flip-icon"></i>
                            </a>
                          )}
                        </div>
                      )}
                      {chat.is_loggedin_user == 1 ? (
                        <>
                          {chat.message != "" && (
                            <a
                              className="dropdown-item d-flex align-items-center justify-content-between delete-item"
                              href="#"
                              onClick={() => EditChat(chat.id, index)}
                            >
                              Edit{" "}
                              <i className="bx bx-edit-alt text-muted ms-2"></i>
                            </a>
                          )}
                          <a
                            className="dropdown-item d-flex align-items-center justify-content-between delete-item"
                            href="#"
                            onClick={() =>
                              removeChatElement(
                                "removeChatElement",
                                chat.id,
                                index
                              )
                            }
                          >
                            Delete{" "}
                            <i className="bx bx-trash text-muted ms-2"></i>
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                      {/* <a
                        type="button"
                        className="dropdown-item d-flex align-items-center justify-content-between"
                        href="#"
                        onClick={() => group(chat.message, chat.id)}
                      >
                        Create Task
                        <i className="bx bx-task text-muted ms-2 flip-icon"></i>
                      </a> */}
                    </div>
                  </div>
                </div>
                {chat.documents?.chat_media.length > 0 && (
                  <div className="message-img mb-2">
                    {chat.documents?.chat_media.length > 0 &&
                      chat.documents?.chat_media?.map((img, img_index) => (
                        <div className="message-img-list" key={img_index}>
                          <div>
                            <a
                              className="popup-img d-inline-block"
                              href={img.path}
                              target="_blank"
                              download
                            >
                              <img
                                src={img.path}
                                alt=""
                                className="rounded border"
                              />
                            </a>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
                {chat.documents?.chat_files.length > 0 && (
                  <div className="message-img mb-2">
                    {chat.documents?.chat_files.length > 0 &&
                      chat.documents?.chat_files?.map((file, file_index) => (
                        <div className="card p-2 border mb-2" key={file_index}>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 avatar-xs ms-1 me-3">
                              <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                <i className="bx bx-file"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <h5 className="font-size-14 text-truncate mb-1">
                                {file.original_name}
                              </h5>
                            </div>
                            <div className="flex-shrink-0 ms-3">
                              <div className="d-flex gap-2">
                                <div>
                                  <a
                                    href={file.path}
                                    target="_blank"
                                    download
                                    className="text-muted px-1"
                                  >
                                    <i className="bx bxs-download"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
                {chat.message != "" && (
                  <>
                    {editChatID == chat.id ? (
                      <>
                        <Editor
                          initialValue={chat.message}
                          //apiKey="f4hongk7ji9lnz5m8u5wu632h675ohs6jykw4jqihy5gzua1"
                          init={{
                            plugins:
                              "autoresize anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount fullscreen",
                            toolbar: "emoticons",
                            toolbar_location: "bottom",
                            menubar: false,
                            max_height: 420,
                            width: "100%",
                            placeholder: "Enter message . . .",
                            default_link_target: "_blank",
                            link_quicklink: true,
                            auto_focus: true,
                            selector: "div",
                          }}
                          onEditorChange={(e) => handleEditorChange(e)}
                          value={message}
                          outputFormat="html"
                          inline={false}
                        />
                        <div className="text-right">
                          <button
                            className="m-2 btn btn-light btn-sm"
                            onClick={handleCancleMessage}
                          >
                            Cancel
                          </button>
                          <button
                            className="m-2 btn btn-primary btn-sm"
                            onClick={handelSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="ctext-wrap-content">
                        <div className="mb-0 ctext-content">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: linkifyHtml(chat.message, {
                                target: "_blank",
                              }),
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </li>
      ))}
    </>
  );
}
export default ChatList;
