import React, { useState , useEffect} from 'react';
import { useParams, Link, useNavigate } from "react-router-dom";
const Overview = ({dashboard,groupMember,markFavoriteElement,group,groupadmin,showDeletePopup,userTokenLogin,ShowUsersModal,updateGroupPosition,projectStage,fullDetail}) => {

  return (
    <>
        <div className="bg-white p-4" style={{"overflowY": "scroll","height": "83vh"}}>
            <h4 className="text-center mb-4">Project Overview</h4>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className="card mb-4">
                        <div className="card-header">Project Details</div>
                        <div className="card-body">
                            <p><strong>Project Name:</strong> {group.name}</p>
                            <p><strong>Objective:</strong> {group.description}</p>
                        </div>
                    </div>
                    <div className="card mb-4">
                        <div className="card-header">Members</div>
                        <div className="card-body">
                            <div><strong>Lead:</strong> 
                                <ul className="groupmemberlist list-unstyled chat-list mx-n4">
                                    <li className="p-2">
                                    <Link
                                        to={
                                        groupadmin.is_loggedin_user == true &&
                                        `/m/${groupadmin.employee_id}/${groupadmin.id}`
                                        }
                                        style={{ display: "inline-block" }}
                                    >
                                        <div className="d-flex align-items-center">
                                        <div className="chat-user-img online align-self-center me-2 ms-0">
                                            <img
                                            src={groupadmin.profile.path}
                                            className="rounded-circle avatar-xs"
                                            alt=""
                                            />
                                            {groupadmin.activity_status == 1 && (
                                            <span className="user-status"></span>
                                            )}
                                        </div>
                                        <div className="overflow-hidden">
                                            <p className="text-truncate mb-0">
                                            {groupadmin.name}
                                            </p>
                                        </div>
                                        </div>
                                    </Link>
                                    </li>
                                </ul>
                            </div>
                            <div><strong>Admin:</strong> 
                                <ul className="groupmemberlist list-unstyled chat-list mx-n4">
                                    <li className="p-2">
                                    <Link
                                        to={
                                        groupadmin.is_loggedin_user == true &&
                                        `/m/${groupadmin.employee_id}/${groupadmin.id}`
                                        }
                                        style={{ display: "inline-block" }}
                                    >
                                        <div className="d-flex align-items-center">
                                        <div className="chat-user-img online align-self-center me-2 ms-0">
                                            <img
                                            src={groupadmin.profile.path}
                                            className="rounded-circle avatar-xs"
                                            alt=""
                                            />
                                            {groupadmin.activity_status == 1 && (
                                            <span className="user-status"></span>
                                            )}
                                        </div>
                                        <div className="overflow-hidden">
                                            <p className="text-truncate mb-0">
                                            {groupadmin.name}
                                            </p>
                                        </div>
                                        </div>
                                    </Link>
                                    </li>
                                </ul>
                            </div>
                            <div><strong>Team Members:</strong> 
                                <ul className="groupmemberlist list-unstyled chat-list mx-n4">
                                    {groupMember.map((row, index) => (
                                    <li className="p-2" key={index}>
                                        <Link
                                        to="#"
                                        style={{ display: "inline-block" }}
                                        >
                                        <div className="d-flex align-items-center">
                                            <div className="chat-user-img online align-self-center me-2 ms-0">
                                            <img
                                                src={row.profile.path}
                                                className="rounded-circle avatar-xs"
                                                alt=""
                                            />
                                            {row.activity_status == 1 && (
                                                <span className="user-status"></span>
                                            )}
                                            </div>
                                            <div className="overflow-hidden">
                                            <p className="text-truncate mb-0">
                                                {row.name}
                                            </p>
                                            </div>
                                        </div>
                                        </Link>
                                        {group.is_current_user_admin === true &&
                                        row.is_admin == false && (
                                            <span
                                            className="float-end p-2"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                showDeletePopup(
                                                "deleteFromGroup",
                                                row.id,
                                                index
                                                )
                                            }
                                            >
                                            <i className="bx bx-trash ms-2 text-muted"></i>
                                            </span>
                                        )}
                                    </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className="card mb-4">
                        <div className="card-header">Project Stats</div>
                        <div className="card-body">
                            <p><strong>Total Hours:</strong> {fullDetail.project_total_hours}</p>
                            {fullDetail?.employee_hours?.map((row, index) => (
                                <p key={index}><strong>{row?.user?.name}:</strong> {row?.hours}H {row?.minutes}M</p>
                            ))}
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">Current Stage</div>
                        <div className="card-body">
                            <select
                              className="form-select"
                              onChange={(e) => updateGroupPosition(e.target.value)}
                              defaultValue={projectStage}
                              disabled={(group.is_current_user_admin === true || userTokenLogin.user.is_admin==true)?false:true}
                            >
                              
                              <option value="0">Enquiry</option>
                              <option value="1">Overview</option>
                              <option value="2">Checklist</option>
                              <option value="3">Timeline</option>
                              <option value="4">on Going</option>
                              <option value="5">On Hold</option>
                              <option value="6">Done</option>
                              <option value="7">Completed</option>
                            </select>
                        </div>
                    </div>
                    <div className="card mb-4">
                      <div className="card-header">Action</div>
                      <div className="card-body">
                        <div className="row text-center">
                          <div className="col-sm col-3">
                            <div className="">
                              <button
                                type="button"
                                className="btn avatar-sm p-0 favourite-btn"
                                onClick={() => markFavoriteElement()}
                              >
                                <span className="avatar-title rounded bg-light text-body">
                                  <i className="bx bx-heart"></i>
                                </span>
                              </button>
                              <h5 className="font-size-11 text-uppercase text-muted mt-2">
                                {group?.is_favorite === 1
                                  ? "Remove Favorite"
                                  : "Mark As Favorite"}
                              </h5>
                            </div>
                          </div>
                          {groupadmin.is_loggedin_user == false && (
                            <div className="col-sm col-3">
                              <div className="">
                                <button
                                  type="button"
                                  className="btn avatar-sm p-0 favourite-btn"
                                  onClick={() =>
                                    showDeletePopup("leaveGroup", "", "")
                                  }
                                >
                                  <span className="avatar-title rounded bg-light text-body">
                                    <i className="bx bx-trash align-middle"></i>
                                  </span>
                                </button>
                                <h5 className="font-size-11 text-uppercase text-muted mt-2">
                                  Leave Group
                                </h5>
                              </div>
                            </div>
                          )}
                          {groupadmin.is_loggedin_user == true && (
                            <div className="col-sm col-3">
                              <div className="">
                                <button
                                  type="button"
                                  className="btn avatar-sm p-0 favourite-btn"
                                  onClick={() =>
                                    showDeletePopup("leaveGroup", "", "")
                                  }
                                >
                                  <span className="avatar-title rounded bg-light text-body">
                                    <i className="bx bx-trash align-middle"></i>
                                  </span>
                                </button>
                                <h5 className="font-size-11 text-uppercase text-muted mt-2">
                                  Delete Group
                                </h5>
                              </div>
                            </div>
                          )}
                          {
                            /*mode == "private" && (*/
                            userTokenLogin.user.employee_type == 'inhouse' && (
                            <div className="col-sm col-3">
                              <div className="">
                                <button
                                  type="button"
                                  className="btn avatar-sm p-0 favourite-btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#addcontactModall"
                                  onClick={ShowUsersModal}
                                >
                                  <span className="avatar-title rounded bg-light text-body">
                                    <i className="bx bx-plus"></i>
                                  </span>
                                </button>
                                <h5 className="font-size-11 text-uppercase text-muted mt-2">
                                  Add Member
                                </h5>
                              </div>
                            </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Overview;
