import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getApiCall, postApiCall, loginDetail } from "../../Service/Api";

function TaskActivity({
  handleUpdateHours,
  setTaskHours,
  setReview,
  currentTask,
  setCurrentTask,
  Review,
  TaskHours,
  handleData,
  TaskMinute,
  setTaskMinute,
  IsWaiting,
  setTaskTag,
  Tags,
  FromData,
  ToData,
  logDate,
  setLogDate

}) {
  useEffect(() => {
    //setTaskStatus(currentTask?.task_status);
  }, [currentTask]);

  return (
    <>
      {currentTask && (
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
                <textarea
                autoComplete="off"
                type="text"
                className="form-control form-control-lg chat-input"
                autoFocus
                id="chat-input"
                placeholder="Type your message..."
                onChange={(e) => setReview(e.target.value)}
                value={Review}
                rows={1}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <div className="row">
                  <div className="col-sm-3">
                      <select className="form-select form-select-lg" onChange={(e) =>setTaskTag(e.target.value)} style={{width:'100%'}}>
                        <option value="">
                          Select Tag
                        </option>
                        {Tags?.map((obj, newIndex) => {
                          return (
                            <option value={obj.uuid}>
                              {obj.tag_name}
                            </option>
                          );
                        })}
                      </select>
                  </div>
                  <div className="col-sm-3">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Date"
                      onChange={(e) => setLogDate(e.target.value)}
                      max={ToData}
                      min={FromData}
                      value={logDate}
                    />
                  </div>
                <div className="col-sm-2">
                  <input
                    autoComplete="off"
                    type="text"
                    className="form-control form-control-lg chat-input"
                    autoFocus
                    id="chat-input"
                    placeholder="Hours..."
                    onChange={(e) =>
                      setTaskHours((v) =>
                        e.target.validity.valid ? e.target.value : v
                      )
                    }
                    value={TaskHours}
                    pattern="[0-9]*"
                    maxLength={2}
                  />
                </div>
                <div className="col-sm-2">
                  <input
                    autoComplete="off"
                    type="text"
                    className="form-control form-control-lg chat-input"
                    autoFocus
                    id="chat-input"
                    placeholder="Minutes..."
                    onChange={(e) =>
                      setTaskMinute((v) =>
                        e.target.validity.valid ? e.target.value : v
                      )
                    }
                    value={TaskMinute}
                    pattern="[0-9]*"
                    maxLength={2}
                  />
                </div>
                <div className="col-auto">
                  <div className="chat-input-links ms-2 gap-md-1">
                    <div className="links-list-item">
                      <button className="btn btn-primary btn-lg chat-send waves-effect waves-light" onClick={() => handleUpdateHours(currentTask)} {...IsWaiting?'disabled':'false'}>
                        <i className="bx bxs-send align-middle"></i> 
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      )}
    </>
  );
}

export default TaskActivity;
