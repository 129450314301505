import { Link } from "react-router-dom";
import { getApiCall, loginDetail } from "../../../Service/Api";
function Header({
  name,
  profile,
  other,
  activity_status,
  enquiry_id,
  headerFor = "user",
  openTab = null,
  SelectedTab = null,
  projectStage
}) {
  const userToken = loginDetail();
  return (
    <div className="row align-items-center">
      <div className={(headerFor == "group")?"col-sm-12 col-md-4 col-lg-4":"col-sm-12"}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 d-block d-lg-none me-3">
            <Link to="/chat">
              {" "}
              <a href="" className="user-chat-remove font-size-18 p-1">
                <i className="bx bx-chevron-left align-middle"></i>
              </a>
            </Link>
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                {profile == "private" && (
                  <h2>
                    <i className="bx bx-lock"></i>
                  </h2>
                )}
                {profile == "public" && <h2>#</h2>}
                {profile != "public" && profile != "private" && (
                  <img
                    src={profile}
                    className="rounded-circle avatar-sm"
                    alt=""
                  />
                )}

                {activity_status == 1 && <span className="user-status"></span>}
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <h6 className="text-truncate mb-0 font-size-18">
                  {headerFor == "user" ? (
                  <a href="#" className="user-profile-show text-reset">
                    {name}
                  </a>
                  ):(
                    <a href="#" className="text-reset" onClick={() => openTab("overview")}>
                    {name}
                  </a>
                  )}
               
                </h6>
                <p className="text-truncate text-muted mb-0">
                  <small>{other}</small>
                </p>
              </div>
            </div>
          </div>
          {headerFor == "user" && (
            <ul className="flex-grow-2 list-inline user-chat-nav text-end mb-0 d-flex align-items-center justify-content-end">
              <li className="list-inline-item">
                <div className="dropdown">
                  <button
                    className="btn nav-btn"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target=".audiocallModal"
                  >
                    {/* <i className="bx bx-search"></i> */}
                    <img
                      src="../../../assets/svg/search.svg"
                      alt="date"
                      width={20}
                    />
                  </button>
                </div>
              </li>
              <li className="list-inline-item d-lg-inline-block me-2 ms-0">
                <button type="button" className="btn nav-btn user-profile-show">
                  {/* <i className="bx bxs-info-circle"></i> */}
                  <img
                    src="../../../assets/svg/account.svg"
                    alt="date"
                    width={20}
                  />
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
      {headerFor == "group" && (
      <div className="col-sm-8" style={{ textAlign: "right" }}>
          <ul className="list-inline user-chat-nav mb-0" id="headerSubMenu">
            <>
              
              {userToken.user.employee_type == "inhouse" && (
               <>
               <  li className="list-inline-item" onClick={() => openTab("overview")}>
                  <Link to="#" className={SelectedTab == "overview" ? "active" : ""}>Overview</Link>
                </li>
                {projectStage >=2 && (
                  <li
                      className="list-inline-item"
                      onClick={() => openTab("Checklist")}
                    >
                      <Link
                        to="#"
                        className={SelectedTab == "Checklist" ? "active" : ""}
                      >
                        Checklist
                      </Link>
                    </li>
                )}
                </>
              )}
              {userToken.user.employee_type == "inhouse" && (
                <>
                {projectStage >=3 && (
                  <li
                    className="list-inline-item"
                    onClick={() => openTab("Timeline")}
                  >
                    <Link
                      to="#"
                      className={SelectedTab == "Timeline" ? "active" : ""}
                    >
                      Timeline
                    </Link>
                  </li>
                )}
                </>
              )}
              {projectStage >=0 && (
              <li className="list-inline-item" onClick={() => openTab("Chat")}>
                <Link to="#" className={SelectedTab == "Chat" ? "active" : ""}>
                  Chat
                </Link>
              </li>
              )}
              {projectStage >=4 &&  userToken.user.employee_type == "inhouse" && (
                <li
                  className="list-inline-item"
                  onClick={() => openTab("Task")}
                >
                  <Link
                    to="#"
                    className={SelectedTab == "Task" ? "active" : ""}
                  >
                    Board
                  </Link>
                </li>
              )}
              <li className="list-inline-item" onClick={() => openTab("Files")}>
                <Link to="#" className={SelectedTab == "Files" ? "active" : ""}>
                  Document
                </Link>
              </li>
              <li className="list-inline-item" onClick={() => openTab("Media")}>
                <Link to="#" className={SelectedTab == "Media" ? "active" : ""}>
                  Photos
                </Link>
              </li>
              {userToken.user.employee_type == "inhouse" && (
                <li
                  className="list-inline-item"
                  onClick={() => openTab("Report")}
                >
                  <Link
                    to="#"
                    className={SelectedTab == "Report" ? "active" : ""}
                  >
                    Report
                  </Link>
                </li>
              )}
              <li className="list-inline-item">
                <button
                  className="btn nav-btn"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target=".audiocallModal"
                >
                  <i className="bx bx-search"></i>
                </button>
              </li>
            </>
          </ul>
        
      </div>
      )}
    </div>
  );
}
export default Header;
