import PinList from '../PinList';
function PinAlert({pinnedMessageCount}){
    return (
        <>
        {pinnedMessageCount > 0 &&
        <>
        <div className="mb-0 row alert alert-warning alert-dismissible topbar-bookmark fade show p-1 px-3 px-lg-4 pe-lg-5 pe-5" role="alert">
            <div className="d-flex align-items-start bookmark-tabs">
                <div className="tab-list-link">
                    <a href="#" className="tab-links" data-bs-toggle="modal" data-bs-target=".pinnedtabModal"><i className="ri-pushpin-fill align-middle me-1"></i> {pinnedMessageCount} Pinned</a>
                </div>
            </div>
            {/*<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>*/}
        </div>
        </> 
        }
    </>);
}
function PinPopup({data,pinnedMessageCount,pinChatElement}){
    return (
    <div className="modal fade pinnedtabModal" tabIndex="-1" role="dialog" aria-labelledby="pinnedtabModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content modal-header-colored shadow-lg border-0">
                <div className="modal-header">
                    <h5 className="modal-title text-white font-size-16" id="pinnedtabModalLabel">Bookmark</h5>
                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <div className="modal-body p-4">
                    <div className="d-flex align-items-center mb-3">
                        <div className="flex-grow-1">
                            <div>
                                <h5 className="font-size-16 mb-0">{pinnedMessageCount} Pinned tabs</h5>
                            </div>
                        </div>
                    </div>
                    <div className="chat-message-list chat-bookmark-list mx-n4" data-simplebar style={{ height: "299px" }}>
                        <ul className="list-unstyled chat-conversation">
                            <PinList data={data} pinChatElement={pinChatElement}/>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
export {
    PinPopup,
    PinAlert
}