import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { loginDetail, getApiCall, postApiCall } from "../Service/Api";

 
const Log = ({ actualWorkingSeconds,setLoading,currentStatus,setCurrentStatus,setIsTrackingActive }) => {
  
  const [time, setTime] = useState(actualWorkingSeconds);
  const userToken = loginDetail();
  useEffect(() => {
    let interval;
    if(currentStatus=='working'){
        interval = setInterval(() => {setTime(prevTime => prevTime + 1);}, 1000);
    }else if(time !== 0){
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [currentStatus]);

  const handleBreak = async () => {
    try {
      const response = await postApiCall("clock-manager/update-work-status?rundev=rundev",{ type: "break_start" });if(response){
        setCurrentStatus('break');
        setIsTrackingActive(false);
        if (window.electron) {
          const { ipcRenderer } = window.electron;
  
          ipcRenderer.send('pause-screenshots');
          console.log('sent');
        } else {
          console.error('window defined');
        }

      }
    } catch (error) {
      console.error('Error starting break', error);
    }
  };
  const handleStopBreak = async () => {
    try {
      const response = await postApiCall("clock-manager/update-work-status?rundev=rundev",{ type: "break_end" });
      if(response){
        setCurrentStatus('working');
        setIsTrackingActive(true);
        if (window.electron) {
          const { ipcRenderer } = window.electron;
  
          ipcRenderer.send('start-screenshots', {
            employee_id: userToken.user.employee_id,
            user_configuration: {
              is_image_taken: userToken.user.user_configuration.is_image_taken,
              image_time_taken: userToken.user.user_configuration.image_time_taken,
              is_time_taken: userToken.user.user_configuration.is_time_taken
            }
          });
          console.log('sent');
        } else {
          console.error('window not defined');
        }
        
      }
      
    } catch (error) {
      console.error('Error starting break', error);
    }
  };
  

  const handleClockOut = async () => {
    setLoading(true);
    try {

      const response = await postApiCall("clock-manager/update-work-status?rundev=rundev",{ type: "end" });
      
      if(response){
        setIsTrackingActive(false);
        setLoading(false);
        setCurrentStatus('end');

        if (window.electron) {
          const { ipcRenderer } = window.electron;
          ipcRenderer.send('pause-screenshots');
        } else {
          console.error('window not defined');
        }
        window.location.reload();
      }
      
    } catch (error) {
      console.error('Error clocking out', error);
    }
  };

  return (
    <>
      <div className="dashboard-sections mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="header">
            <img src="../../assets/svg/time-dark.svg" alt="date" />
            <p className="m-0">Clock In Log</p>
          </div>
          <Link to="/employeereport">View All Logs</Link>
        </div>
        <div className="row">
            <div className="col-sm-12 text-center">
            <h2>{new Date(time * 1000).toISOString().substr(11, 8)}</h2>
            {currentStatus=='working' && (
              <button className="btn btn-warning"  onClick={handleBreak} style={buttonStyle}>Start Break</button>
            )}
            {currentStatus=='break' && (
              <button className="btn btn-warning"  onClick={handleStopBreak} style={buttonStyle}>Stop Break</button>
            )}
            <button className="btn btn-danger" onClick={handleClockOut} style={buttonStyle}>Clock Out</button>
          </div>
        </div>
        {/*<table className="mt-4">
          <thead>
            <th>Date</th>
            <th>Clock In</th>
            <th>Clock Out</th>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr>
                <td className="font-weight-bold text-grey">{item.date}</td>
                <td className="font-weight-bold">{item.intime}</td>
                <td className="font-weight-bold">{item.outtime?item.outtime:"----"}</td>
              </tr>
            ))}
          </tbody>
        </table>*/}
      </div>
    </>
  );
};
const buttonStyle = {
  margin: '10px',
  padding: '10px 20px',
  fontSize: '16px',
};

export default Log;
