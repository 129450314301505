import React, { useState } from "react";
import {postApiCall } from "../../Service/Api";
import { useParams } from "react-router-dom";

function TaskMessage({ projectTaskTwo, Tags, setTaskData, TaskData }) {
  const { id } = useParams();
  const [Task, setTask] = useState("");
  const [EstimateTime, setEstimateTime] = useState("");
  const [EstimateMinute, setEstimateMinute] = useState("");
  const [Disable, setDisable] = useState(false);

  const handleSendMessage = async () => {
    setDisable(true);
    let payload = {
      task_name: Task,
      project_id: id,
      status: "1",
      expected_hours: EstimateTime,
      expected_minutes: EstimateMinute,
      description: "",
      rundev:'rundev'
    };
    const response = await postApiCall("task-manager/task", payload);
    if (response.status) {
      setTask("");
      setEstimateTime("");
      setEstimateMinute("");
      projectTaskTwo();
    }
    setDisable(false);
  };

  const handleChange = (e) => {
    setTask(e.target.value);

    // const filtered = TaskData.filter((item) =>
    //   item?.includes(e.target.value.toLowerCase())
    // );
    // setTaskData(filtered);
    // console.log(TaskData);
  };

  return (
    <>
      <div className="position-relative mb-3">
        <div className="chat-input-section p-3 p-lg-4">
          <div className="row g-0 align-items-center">
            <div className="col-sm-8">
              <div className="position-relative">
                <div className="chat-input-feedback">
                  Please Enter Your Task
                </div>
                <input
                  autoComplete="off"
                  type="text"
                  className="form-control form-control-lg chat-input chat-input-1"
                  autoFocus
                  id="chat-input"
                  placeholder="Type your message..."
                  // onChange={(e) => setTask(e.target.value)}
                  onChange={(e) => handleChange(e)}
                  value={Task}
                />
              </div>
            </div>
            <div className="col-sm-2 m-1">
              <input
                autoComplete="off"
                type="text"
                className="form-control form-control-lg chat-input chat-input-1"
                
                id="chat-input"
                placeholder="Estimate Hours..."
                onChange={(e) =>
                  setEstimateTime((v) =>
                    e.target.validity.valid ? e.target.value : v
                  )
                }
                value={EstimateTime}
                pattern="[0-9]*"
                maxLength={2}
              />
            </div>
            <div className="col-sm-1 m-1">
              <input
                autoComplete="off"
                type="text"
                className="form-control form-control-lg chat-input"
                
                id="chat-input"
                placeholder="Minute..."
                onChange={(e) =>
                  setEstimateMinute((v) =>
                    e.target.validity.valid ? e.target.value : v
                  )
                }
                value={EstimateMinute}
                pattern="[0-9]*"
                maxLength={2}
              />
            </div>
            <div className="col-auto">
              <div className="chat-input-links ms-2 gap-md-1">
                {Disable ? (
                  <div className="links-list-item">
                    <button
                      className="btn btn-primary btn-lg chat-send waves-effect waves-light"
                      disabled
                      onClick={handleSendMessage}
                    >
                      <i className="bx bxs-send align-middle"></i>
                    </button>
                  </div>
                ) : (
                  <div className="links-list-item">
                    <button
                      className="btn btn-primary btn-lg chat-send waves-effect waves-light"
                      onClick={handleSendMessage}
                    >
                      <i className="bx bxs-send align-middle"></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
}

export default TaskMessage;
