function ChatMedia({ data }) {
  // console.log(data);

  const commonFiles = data?.map((file, index) => (
    <div className="media-img-list col-sm-3 p-2" key={index}>
      <div className="innerMedia">
        <a href={file.path} target="_blank">
          <img src={file.path} alt="media img" className="img-fluid" />
          {/* <p>{file.original_name}</p> */}
        </a>
      </div>
    </div>
  ));
  return (
    <div className="row">{commonFiles} </div>
  );
}
export default ChatMedia;
