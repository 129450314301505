import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {Link} from 'react-router-dom';
import { getApiCall, postApiCall } from "../Service/Api";


export default function Login({ setToken }) {
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [employee_id, setEmployeeId] = useState();
  const [otp, setOtp] = useState();
  const [message, setMessage] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };


  let FirebaseToken = localStorage.getItem('firebase-token');

  const handleSubmit_new = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    let callAPi='task-manager/attempt-web-otp';
    let payload = {
      employee_id: employee_id
    }
    if(activePage>=2){
      callAPi='task-manager/verify-web-otp';
      payload['otp']=otp;
      payload['browser_id']=FirebaseToken;
    }
    // console.log(payload);
    // return
    const response = await postApiCall(callAPi, payload);
    if (response.status) {
      if(activePage==2){
        setToken(response);
        window.location.href='/';
      }else{
        setActivePage(activePage+1);
      }
    }
    setLoading(false);
    return false;
  };

  const ResendOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    let callAPi='task-manager/attempt-web-otp';
    let payload = {
      employee_id: employee_id
    }
    const response = await postApiCall(callAPi, payload);
    setLoading(false);
    return false;
  };

  return (
    <div className="auth-bg">
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-xl-3 col-lg-4">
            <div className="p-4 pb-0 p-lg-5 pb-lg-0 auth-logo-section">
              <div className="text-white-50">
                <h3>
                  <a href="#" className="text-white">
                    <i className="bx bxs-message-alt-detail align-middle text-white h3 mb-1 me-2"></i>{" "}
                    TeamTrack
                  </a>
                </h3>
              </div>
              <div className="mt-auto">
                <img
                  src="/assets/images/login-security.svg"
                  alt=""
                  className="auth-img"
                />
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="authentication-page-content">
              <div className="d-flex flex-column h-100 px-4 pt-4">
                <div className="row justify-content-center my-auto">
                  <div className="col-sm-8 col-lg-8 col-xl-8 col-xxl-8">
                    <div className="py-md-5 py-4">
                      <div className="text-center mb-5">
                        <h3>Welcome to TeamTrack</h3>
                        <p className="text-muted">
                          To get started, please sign in
                        </p>
                      </div>
                      <p>{message}</p>
                      <form onSubmit={handleSubmit_new}>
                      {
                        activePage>=1 && 
                        <div className="mb-3">
                          <label htmlFor="username" className="form-label">
                            Email ID / Employee ID
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                            placeholder="Employee Id"
                            onChange={(e) => setEmployeeId(e.target.value)}
                          />
                          {activePage>1 &&
                            <p className="text-right"><Link onClick={ResendOTP}>Resend OTP</Link></p>
                          }
                        </div>
                      }
                      {
                        activePage>=2 && 

                        <div className="mb-3">
                          <label htmlFor="userpassword" className="form-label">
                            OTP
                          </label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            {/* <input type="password" className="form-control pe-5" placeholder="Enter Password" id="password-input" onChange={e => setOtp(e.target.value)}/> */}
                            <input
                              placeholder="OTP"
                              name="password"
                              type={passwordShown ? "text" : "password"}
                              className="form-control pe-5"
                              id="password-input"
                              onChange={(e) => setOtp(e.target.value)}
                            />
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                            >
                              <i
                                className="ri-eye-fill align-middle"
                                onClick={togglePasswordVisiblity}
                              ></i>
                            </button>
                          </div>
                        </div>
                      }

                        <div className="text-center mt-4">
                          <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                          <span hidden={loading}>Log In</span>
                          <i className="ri-loader-3-line align-middle" hidden={!loading}></i></button>
                        </div>
                      </form>
                    </div>
                    <div className="text-center"><p>Don't have an account? <Link to="/create-account">Sign up</Link></p></div>
                  </div>
                  
                </div>

                <div className="row">
                  <div className="col-xl-12">
                    <div className="text-center text-muted p-4">
                      <p className="mb-0">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
                      <p className="mb-0"> Copyright <script> document.write(new Date().getFullYear()) </script> © <a href="https://www.devwings.com/">Devwings</a> All rights reserved.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
