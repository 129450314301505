/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { getApiCall, postApiCall, deleteApiCall } from "../Service/Api";
import {
  FaTrashAlt,
  FaCheck,
  FaEdit,
  FaSave,
  FaTimes,
  FaArrowRight,
} from "react-icons/fa";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import DeleteConfirmation from "../ChatPortal/component/DeleteConfirmation";

const Todo = ({latestTodo}) => {
  const [sections, setSections] = useState([
    {
      id: 1,
      title: "Today's To Do List",
      items: latestTodo,
    },
  ]);
  const [listIndex, setListIndex] = useState("");
  const [newItemText, setNewItemText] = useState("");
  const [newItemTime, setNewItemTime] = useState("");
  const [editItemText, setEditItemText] = useState("");
  const [editItemTime, setEditItemTime] = useState("");
  const [newItemMin, setNewItemMin] = useState("");
  const [newSectionTitle, setNewSectionTitle] = useState("");
  const [deleteBoxShow, setDeleteBoxShow] = useState(true);
  const [ItemId, setItemId] = useState("");

  const Today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    //api();
    //console.log(sections);
  }, []);

  const api = async () => {
    const response = await getApiCall(
      "task-manager/get-todos?rundev=rundev&start_date=" +
        Today +
        "&end_date=" +
        Today
    );
    if (response.status) {
      setSections([
        {
          id: 1,
          title: "TO DO List",
          items: response.data[0].todo,
        },
      ]);
    }
  };

  const handleAddNewItem = async (sectionId,event) => {
    event.preventDefault();
    let payload = {
      task_name: newItemText,
      expected_hours: newItemTime+'H:'+newItemMin+'M',
      hours: newItemTime,
      minutes: newItemMin,
    };

    const response = await postApiCall("task-manager/manage-todos", payload);
    if (response.status) {
      api();
      setNewItemText("");
      setNewItemTime("");
      setNewItemMin("");
    }
  };

  const handleRemoveItem = async (sectionId, itemId) => {
    window["showModal"]();
    setItemId(itemId);
  };

  async function confirmDelete() {
    const response = await deleteApiCall("task-manager/delete-todo/" + ItemId);
    if (response.status) {
      api();
      setItemId("");
      window["closeModal"]();
    }
  }

  const handleMarkAsDone = async (sectionId, itemId, status) => {
    const response = await postApiCall(
      "task-manager/toggle-todos-status/" + itemId,
      { done: status } // 1 for done, 2 for working
    );
    if (response.status) {
      api();
    }
  };

  const handleEditItem = (sectionId, itemId, newListIndex) => {
    const updatedTodos = [...sections];
    updatedTodos[0].items[0].editing = 0;
    if (listIndex != "") {
      updatedTodos[0].items[listIndex].editing = 0;
    }
    setListIndex(newListIndex);
    updatedTodos[0].items[newListIndex].editing = 1;
    setEditItemText(updatedTodos[0].items[newListIndex].task_name);
    setEditItemTime(updatedTodos[0].items[newListIndex].expected_hours);
    setSections(updatedTodos);
  };

  const handleSaveItem = async (sectionId, itemId) => {
    let payload = {
      task_name: editItemText,
      expected_hours: editItemTime,
    };
    const response = await postApiCall(
      "task-manager/manage-todos/" + itemId,
      payload
    );
    if (response.status) {
      api();
    }
  };

  const handleCancelEdit = (sectionId, itemId) => {
    const updatedTodos = [...sections];
    updatedTodos[0].items[listIndex].editing = 0;
    setSections(updatedTodos);
  };

  return (
    <>
      {deleteBoxShow && <DeleteConfirmation fElement={confirmDelete} />}
      <div className="dashboard-sections">
        <div className="d-flex align-items-center justify-content-between">
          <div className="header">
            <img src="../../assets/svg/to-do.svg" alt="date" />
            <p className="m-0">To-do List</p>
          </div>
          <Link to="/todo/todaystodo">View All</Link>
        </div>{" "}
        {sections.map((section, mainindex) => (
          
            <ul className="list-group list-group-flush mt-4" key={mainindex}>
              {section?.items?.map((item, index) =>
                
                  <li key={item.id} className="mb-2 list-group-item">
                    {item.editing ? (
                      <>
                        <div className="row">
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="form-control d-inline-block"
                              value={editItemText}
                              onChange={(e) => setEditItemText(e.target.value)}
                            />
                          </div>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control d-inline-block"
                              value={editItemTime}
                              onChange={(e) => setEditItemTime(e.target.value)}
                            />
                          </div>
                        </div>
                        <button
                          className="btn btn-primary mt-2"
                          onClick={() => handleSaveItem(section.id, item.id)}
                        >
                          Save
                        </button>
                        &nbsp;&nbsp;
                        <button
                          className="btn mt-2"
                          onClick={() => handleCancelEdit(section.id, item.id)}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <div className="row">
                        <div
                          style={{
                            textDecoration: item.done===1 ? "line-through" : "none",
                          }}
                          className="col-sm-8"
                          onClick={() =>
                            handleEditItem(section.id, item.id, index)
                          }
                        >
                          {item.task_name} - {item.expected_hours}
                        </div>
                        <div className="col-sm-4 text-right">
                        
                            
                            <a
                            className="text-danger btn-sm m-l-2 todo-edit-icon"
                            onClick={() =>
                              handleEditItem(section.id, item.id, index)
                            }
                            href="#!"
                          >
                            <img src="../../assets/svg/edit.svg" alt="date" />
                          </a>
                          {item.done !== 1 && (
                          <a
                              className="btn-sm m-l-2"
                              onClick={() => handleMarkAsDone(section.id, item.id, 2)}
                              href="#!"
                            >
                              {item.done === 2 ? "Currently Working" : "Start Working"}
                            </a>
                          )}
                          
                          <a
                            className="text-danger btn-sm m-l-2"
                            onClick={() =>
                              handleRemoveItem(section.id, item.id)
                            }
                            href="#!"
                          >
                            <img src="../../assets/svg/delete.svg" alt="date" />
                          </a>
                          
                          <a
                            className="btn-sm m-l-2"
                            onClick={() =>
                              handleMarkAsDone(section.id, item.id,1)
                            }
                            href="#!"
                          >
                            <FaCheck />
                          </a>
                        </div>
                      </div>
                    )}
                  </li>
                
              )}
            </ul>
          
        ))}
        <form method="POST" onSubmit={(e) => handleAddNewItem(1,e)} className="input-group mb-3 mt-4 gap-2">
          <input
            type="text"
            className="form-control"
            placeholder="Enter new task"
            value={newItemText}
            style={{ width: "50%" }}
            onChange={(e) => setNewItemText(e.target.value)}
            required
          />
          <input
            type="number"
            className="form-control"
            placeholder="Hours"
            max="9"
            value={newItemTime}
            onChange={(e) => setNewItemTime(e.target.value)}
            required
          />
          <input
            type="number"
            className="form-control"
            placeholder="Minute"
            max="60"
            value={newItemMin}
            onChange={(e) => setNewItemMin(e.target.value)}
            required
          />
          <div className="input-group-append">
            <button
              type="submit"
              className="btn btn-primary"
            >
              Add Task
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Todo;
