import { height } from "@mui/system";
import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getApiCall, postApiCall, loginDetail } from "../data/Api";
import FullScreenLoader from '../../FullScreenLoader';
function ProjectReport({userProjectData}) {
  const Today = new Date().toISOString().split("T")[0];
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const Week = `${firstDayOfMonth.getFullYear()}-${String(firstDayOfMonth.getMonth() + 1).padStart(2, '0')}-${String(firstDayOfMonth.getDate()).padStart(2, '0')}`;
    


  const [userData, setUserData] = useState([]);
  const [FromData, setFromData] = useState(Week);
  const [ToData, setToData] = useState(Today);
  const [ProjectFilter, setProjectFilter] = useState("");
  //const [userProjectData, setUserProjectData] = useState("");
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(loginDetail());

  const [summation, setSummation] = useState("");
  const [usersSummary, setUsersSummary] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle search query change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter data based on search query
  const filteredData = userData.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSummeryData = async () => {
    setProjectFilter("");
    setFromData(Week);
    setToData(Today);
  };

  const handleFilterData = async () => {
    setLoading(true);
    let payload = {
        start_date: FromData,
        end_date: ToData,
        rundev: "rundev",
        giveArray:"giveArray"
    };
    const response = await postApiCall("get-project-time-spent", payload);
    if (response) {
      setLoading(false);
      setUserData(response.data.report);
      setSummation(response.data.summation);
      setUsersSummary(response.data.users_summary);
    }
  };

  const handleResetData = () => {
    handleSummeryData();
  };
  useEffect(() => {
    if(profile?.user?.is_admin){
        handleFilterData();
    }
  }, []);

  return (
    <>
    {loading ? <FullScreenLoader /> :
      <>
        <div className="row align-items-center">
            <div className="col-sm-2">
              <div className="mb-4">
                <label className="mb-2">From Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Start Date"
                  onChange={(e) => setFromData(e.target.value)}
                  value={FromData}
                />
              </div>
            </div>
            <div className="col-sm-2">
              <div className="mb-4">
                <label className="mb-2">To Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="End Date"
                  onChange={(e) => setToData(e.target.value)}
                  value={ToData}
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div className="mt-2">
                <input
                  type="button"
                  className="btn btn-primary"
                  value="Filter"
                  onClick={handleFilterData}
                />
                &nbsp;&nbsp;
                <input
                  type="button"
                  className="btn btn-warning"
                  value="Reset Filter"
                  onClick={handleResetData}
                />
              </div>
            </div>
          </div>
          {usersSummary &&
          <>
          <div className="row mt-3">
            
            <div className="col-sm-3">
                Total Clock IN Time : {usersSummary.total_active_time}
            </div>
            <div className="col-sm-3">
                Total Summery Time : {usersSummary.total_working_time}
            </div>
            <div className="col-sm-3">
              <input
                type="text"
                placeholder="Search by Project name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="form-control"
              />
            </div>
          </div>
          <div
            className="row mt-3"
            style={{ overflowY: "auto", height: "70vh" }}
          >
            

            <div className="col-sm-12 mt-2">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Project Name</th>
                    <th>Total Time</th>
                    <th>Employee</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                    {filteredData?.map((item, index) => (
                      
                      (item.spent_hours.hours>'0' || item.spent_hours.minutes>'0'?(
                          <tr key={index}>
                              <td>{item.name}</td>
                              <td>{item.spent_hours.hours}H:{item.spent_hours.minutes}M</td>
                              <td>
                              {item?.userwise_report.map((user, i) => (
                                <p>{user.username} - {user.hours}:{user.minutes}</p>
                              ))}
                              </td>
                              <td><Link to={`/chat/g/${item.slug}/${item.id}`}>GO TO Channel</Link></td>
                          </tr>
                      ):(''))
                  ))}
               
                </tbody>
              </table>
            </div>
           
          </div>
          </>
          }
      </>
    }
    </>
  );
}

export default ProjectReport;
