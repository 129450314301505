import { height } from "@mui/system";
import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getApiCall, postApiCall, loginDetail } from "../data/Api";
import FullScreenLoader from '../../FullScreenLoader';
function Report({ dashboard, groupMember }) {
  const Today = new Date().toISOString().split("T")[0];
  const week = new Date();
  week.setDate(week.getDate() - 7);
  let Week = week.toISOString().split("T")[0];

  const { id } = useParams();
  const [userData, setUserData] = useState([]);
  const [FromData, setFromData] = useState(Week);
  const [ToData, setToData] = useState(Today);
  const [ProjectFilter, setProjectFilter] = useState("");
  const [userProjectData, setUserProjectData] = useState("");
  const [tags, setTags] = useState("");
  const [selectedTags, setSelectedTags] = useState("");
  const [selectedEmp, setSelectedEmp] = useState("");
  const [pattern, setPattern] = useState(2);
  const [loading, setLoading] = useState(false);
  const [totalTime, setTotalTime] = useState({ hours: 0, minutes: 0 });

  useEffect(() => {
    getTags();
    setUserData([]);
  }, [id]);

  async function getTags() {
    const response = await getApiCall("tag?rundev=rundev");
    if (response.status) {
      setTags(response.data);
    }
  }

  const handleSummeryData = async () => {
    setLoading(true);
    let payload = {
      start_date: Today,
      end_date: Week,
      project_id: id,
      tag_id: selectedTags,
      user_id: selectedEmp,
      rundev: "rundev",
    };
    const response = await postApiCall("get-project-report-new", payload);
    if (response.status) {
      setLoading(false);
      setUserData(response.data.tasks);
      setUserProjectData(response.data.activities);
    }else{
      setLoading(false);
    }
  };

  const handleFilterData = async () => {
    setLoading(true);
    let payload = {
      start_date: FromData,
      end_date: ToData,
      project_id: id,
      tag_id: selectedTags,
      user_id: selectedEmp,
      rundev: "rundev",
    };
    const response = await postApiCall("get-project-report-new", payload);
    if (response.status) {
      setUserData(response.data.tasks);
      setUserProjectData(response.data.activities);
      setLoading(false);
    }else{
      setLoading(false);
    }
  };

  const handleResetData = () => {
    setSelectedTags('');
    setSelectedEmp('');
    setFromData(Week);
    setToData(Today);
    handleSummeryData();
  };

  const downloadCSV = (csv, filename) => {
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvData);
    const hiddenElement = document.createElement("a");
    hiddenElement.href = csvUrl;
    hiddenElement.target = "_blank";
    hiddenElement.download = filename;
    hiddenElement.click();
  };

  const handleExport = () => {
    const table = document.getElementById("exportTable");
    const rows = table.querySelectorAll("tr");

    let csv = "";
    rows.forEach((row) => {
      const cells = row.querySelectorAll("td, th");
      const rowValues = Array.from(cells).map((cell) => cell.textContent);
      csv += rowValues.join(",") + "\n";
    });

    downloadCSV(csv, "report.csv");
  };

  useEffect(() => {
    let totalMinutes = 0;
    let totalHours = 0;

    userData.forEach(task => {
      totalHours += task.hours;
      totalMinutes += task.minutes;
    });

    // Convert total minutes to hours and minutes
    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;

    setTotalTime({ hours: totalHours, minutes: totalMinutes });
  }, [userData]);

  return (
    <>
      {loading ? <FullScreenLoader /> :
        <div className="user-chat w-100">
          <div className="p-4">
            <div className="row align-items-center">
              <div className="col-sm-3">
                <div className="mb-4">
                  <label className="mb-2">Employee</label>
                  <select
                    className="form-select"
                    onChange={(e) => setSelectedEmp(e.target.value)}
                    value={selectedEmp}
                  >
                    <option value="">Select Employee</option>
                    {groupMember &&
                      groupMember?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="mb-4">
                  <label className="mb-2">From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Start Date"
                    onChange={(e) => setFromData(e.target.value)}
                    defaultValue={FromData}
                    value={FromData}
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <div className="mb-4">
                  <label className="mb-2">To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="End Date"
                    onChange={(e) => setToData(e.target.value)}
                    defaultValue={ToData}
                    value={ToData}
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <div className="mb-4">
                  <label className="mb-2">Tags</label>
                  <select
                    className="form-select"
                    onChange={(e) => setSelectedTags(e.target.value)}
                    value={selectedTags}
                  >
                    <option value="">Select Tags</option>
                    {tags &&
                      tags?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.tag_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="mb-4">
                    <label className="mb-2">Report Type</label>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      setPattern(e.target.value);
                    }}
                    value={pattern}
                  >
                    <option value="2">Without Activity</option>
                    <option value="1">With Activity</option>
                    
                  </select>
                </div>
              </div>
              <div className="col-sm-12 mt-2">
                <div className="">
                  <input
                    type="button"
                    className="btn btn-primary"
                    value="Filter"
                    onClick={handleFilterData}
                  />
                  &nbsp;&nbsp;
                  <input
                    type="button"
                    className="btn btn-warning"
                    value="Reset Filter"
                    onClick={handleResetData}
                  />
                  &nbsp;&nbsp;
                  <input
                  type="button"
                  className="btn btn-warning"
                  value="Export"
                  onClick={handleExport}
                />
                {userProjectData &&
                  <label style={{"float":"right"}}>{totalTime.hours} hours and {totalTime.minutes} minutes</label>
                }
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div
                className="col-sm-12 reports-table-col"
                style={{
                  height: "100vh",
                  overflowY: "auto",
                  paddingBottom: "250px",
                }}
              >
                <table
                  className="table table-striped table-bordered"
                  id="exportTable"
                >
                  
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Task</th>
                      {pattern == 1 ? <th>Activity</th> : ""}
                      <th>Hours</th>
                      <th>Minutes</th>
                      {pattern == 1 ? <th>Tag</th> : ""}
                      {pattern == 1 ? <th>User</th> : ""}
                    </tr>
                  </thead>
                  {pattern == 1 ? (
                    <tbody>
                      {userProjectData &&
                        userProjectData?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{new Date(item.created_at).toLocaleDateString("en-IN")}</td>
                              <td>{item.tasks.task_name}</td>
                              <td style={{ width: "30%", padding: "10px" }}>
                                {item.remarks}
                              </td>
                              <td>
                                <strong>
                                  {("0" + item.spent_hours).slice(-2)}
                                </strong>
                              </td>
                              <td>
                                <strong>
                                  {("0" + item.spent_minutes).slice(-2)}
                                </strong>
                              </td>
                              <td>{item?.tag_name}</td>
                              <td>{item?.user?.name}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  ) : (
                    <tbody>
                      {userData && userData?.map((item, i) => {
                          return (
                              <tr key={i}>
                                <td>
                                  {new Date(item.updated_at).toLocaleDateString(
                                    "en-IN"
                                  )}
                                </td>
                                <td>{item.task_name}</td>
                                <td>
                                  <strong>
                                    {item.hours}
                                  </strong>
                                </td>
                                <td>
                                  <strong>
                                    {item.minutes}
                                  </strong>
                                </td>
                              </tr>
                          );
                        })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Report;
