
import React, { useEffect, useState, useRef } from "react";
import { getApiCall, postApiCall, loginDetail } from "../../Service/Api";
import FullScreenLoader from '../../FullScreenLoader';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

function ScreenReport({userProjectData}) {
  const Today = new Date().toISOString().split("T")[0];
  const week = new Date();
  week.setDate(week.getDate() - 0);
  let Week = week.toISOString().split("T")[0];

  
  const [userData, setUserData] = useState("");
  const [FromData, setFromData] = useState(Week);
  const [ToData, setToData] = useState(Today);
  const [ProjectFilter, setProjectFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(loginDetail());
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleSummeryData = async () => {
    setUserData("");
    setProjectFilter("");
    setFromData(Week);
    setToData(Today);
  };

  const handleFilterData = async () => {
    setLoading(true);
    const response = await getApiCall("get-user-file?rundev=rundev&employee_id="+ProjectFilter+"&start_date="+FromData+"&end_date="+ToData);
    if(response) {
      setUserData(response.data);
      setLoading(false);
    }
  };

  const handleResetData = () => {
    handleSummeryData();
  };


  return (
    <>
      {loading ? <FullScreenLoader /> :
        <>
        <div className="row align-items-center">
            <div className="col-sm-2 ">
              <div className="mb-4">
                <label className="mb-2">Employee</label>
                <select
                  className="form-select"
                  onChange={(e) => setProjectFilter(e.target.value)}
                  defaultValue={ProjectFilter}
                  value={ProjectFilter}
                >
                  <option value="">Select Option</option>
                  {profile?.user?.is_admin && (
                    <option value="">All</option>
                  )}
                  <option value={profile?.user?.employee_id}>Self</option>
                  {userProjectData &&
                    userProjectData?.map((item, i) => {
                      return (
                        <option value={item.employee_id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="mb-4">
                <label className="mb-2">From Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Start Date"
                  onChange={(e) => setFromData(e.target.value)}
                  value={FromData}
                />
              </div>
            </div>
            <div className="col-sm-2">
              <div className="mb-4">
                <label className="mb-2">To Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="End Date"
                  onChange={(e) => setToData(e.target.value)}
                  value={ToData}
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="mt-2">
                <input
                  type="button"
                  className="btn btn-primary"
                  value="Filter"
                  onClick={handleFilterData}
                />
                &nbsp;&nbsp;
                <input
                  type="button"
                  className="btn btn-warning"
                  value="Reset Filter"
                  onClick={handleResetData}
                />
              </div>
            </div>
        </div>
        <div className="row mt-3" style={{ overflowY: "auto", height: "70vh" }}>
            {userData &&
                userData?.map((item, index) => (
                    <div className="col-sm-3 text-center" key={index}>
                      <img
                        src={item.image}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                        alt={`Gallery image ${index}`}
                        style={{ width: '200px', margin: '10px' }}
                      />
                    <p>{item.created_at}</p>
                    </div>
                ))
            }
        </div>
        {isOpen && (
        <Lightbox
          mainSrc={userData[photoIndex].image}
          nextSrc={userData[(photoIndex + 1) % userData.length]}
          prevSrc={userData[(photoIndex + userData.length - 1) % userData.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + userData.length - 1) % userData.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % userData.length)}
          imageCaption={`${userData[photoIndex].created_at} (${photoIndex + 1} of ${userData.length})`}
        />
      )}
      </>
    }
       </>
  );
}

export default ScreenReport;