
import React, { useEffect, useState, useRef } from "react";
import { getApiCall, postApiCall, loginDetail } from "../data/Api";
import FullScreenLoader from '../../FullScreenLoader';
import { Modal, Button } from 'react-bootstrap';

function ReportInOut({userProjectData}) {
  
  const Today = new Date().toISOString().split("T")[0];
  const week = new Date();
  week.setDate(week.getDate() - 0);
  let Week = week.toISOString().split("T")[0];

  const [userData, setUserData] = useState("");
  const [FromData, setFromData] = useState(Week);
  const [ToData, setToData] = useState(Today);
  const [ProjectFilter, setProjectFilter] = useState("");
  //const [userProjectData, setUserProjectData] = useState("");
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(loginDetail());
  const [summation, setSummation] = useState("");
  const [usersSummary, setUsersSummary] = useState("");
  const [logDetail, setLogDetail] = useState("");
  const [modelShow, setModelShow] = useState(false);
  useEffect(() => {
    //getProject();
    
    if(profile?.user?.is_admin){
      handleFilterData();
    }
  }, []);

  /*async function getProject() {
    const response = await getApiCall("get-all-juniors?rundev=rundev");
    if (response.status) {
      setUserProjectData(response.data);
      if(profile?.user?.is_admin==true){
        setFromData(Today);
        setToData(Today);
        handleFilterData();
      }
    }
  }*/

  const handleSummeryData = async () => {
    setUserData("");
    setSummation("");
    setUsersSummary("");
    setProjectFilter("");
    setFromData(Week);
    setToData(Today);
  };

  const handleFilterData = async () => {
    setLoading(true);
    let payload = {
      from_date: FromData,
      to_date: ToData,
      user_id: ProjectFilter,
      rundev: "rundev",
      giveArray:"giveArray"
    };
    const response = await postApiCall("get-daily-work-summary", payload);
    if (response) {
      setLoading(false);
      setUserData(response.data.report);
      setSummation(response.data?.device_track);
      setUsersSummary(response.data.users_summary);
    }
  };

  const handleResetData = () => {
    handleSummeryData();
  };

  const handleClose = () => setModelShow(false);
  const handleShow = (task) => {
    setLogDetail(task.clock_tracks.log);
    setModelShow(true);
  };

  return (
    <>
    {loading ? <FullScreenLoader /> :
      <>
        <div className="row align-items-center">
            <div className="col-sm-2 ">
              <div className="mb-4">
                <label className="mb-2">Employee</label>
                <select
                  className="form-select"
                  onChange={(e) => setProjectFilter(e.target.value)}
                  defaultValue={ProjectFilter}
                  value={ProjectFilter}
                >
                  <option value="">Select Option</option>
                  {profile?.user?.is_admin && (
                    <option value="">All</option>
                  )}
                  <option value={profile?.user?.id}>Self</option>
                  {userProjectData &&
                    userProjectData?.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="mb-4">
                <label className="mb-2">From Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Start Date"
                  onChange={(e) => setFromData(e.target.value)}
                  value={FromData}
                />
              </div>
            </div>
            <div className="col-sm-2">
              <div className="mb-4">
                <label className="mb-2">To Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="End Date"
                  onChange={(e) => setToData(e.target.value)}
                  value={ToData}
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="mt-2">
                <input
                  type="button"
                  className="btn btn-primary"
                  value="Filter"
                  onClick={handleFilterData}
                />
                &nbsp;&nbsp;
                <input
                  type="button"
                  className="btn btn-warning"
                  value="Reset Filter"
                  onClick={handleResetData}
                />
              </div>
            </div>
        </div>
        {userData &&
            <>
            <div className="row">
                <div className="col-sm-2">
                  Active Time : <br />{usersSummary.total_active_time}
                </div>
                <div className="col-sm-2">
                  Office Time : <br />{usersSummary.total_office_time}
                </div>
                <div className="col-sm-2">
                  Summary Time : <br/>{usersSummary.total_working_time}
                </div>
                <div className="col-sm-2">
                  Device Active : <br />{summation.active_time}
                </div>
                <div className="col-sm-2">
                  Device InActive : <br />{summation.inactive_time}
                </div>
            </div>
            <div className="row mt-3" style={{ overflowY: "auto", height: "70vh" }}>
              
              <div className="col-sm-12 mt-2">
                <table className="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Clock IN</th>
                        <th>Clock Out</th>
                        <th>In Time</th>
                        <th>Break</th>
                        <th>Working Hours</th>
                        <th>Summary Hours</th>
                        <th>Active Time</th>
                        <th>InActive Time </th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userData &&
                        userData?.map((item, index) => (
                          item.summary?.map((sum, w) => (
                              <tr key={w}>
                                <td>{item.user.name}</td>
                                <td>{sum.date}</td>
                                <td>{sum.clock_tracks?.log[0].entry_time}</td>
                                <td>{sum.clock_tracks?.log[sum.clock_tracks?.log.length - 1]?.entry_time}</td>
                                <td>{sum.clock_tracks.clock_activity?.office_time}</td>
                                <td>{sum.clock_tracks.clock_activity?.break_time}</td>
                                <td>{sum.clock_tracks.clock_activity?.working_time}</td>
                                <td>{sum.clock_tracks.total_spent_hours}</td>
                                <td>{sum.clock_tracks.device_activity.active_time}</td>
                                <td>{sum.clock_tracks.device_activity.inactive_time}</td>
                                <td>
                                  <a
                                    className=" m-l-2"
                                    style={{display: "inline"}}
                                    href="#"
                                    onClick={() => handleShow(sum)}
                                    >
                                      <i className="bx bx-show text-muted ms-2"></i>
                                  </a>
                                </td>
                              </tr>
                          ))
                      ))
                    }
                    </tbody>
                </table>
              </div>

            </div>
          </>
        }
        </>
      }
      <Modal show={modelShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                  <th>Type</th>
                  <th>Time</th>
              </tr>
            </thead>
            <tbody>
            {logDetail &&
                logDetail?.map((sum, w) => (
                  <tr key={w}>
                    <td>{sum.type}</td>
                    <td>{sum.entry_time}</td>
                  </tr>
                  ))
                }
              
          </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
  
export default ReportInOut;


