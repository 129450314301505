import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getApiCall, postApiCall } from "../../Service/Api";
import TaskMessage from "../component/TaskMessage";
import TaskActivity from "../component/TaskActivity";
import DeleteConfirmation from "../../ChatPortal/component/DeleteConfirmation";
import FullScreenLoader from '../../FullScreenLoader';

function ProjectTask({ dashboard }) {
  const { id } = useParams();
  const [UserData, setUserData] = useState("");
  const [TaskData, setTaskData] = useState("");
  const [TaskHours, setTaskHours] = useState("");
  const [TaskMinute, setTaskMinute] = useState("");
  const [TaskTag, setTaskTag] = useState("");
  const [Review, setReview] = useState("");
  const [TasksLogs, setTasksLogs] = useState("");
  const [currentTask, setCurrentTask] = useState({});
  const [currentTaskIndex, setCurrentTaskIndex] = useState();
  const [Tags, setTags] = useState("");
  const [Users, setUsers] = useState("");
  const [FilterProject, setFilterProject] = useState("");
  const [FilterTags, setFilterTags] = useState("");
  const [FilterStatus, setFilterStatus] = useState("");
  
  const [TaskNumber, setTaskNumber] = useState("");
  const [ID, setID] = useState("");
  const [TagsData, setTagsData] = useState("");
  const [IsWaiting, setIsWaiting] = useState(false);
  const [SelectedUser, setSelectedUser] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [deleteBoxShow, setDeleteBoxShow] = useState(true);
  const [user_id, setuser_id] = useState("");
  const [TagId, setTagId] = useState("");
  const [StatusId, setStatusId] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  
  const Today = new Date().toISOString().split("T")[0];
  const week = new Date();
  week.setDate(week.getDate() - 1);
  let Week = week.toISOString().split("T")[0];

  const [FromData, setFromData] = useState(Week);
  const [ToData, setToData] = useState(Today);
  const [logDate, setLogDate] = useState(Today);

  useEffect(() => {
    projectTaskTwo();
  }, [id]);

  useEffect(() => {
    AllTags();
  }, []);

  const confirmDelete = async () => {};

  useEffect(() => {
    setTimeout(() => {
      if (currentTaskIndex >= 0) {
        openTaskDetails(TaskData[currentTaskIndex], currentTaskIndex);
      }
    }, 100);
  }, [TaskData]);

  const projectTaskTwo = async () => {
    setLoading(true);
    const response = await postApiCall("task-manager/get-project", { project_id: id,rundev:'rundev' });
    if (response.status) {
      setUserData(response.data);
      setTaskData(response.data.tasks);
      setTaskNumber(response.data);
      setLoading(false);
    }
  };

  const openTaskDetails = async (data, i) => {
    setCurrentTask(data);
    setCurrentTaskIndex(i);
  };

  const handleUpdateHours = async (taskID) => {
    setIsWaiting(true);
    if(TaskTag<1){
      alert('Valid Tag must be selected');
      return false;
    }
    let payload = {
      activity_hours: TaskHours,
      activity_minutes: TaskMinute,
      task_id: taskID,
      remarks: Review,
      rundev:'rundev',
      tag_id:TaskTag,
      created_at:logDate
    };
    const response = await postApiCall("task-manager/task-update-hours", payload);
    setIsWaiting(false);
    if (response.status) {
      setTaskHours("");
      setTaskMinute("");
      setTaskTag("");
      setReview("");
      projectTaskTwo();
    }
  };
  const AllTags = async () => {
    const response = await getApiCall("task-manager/tag?rundev=rundev");
    if (response.status) {
      setTags(response.data);
    }
  };

  const setBugTag = async (tagid,taskid) => {
    const response = await postApiCall("task-manager/update-task-activity-tag", {task_log_id:taskid,tag_id:tagid,rundev:'rundev'});
    if (response.status) {
      projectTaskTwo();
    }
  };

  return (
    <>
      {loading ? <FullScreenLoader /> :
      <div className="user-chat w-100 overflow-hidden" id="your_div">
        <div className="user-chat-overlay"></div>
        <div className="chat-content d-lg-flex">
          <div className="w-100  position-relative">
            {/* MAIN LI SECTION */}
            <div
              className="chat-conversation"
              id="chat-conversation"
              data-simplebar
            >
              <ul
                className="list-unstyled accordion p-2"
                id="users-conversation"
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  height: "100%",
                }}
              >
                {/* TASKDATA SECTION */}

                {TaskData &&
                  TaskData.map((item, i) => {
                    return (
                      <li className="chat-list left accordion-item" key={i}>
                        <h2 className="accordion-header" id={`heading-${i}`}>
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${i}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${i}`}
                            onClick={() => setActiveTab(i)}
                          >
                            <strong>{item.task_name}</strong>
                          </button>
                        </h2>
                        <div
                          id={`collapse-${i}`}
                          className={`accordion-collapse ${
                            i === activeTab ? " collapse show" : "collapse"
                          }  `}
                          aria-labelledby={`heading-${i}`}
                          data-bs-parent="#users-conversation"
                        >
                          <div className="accordion-body">
                            <div className="row mx-1">
                              <div className="col-sm-2">
                                <label>Time Spent</label>
                                <p>{item.total_spent_time}</p>
                              </div>
                              <div className="col-sm-2">
                                <label>Estimate Time</label>
                                <p>
                                  {item.expected_hours}H :{" "}
                                  {("0" + item.expected_minutes).slice(-2)}M
                                </p>
                              </div>
                              <div className="col-sm-3">
                                <label>Created Date</label>
                                <p>{item.created_at}</p>
                              </div>
                              <div className="col-sm-3">
                                <label>Last Update time</label>
                                <p>{item.updated_at}</p>
                              </div>
                            </div>
                            <TaskActivity
                              handleUpdateHours={handleUpdateHours}
                              IsWaiting={IsWaiting}
                              setTaskHours={setTaskHours}
                              setTaskTag={setTaskTag}
                              setReview={setReview}
                              currentTask={item.id}
                              setCurrentTask={setCurrentTask}
                              Review={Review}
                              TaskHours={TaskHours}
                              handleData={projectTaskTwo}
                              TaskMinute={TaskMinute}
                              setTaskMinute={setTaskMinute}
                              Tags={Tags}
                              FromData={FromData}
                              ToData={ToData}
                              logDate={logDate}
                              setLogDate={setLogDate}
                              
                            />
                            {item?.task_logs && (
                              <div className="ctext-wrap-content">
                                <div className="mt-4 mb-4 ctext-content">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Hours</th>
                                        <th>Remark</th>
                                        <th>User</th>
                                        <th>Tag</th>
                                        <th>Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.task_logs?.map((logitem, inn) => {
                                        return (
                                          <tr key={inn}>
                                            <td>
                                              <strong>
                                                {(
                                                  "0" + logitem?.spent_hours
                                                ).slice(-2)}
                                                H:
                                                {(
                                                  "0" + logitem?.spent_minutes
                                                ).slice(-2)}
                                                m
                                              </strong>
                                            </td>
                                            <td
                                              style={{
                                                width: "50%",
                                                padding: "10px",
                                              }}
                                            >
                                              {logitem?.remarks}
                                            </td>
                                            <th>{logitem?.user?.name}</th>
                                            <th>
                                              <select
                                                value={logitem.tag_id}
                                                onChange={(e) =>
                                                  setBugTag(
                                                    e.target.value,
                                                    logitem.id
                                                  )
                                                }
                                              >
                                                <option value="">
                                                  Select Tag
                                                </option>
                                                {Tags?.map((obj, newIndex) => {
                                                  return (
                                                    <option value={obj.uuid}>
                                                      {obj.tag_name}
                                                    </option>
                                                  );
                                                })}
                                              </select>
                                            </th>
                                            <td>{logitem?.created_at}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <TaskMessage
            projectTaskTwo={projectTaskTwo}
            Tags={Tags}
            setTaskData={setTaskData}
            TaskData={TaskData}
          />
        </div>
      </div>
      }
      {deleteBoxShow && <DeleteConfirmation fElement={confirmDelete} />}
                
    </>
  );
}

export default ProjectTask;
