import React, { useEffect,useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import './css/FileUploadPreview.css'
function PostMessage({ handelSubmit, message, setMessage, setFileList,fileList,sending,slug}) {

  /* file upload preview */
  const [editorReady, setEditorReady] = useState(false);
  useEffect(() => {
    setEditorReady(true);
  }, []);
  useEffect(() => {
    const savedDraft =  localStorage.getItem('draftMessage_'+slug);
    if (savedDraft) {
      setMessage(savedDraft);
    }else{
      setMessage('');
    }
  }, [slug]);

  function handleFileSelect(event) {
    const files = Array.from(event.target.files);
    setFileList([...fileList, ...files]);
  }
  const handleRemoveFile = fileToRemove => {
    setFileList(fileList.filter(file => file !== fileToRemove));
  };

  function ImagePreview({ src }) {
    return <a href={src} target="_blank"><img src={src} className="previewContent" /></a>;
  }
  
  function DocumentPreview({ src }) {
    return <a href={src} target="_blank"><iframe src={src} className="previewContent" /></a>;
  }
  function VideoPreview({ src }) {
    return (
      <video className="previewContent" controls>
        <source src={src}  />
      </video>
    );
  }

  function FilePreview({ file, onRemove }) {
    const { type, name } = file;
    const src = URL.createObjectURL(file);
  
    const handleRemove = () => {
      onRemove(file);
    };
  
    if (type.startsWith('image/')) {
      return (
        <div className="file-card">
          <div className="file-preview">
            <ImagePreview src={src} />
          </div>
          <div className="file-info">
            <div>{name}</div>
            <button className="btn btn-light" onClick={handleRemove}>Remove</button>
          </div>
        </div>
      );
    }
  
    if (type === 'application/pdf') {
      return (
        <div className="file-card">
          <div className="file-preview">
            <DocumentPreview src={src} />
          </div>
          <div className="file-info">
            <div>{name}</div>
            <button className="btn btn-light" onClick={handleRemove}>Remove</button>
          </div>
        </div>
      );
    }
  
    if (type.startsWith('video/')) {
      return (
        <div className="file-card">
          <div className="file-preview">
            <VideoPreview src={src} />
          </div>
          <div className="file-info">
            <div>{name}</div>
            <button className="btn btn-light" onClick={handleRemove}>Remove</button>
          </div>
        </div>
      );
    }
  
    return (
      <div className="file-card">
        <div className="file-preview">
          #
        </div>
        <div className="file-info">
          <div>{name}</div>
          <button className="btn btn-light" onClick={handleRemove}>Remove</button>
        </div>
      </div>
    );
  }

  

    const handleEditorChange = (e) => {
      setMessage(e);
      localStorage.setItem('draftMessage_'+slug, message);
      if(e==''){
        localStorage.removeItem('draftMessage_'+slug);
      }
    }
    const submitForm = (e) => {
        if(e.code=='Enter' && e.shiftKey==false){
          submitButtonForm();
          return false;
        }
    }
    const submitButtonForm = () => {
      handelSubmit();
      setMessage('');
      localStorage.removeItem('draftMessage_'+slug);
      return true;
    }
  return (
    <div className="position-relative">
      <form
        id="chatinput-form"
        encType="multipart/form-data"
      >
        <div className="chat-input-section">
          <div className="row g-0 align-items-center">
          
            <div className="col-sm-12">
            <div className="row">
              {fileList?.map(file => (
                 <div className="preview col-sm-2">
                <FilePreview key={file.name} file={file} onRemove={handleRemoveFile} />
                </div>
              ))}
            </div>
            {editorReady ? (
                <Editor
                  //initialValue={message}
                  apiKey=""
                  init={{
                    selector: 'div',
                    height: 120,
                    external_plugins: {'tinymce': `${process.env.REACT_APP_PUBLIC_URL}/assets/tinymce/tinymce.min.js`},
                    toolbar:false,
                    toolbar_location: "bottom",
                    placeholder: "Enter message . . .",
                    default_link_target: "_blank",
                    link_quicklink: true,
                    auto_focus: true,
                    license_key: 'gpl',
                    plugins:"autoresize anchor autolink charmap codesample image link lists media searchreplace table visualblocks wordcount fullscreen",//emoticons
                    menubar: false         
                  }}
                  onEditorChange={ (e) => handleEditorChange(e) }
                  onEditorpaste={ (e) => handleEditorChange(e) }
                  value={message}
                  outputFormat='html'
                  inline={false} 
                  onKeyDown={(e) => submitForm(e)}
                />
            
                ) : (
              <p>Loading Editor...</p>
              )}
            </div>
            <div className="col-sm-12 lastButton">
                {sending ==false ? '':<label className="waves-effect font-size-14 color-primary mb-2">Sending...</label>}  
                <input
                  onChange={handleFileSelect}
                  id="attachedfile-input"
                  type="file"
                  className="d-none"
                  multiple
                />
                <label htmlFor="attachedfile-input" className="waves-effect">
                  <span className="">
                    <i className="bx bx-paperclip"></i>
                  </span>
                </label>
                <label className="waves-effect" onClick={submitButtonForm}>
                  <i className="bx bxs-send align-middle" id="submit-btn"></i>
                </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
export default PostMessage;
