import { height } from "@mui/system";
import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getApiCall, postApiCall, loginDetail } from "../../Service/Api";
import FullScreenLoader from '../../FullScreenLoader';

function OfficeReport({userProjectData}) {
    
    const Today = new Date().toISOString().split("T")[0];
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const Week = `${firstDayOfMonth.getFullYear()}-${String(firstDayOfMonth.getMonth() + 1).padStart(2, '0')}-${String(firstDayOfMonth.getDate()).padStart(2, '0')}`;
    
    //const Week = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split("T")[0];

  const [userData, setUserData] = useState("");
  const [FromData, setFromData] = useState(Week);
  const [ToData, setToData] = useState(Today);
  const [loading, setLoading] = useState(false);
  
  const handleSummeryData = async () => {
    setFromData(Week);
    setToData(Today);
    handleFilterData();
  };

  const handleFilterData = async () => {
    setLoading(true);
    let payload = {
        start_date: FromData,
        end_date: ToData,
        rundev: "rundev",
        giveArray:"giveArray"
    };
    const response = await postApiCall("chat-manager/report-office-status", payload);
    if (response) {
      setLoading(false);
      setUserData(response.data);
    }
  };

  const handleResetData = () => {
    handleSummeryData();
  };

  return (
    <>
    {loading ? <FullScreenLoader /> :
      <>
        <div className="row align-items-center">
            <div className="col-sm-2">
              <div className="mb-4">
                <label className="mb-2">From Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Start Date"
                  onChange={(e) => setFromData(e.target.value)}
                  value={FromData}
                />
              </div>
            </div>
            <div className="col-sm-2">
              <div className="mb-4">
                <label className="mb-2">To Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="End Date"
                  onChange={(e) => setToData(e.target.value)}
                  value={ToData}
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div className="mt-2">
                <input
                  type="button"
                  className="btn btn-primary"
                  value="Filter"
                  onClick={handleFilterData}
                />
                &nbsp;&nbsp;
                <input
                  type="button"
                  className="btn btn-warning"
                  value="Reset Filter"
                  onClick={handleResetData}
                />
              </div>
            </div>
          </div>
          {userData &&
          <>
          <div
            className="row mt-3"
            style={{ overflowY: "auto", height: "70vh" }}
          >
            

            <div className="col-sm-12 mt-2">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Employee In</th>
                    <th>Employee Leave</th>
                    <th>Employee IN Count</th>
                    <th>Office in Time</th>
                    <th>Working Time</th>
                    <th>Break Time</th>
                    <th>Summary Hours</th>
                    <th>Active Time</th>
                    <th>Inactive Time</th>
                  </tr>
                </thead>
                <tbody>
                    {userData?.map((item, index) => (
                      
                        <tr key={index}>
                            <td>{item.target_date}</td>
                            <td>
                                {item.working_users?.map((working, one) => (
                                    <span>{working}, </span>
                                ))}
                            </td>
                            <td>
                                {item.absent_users?.map((absent, one) => (
                                    <span>{absent}, </span>
                                ))}
                            </td>
                            <td>{item.working_users.length}</td>
                            <td>{item.summary.total_office_time}</td>
                            <td>{item.summary.total_active_time}</td>
                            <td>-</td>
                            <td>{item.summary.total_working_time}</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                  ))}
               
                </tbody>
              </table>
            </div>
           
          </div>
          </>
          }
      </>
    }
    </>
  );
}

export default OfficeReport;
