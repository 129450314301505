import React, { useState } from "react";
import PropTypes from "prop-types";
import { getApiCall, postApiCall } from "../Service/Api";
import {Link} from 'react-router-dom';

export default function Register({ setToken }) {
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [employee_id, setEmployeeId] = useState();
  const [otp, setOtp] = useState();
  const [workspace, setworkspace] = useState();
  const [message, setMessage] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let callAPi='register-workspace';
    let payload = {
      email_id: employee_id
    }
    if(activePage>=2){
      callAPi='verify-workspace';
      payload['otp']=otp;
    }
    if(activePage>=3){
      callAPi='create-workspace';
      payload['workspace_name']=workspace;
    }
    const response = await postApiCall(callAPi, payload);
    if (response.status) {
      if(activePage==3){
        setToken(response);
        window.location.href='/';
      }else{
        setActivePage(activePage+1);
      }
      
    }
    setLoading(false);
    return false;
  };
  const ResendOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    let callAPi='register-workspace';
    let payload = {
      email_id: employee_id
    }
    const response = await postApiCall(callAPi, payload);
    setLoading(false);
    return false;
  };
  

  return (
    <div className="auth-bg">
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-xl-3 col-lg-4">
            <div className="p-4 pb-0 p-lg-5 pb-lg-0 auth-logo-section">
              <div className="text-white-50">
                <h3>
                  <a href="#" className="text-white">
                    <i className="bx bxs-message-alt-detail align-middle text-white h3 mb-1 me-2"></i>{" "}
                    TeamTrack
                  </a>
                </h3>
              </div>
              <div className="mt-auto">
                <img
                  src="/assets/images/login-security.svg"
                  alt=""
                  className="auth-img"
                />
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="authentication-page-content">
              <div className="d-flex flex-column h-100 px-4 pt-4">
                <div className="row justify-content-center my-auto">
                  <div className="col-sm-8 col-lg-8 col-xl-8 col-xxl-8">
                    <div className="py-md-5 py-4">
                      <div className="text-center mb-5">
                        <h3>Make Your Workday Easier with TeamTrack</h3>
                        <p className="text-muted">
                            By signing up, I agree to the TeamTrack <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a>.
                        </p>
                      </div>
                      <p>{message}</p>
                      <form onSubmit={handleSubmit}>
                        {
                        activePage>=1 && 
                        <div className="mb-3" >
                          <label htmlFor="username" className="form-label">
                            We suggest using the email address that you use at work.
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                            placeholder="name@work-email.com"
                            onChange={(e) => setEmployeeId(e.target.value)}
                            required
                          />
                          {activePage>1 &&
                            <p className="text-right"><Link onClick={ResendOTP}>Resend OTP</Link></p>
                          }
                        </div>
                        }
                        {
                        activePage>=2 && 
                        <div className="mb-3 hidden">
                          <label htmlFor="userpassword" className="form-label">
                            OTP
                          </label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <input
                              placeholder="OTP"
                              name="password"
                              type={passwordShown ? "text" : "password"}
                              className="form-control pe-5"
                              id="password-input"
                              onChange={(e) => setOtp(e.target.value)}
                              required
                            />
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                            >
                              <i
                                className="ri-eye-fill align-middle"
                                onClick={togglePasswordVisiblity}
                              ></i>
                            </button>
                          </div>
                        </div>
                        }
                        {
                        activePage>=3 && 
                        <div className="mb-3 hidden">
                          <label htmlFor="username" className="form-label">
                            What’s the name of your company or team?
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                            placeholder="eg. Devwings"
                            onChange={(e) => setworkspace(e.target.value)}
                            required
                          />
                          <p>This will be the name of your Slack workspace – choose something that your team will recognise.</p>
                        </div>
                        }
                        <div className="text-center mt-3">
                          <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                          { activePage>=3? <span hidden={loading}>Submit</span>:<span hidden={loading}>Continue</span>}
                          
                          <i className="ri-loader-3-line align-middle" hidden={!loading}></i></button>
                        </div>
                      </form>
                    </div>
                    <div className="text-center"><p>Already have an account? <Link to="/login">Sign In</Link></p></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="text-center text-muted p-4">
                      <p className="mb-0">
                        Copyright <script>
                          document.write(new Date().getFullYear())
                        </script> © <a href="https://www.devwings.com/">Devwings</a> All rights reserved. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Register.propTypes = {
  setToken: PropTypes.func.isRequired,
};
