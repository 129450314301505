import React, { useEffect, useState } from "react";
import { getApiCall, postApiCall } from "../data/Index";
import PinList from "../component/PinList";

function Searchpage() {
  const [bookmarks, setBookmarks] = useState([]);
  const [SearchData, setSearchData] = useState("");
  const [EmptyData, setEmptyData] = useState(false)

  useEffect(() => {
    getDashboardData();
  }, []);

  async function getDashboardData() {
    const r = await getApiCall("get-all-pinned-chats?rundev=rundev");
    setBookmarks(r.data);
  }

  const pinChatElement = async (id, type) => {
    const response = await postApiCall("pin-msg", {
      message_id: id,
      type: type,
    });
    if (response.status) {
      getDashboardData();
    }
  };

  const handleSearch = async (e) => {
    const response = await postApiCall("system-search", {
      keyword: e.target.value,
    });
    if (response.status) {
      // console.log(response.data.data);
      setSearchData(response.data.data);
    }
  };

  return (
    <div className="bg-white user-chat w-100">
      <div className="user-chat-overlay"></div>
      <div className="px-4 pt-4">
        <div className="d-flex align-items-start">
          <div className="flex-grow-1">
            <h4 className="mb-3">Search in system</h4>
          </div>
        </div>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control bg-light border-0 pe-0 radius-0"
            id="serachBookmark"
            placeholder="Search here.."
            aria-label="Example text with button addon"
            aria-describedby="searchbtn-addon"
            autoComplete="off"
            onChange={(e) => handleSearch(e)}
          />
          <button className="btn btn-light" type="button" id="searchbtn-addon">
            <i className="bx bx-search align-middle"></i>
          </button>
        </div>
      </div>
      <div className="chat-message-list chat-bookmark-list" data-simplebar>
        <ul className="list-unstyled chat-conversation">
          {SearchData? (
            <PinList data={SearchData} handleSearch={handleSearch} />
          ) : (
            <div className="container">
              <div className="row mt-3">
                <div className="col-sm-12 text-center p-5">
                  <h2>Search in the system</h2>
                  <p>
                    Search your conversion in an agile way.
                  </p>
                </div>
              </div>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Searchpage;
