/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getApiCall, loginDetail, postApiCall } from "../ChatPortal/data/Index";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPen } from "@fortawesome/free-solid-svg-icons";
function Profile({ dashboard }) {
  const { format } = require("date-fns");
  const today = format(new Date(), "eeee, LLLL d");
  const userToken = loginDetail();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleClosee = () => setShowModal(false);
  const handleShow = () => {
    // setShowModal(true)
  };
  const handleShoww = () => setShow(true);
  const [Image, setImage] = useState("");
  const [Name, setName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [InTime, setInTime] = useState("");
  const [OutTime, setOutTime] = useState("");
  const [About, setAbout] = useState("");
  const [BreakTime, setBreakTime] = useState("");
  const [TeaTime, setTeaTime] = useState("");
  const [OtherBreak, setOtherBreak] = useState("");
  const [Imagedata, setImagedata] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [Contact, setContact] = useState("");
  const [Address, setAddress] = useState("");
  const [Employee_id, setEmployee_id] = useState("");

  useEffect(() => {
    api();
    handleShow();
  }, []);

  const handleShowmodal = () => {
    setShowModal(true);
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const api = async () => {
    const response = await getApiCall("profile?rundev=rundev");
    setImagedata(response.data.profile.path);
    setName(response.data.name);
    setLastName(response.data.last_name);
    setEmail(response.data.email);
    setEmployee_id(response.data.employee_id);
    setAddress(response.data.address);
    setContact(response.data.contact);
    setInTime(response.data.settings.clockInTime);
    setOutTime(response.data.settings.clockOutTime);
    setBreakTime(response.data.settings.breakTime);
    setTeaTime(response.data.settings.teaTime);
    setOtherBreak(response.data.settings.otherBreakTime);
  };

  const UpdateProfile = async () => {
    const formData = new FormData();
    formData.append("email", Email);
    formData.append("first_name", Name);
    formData.append("last_name", LastName);
    formData.append("contact", Contact);
    formData.append("address", Address);
    formData.append("profile", Image[0]);
    const response = await postApiCall("update-profile", formData);
    if (response.status) {
      api();
      setShow(false);
    }
  };

  const UpdateProfileInfo = async () => {
    let data = {
      clockInTime: InTime,
      clockOutTime: OutTime,
      breakTime: BreakTime,
      otherBreakTime: OtherBreak,
      teaTime: TeaTime,
    };
    const response = await postApiCall("user-settings", data);
    if (response.status) {
      api();
      setShowModal(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-input-div">
              <label>Upload Profile Picture:</label>
              <br />
              <input
                type="file"
                onChange={(e) => {
                  setImage(e.target.files);
                }}
                className="form-control profile-inputs"
              />
            </div>
            <div className="form-input-div">
              <label>First Name:</label>
              <br />
              <input
                type="text"
                value={Name || ""}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className="form-control profile-inputs"
              />
            </div>
            <div className="form-input-div">
              <label>Last Name:</label>
              <br />
              <input
                type="text"
                value={LastName || ""}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                className="form-control profile-inputs"
              />
            </div>
            <div className="form-input-div">
              <label>Email:</label>
              <br />
              <input
                type="email"
                value={Email || ""}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="form-control profile-inputs"
              />
            </div>
            <div className="form-input-div">
              <label>Address:</label>
              <br />
              <input
                type="text"
                value={Address || ""}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                className="form-control profile-inputs"
              />
            </div>
            <div className="form-input-div">
              <label>Phone Number:</label>
              <br />
              <input
                type="Text"
                value={Contact || ""}
                onChange={(e) => {
                  setContact(e.target.value);
                }}
                className="form-control profile-inputs"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={UpdateProfile}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={handleClosee}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Informations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-input-div">
              <label>Clock-In Time:</label>
              <br />
              <input
                type="time"
                value={InTime || ""}
                onChange={(e) => {
                  setInTime(e.target.value);
                }}
                className="form-control profile-inputs"
              />
            </div>
            <div className="form-input-div">
              <label>Clock-Out Time:</label>
              <br />
              <input
                type="time"
                value={OutTime || ""}
                onChange={(e) => {
                  setOutTime(e.target.value);
                }}
                className="form-control profile-inputs"
              />
            </div>
            <div className="form-input-div">
              <label>Other Break:</label>
              <br />
              <input
                type="time"
                value={OtherBreak || ""}
                onChange={(e) => {
                  setOtherBreak(e.target.value);
                }}
                className="form-control profile-inputs"
              />
            </div>
            <div className="form-input-div">
              <label>Tea Break:</label>
              <br />
              <input
                type="time"
                value={TeaTime || ""}
                onChange={(e) => {
                  setTeaTime(e.target.value);
                }}
                className="form-control profile-inputs"
              />
            </div>
            <div className="form-input-div">
              <label>Break Time:</label>
              <br />
              <input
                type="time"
                value={BreakTime || ""}
                onChange={(e) => {
                  setBreakTime(e.target.value);
                }}
                className="form-control profile-inputs"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleClosee}>
            Close
          </Button>
          <Button variant="primary" onClick={UpdateProfileInfo}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="w-100">
        <div className="profile-header">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ padding: "7px 25px" }}
          >
            <h1 className="d-flex align-items-center profile-heading">
              <i className=""></i>My Profile
            </h1>
            <div>
              <button className="btn btn-outline-primary" onClick={handleShoww}>Edit Your Profile</button>&nbsp;&nbsp;
              <button  className="btn btn-outline-secondary"  onClick={handleShowmodal}>Edit Your Clockify Detail</button>
            </div>
          </div>
        </div>
        <div style={{ height: "calc(100vh)",overflow:"scroll" }}>
          <div className="p-5 col-12 col-lg-7 mx-auto">
            <div className="row">
              <div className="text-center col-sm-12 mb-4">
                <img
                  src={Imagedata}
                  alt="image"
                  style={{ width: "110px", borderRadius: "10px" }}
                />
                <br />
              </div>
              <div className="col-sm-6 mb-4">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={Name}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-6 mb-4">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={LastName}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-6 mb-4">
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    value={Email}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-6 mb-4">
                <div className="form-group">
                  <label>Employee ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={Employee_id}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-6 mb-4">
                <div className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    className="form-control"
                    value={Address}
                    disabled
                  />
                </div>
              </div>{" "}
              <div className="col-sm-6 mb-4">
                <div className="form-group">
                  <label>Phone number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={Contact}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-6 mb-4">
                <div className="form-group">
                  <label>Clock-In Time</label>
                  <input
                    type="text"
                    className="form-control"
                    value={InTime}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-6 mb-4">
                <div className="form-group">
                  <label>Clock-Out Time</label>
                  <input
                    type="text"
                    className="form-control"
                    value={OutTime}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-6 mb-4">
                <div className="form-group">
                  <label>Other Break</label>
                  <input
                    type="text"
                    className="form-control"
                    value={OtherBreak}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-6 mb-4">
                <div className="form-group">
                  <label>Tea Break</label>
                  <input
                    type="text"
                    className="form-control"
                    value={TeaTime}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-6 mb-4">
                <div className="form-group">
                  <label>Break Time</label>
                  <input
                    type="text"
                    className="form-control"
                    value={BreakTime}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <p className="text-center mt-4 font-weight-bold">
                *Above all the inputs are read only.
              </p>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
