import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import {
  Header,
  ChatFiles,
  ChatMedia,
  PostMessage,
  PinPopup,
  PinAlert,
  ChatList,
  SearchChat,
  ShowAllFiles,
  ShowAllImage,
} from "../component/chat";
import Profile from "../component/chat/Profile";
import DeleteConfirmation from "../component/DeleteConfirmation";
import { getApiCall, postApiCall, loginDetail } from "../data/Index";
import InfiniteScroll from "react-infinite-scroll-component";
import FullScreenLoader from '../../FullScreenLoader';
function MembersChat({ dashboard, personalChat }) {
  // console.log(personalChat.data.muted_by);
  let [loader, setLoader] = useState(true);
  let [sending, setSending] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const { id } = useParams();
  const { slug } = useParams();
  let [page, setPage] = useState(1);
  let [employeeId, setEmployeeId] = useState(slug);
  const [chat, setChat] = useState([]);
  const [user, setUser] = useState([]);
  const [MuteData, setMuteData] = useState("");
  const [files, setFiles] = useState([]);
  const [media, setMedia] = useState([]);
  const [common_groups, setCommon_groups] = useState([]);
  const [pinnedMessageCount, setPinnedMessageCount] = useState(0);
  const [pinnedMessage, setPinnedMessage] = useState([]);
  const [message, setMessage] = useState("");
  const [editmessage, setEditmessage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [allFileList, setAllFileList] = useState([]);
  const [allImageList, setAllImageList] = useState([]);
  const chatSection = useRef(null);
  const [fireBottom, setFireBottom] = useState(1);
  // delete settelment
  const [deleteId, setDeleteId] = useState("");
  const [deleteRowIndex, setDeleteRowIndex] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [deleteBoxShow, setDeleteBoxShow] = useState(true);
  const [MessageId, setMessageId] = useState("");
  const [ID, setID] = useState("");
  const [editIndex, setEditIndex] = useState("");
  const [Seen, setSeen] = useState("");
  const [SeeAll, setSeeAll] = useState(false);
  const navigate = useNavigate();
  const [ShowAllChatFiles, setShowAllChatFiles] = useState(false);
  const [Beep, setBeep] = useState(false);
  useEffect(() => {
    if (typeof personalChat != "undefined") {
      pusherChatChange(personalChat);
    }
  }, [personalChat]);
  useEffect(() => {
    getChat();
    fetchPinData();
    //scrollToBottom();
    // handleSeenMessages();
  }, [id]);

  useEffect(() => {
    setEmployeeId(slug);
  }, [slug]);

  async function getChat() {
    setLoader(true);
    const response = await getApiCall(
      "chat-history?rundev=rundev&chat_partner=" + id
    );
    if (response.status) {
      setMessageId(response.data.user.id);
      setChat(response.data.chats.data);
      setUser(response.data.user);
      setMuteData(response.data.is_muted);
      setCommon_groups(response.data.common_groups);
      setFiles(response.data.files);
      setMedia(response.data.media);
      setLoader(false);
    } else {
      setLoader(false);
      navigate("/chat");
    }
  }

  async function fetchPinData() {
    const response = await getApiCall(
      "get-pinned-chats?rundev=rundev&chat_partner=" + id + "&type=1"
    );
    setPinnedMessageCount(response.data.length);
    setPinnedMessage(response.data);
  }

  const pusherChatChange = (response) => {
    if (typeof response.type != "undefined") {
      if (response.type == 1) {
        const newFruits = chat?.filter(
          (fruit) => fruit.id === response.data.id
        );
        if (newFruits.length > 0) {
          return;
        }
        if (response.data.user.employee_id == employeeId) {
          setChat((oldArray) => [response.data, ...oldArray]);
          //scrollToBottom();
        }
      } else if (response.type == 3) {
        if (response.data.user?.employee_id == employeeId) {
          let index = chat.findIndex(
            (x) => x.chat_token == response.data.chat_token
          );
          const newFruits = chat;
          newFruits[index].message = response.data.message;
          setChat(newFruits);
        }
      } else if (response.type == 4) {
        const newFruits = chat?.filter(
          (fruit) => fruit.chat_token !== response.data.chat_token
        );
        setChat(newFruits);
      }
    }
  };

  const handelSubmit = async (e) => {
    setSending(true);
    const formData = new FormData();
    formData.append("receiver_user_id", id);
    formData.append("message", message);
    Object.keys(fileList).map(function (key) {
      formData.append("uploads[]", fileList[key]);
    });
    setMessage("");
    setID("");
    setFileList([]);
    const response = await postApiCall("chat", formData);
    if (response.status) {
      setChat((oldArray) => [response.data, ...oldArray]);
      if (response.data.documents?.chat_files.length > 0) {
        setFiles((oldArray) => [
          ...response.data.documents?.chat_files,
          ...oldArray,
        ]);
      }
      if (response.data.documents?.chat_media.length > 0) {
        setMedia((oldArray) => [
          ...response.data.documents?.chat_media,
          ...oldArray,
        ]);
      }
    }
    scrollToBottom();
    dashboard();
    setSending(false);
  };

  const EditChat = async (id, index) => {
    setID(id);
    setEditIndex(index);
  };
  const handelUpdate = async (e) => {
    const response = await postApiCall("edit-chat", {
      message: editmessage,
      type: "1",
      chat_id: ID,
    });
    if (response.status) {
      let newChat = chat;
      newChat[editIndex].message = editmessage;
      setChat(newChat);

      setEditmessage("");
      setID("");
    }
    return;
  };
  const handleCancleMessage = () => {
    setEditmessage("");
    setID("");
  };

  const removeChatElement = async (id, index) => {
    const response = await postApiCall("delete-chat", { chat_id: id, type: 1 });
    if (response.status) {
      const newFruits = chat?.filter((fruit) => fruit.id !== id);
      setChat(newFruits);
      fetchPinData();
    }
  };

  const pinChatElement = async (id, index) => {
    const response = await postApiCall("pin-msg", { message_id: id, type: 1 });
    if (response.status) {
      let newChat = chat;
      if (newChat[index]?.is_pinned == 1) {
        newChat[index].is_pinned = 0;
      } else {
        newChat[index].is_pinned = 1;
      }
      setChat(newChat);
      fetchPinData();
    }
  };

  const handleSeenMessages = async () => {
    const response = await postApiCall("mark-read", {
      co_user_id: id,
      type: "1",
    });
    // console.log(response);
    if (response.status) {
      setSeen(response);
    }
  };

  const markFavoriteElement = async (yyyid, index) => {
    const response = await postApiCall("mark-favorite", {
      chat_partner: id,
      type: 1,
    });
    if (response.status) {
      let newState = { ...user };
      if (user?.is_favorite == 1) {
        newState.is_favorite = 0;
      } else {
        newState.is_favorite = 1;
      }
      setUser(newState);
      dashboard();
    }
  };

  const handleScroll = async (event) => {
    //if(event.currentTarget.scrollTop==0){
    const nextpage = page + 1;
    const response = await getApiCall(
      "chat-history?rundev=rundev&chat_partner=" + id + "&page=" + nextpage
    );
    if (response.data.chats.data.length > 0) {
      setChat((oldArray) => [...oldArray, ...response.data.chats.data]);
      //setChat(oldArray => [...response.data.chats.data,...oldArray]);
      setPage(nextpage);
    }
    //}
  };

  const callFiles = async () => {
    setShowAllChatFiles(true);
    if (ShowAllChatFiles) {
      setShowAllChatFiles(false);
    }
    const response = await getApiCall(
      "get-media?rundev=rundev&chat_partner=" + id + "&type=1"
    );
    setAllFileList(response.files);
    setAllImageList(response.media);
  };
  const deleteFiles = async (path) => {
    //const response = await postApiCall('remove-files',{'chat_partner': id,'type': 1,'file_path':path});
  };

  function showDeletePopup(type, id, index) {
    setDeleteId(id);
    setDeleteType(type);
    //setDeleteBoxShow(true);
    setDeleteRowIndex(index);
    window["showModal"]();
  }
  function confirmDelete() {
    if (deleteType == "removeChatElement") {
      removeChatElement(deleteId, deleteRowIndex);
    }
    window["closeModal"]();
    //setDeleteBoxShow(false);
    setDeleteId("");
    setDeleteType("");
  }
  useEffect(() => {
    chatSection.current?.scrollIntoView({ behavior: "smooth" });
  }, [fireBottom]);

  const scrollToBottom = () => {
    setFireBottom(fireBottom + 1);
  };

  // drag and drop
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setFileList(files);
  };

  const commonGroup = common_groups?.map((group, index) =>
    SeeAll ? (
      <li key={group.id}>
        <Link to={{ pathname: "/chat/g/" + group.slug + "/" + group.id }}>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 avatar-xs me-2">
              <span className="avatar-title rounded-circle bg-soft-light text-dark">
                {group.type == "1" ? <i className="bx bx-lock"></i> : "#"}
              </span>
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <p className="text-truncate mb-0">{group.name}</p>
            </div>
          </div>
        </Link>
      </li>
    ) : index <= 4 ? (
      <li key={group.id}>
        <Link to={{ pathname: "/chat/g/" + group.slug + "/" + group.id }}>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 avatar-xs me-2">
              <span className="avatar-title rounded-circle bg-soft-light text-dark">
                {group.type == "1" ? <i className="bx bx-lock"></i> : "#"}
              </span>
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <p className="text-truncate mb-0">{group.name}</p>
            </div>
          </div>
        </Link>
      </li>
    ) : (
      ""
    )
  );

  const handleShowFiles = () => {
    setSeeAll(true);
    if (SeeAll) {
      setSeeAll(false);
    }
  };

  const handleMuteUser = async (id) => {
    let payload = {
      chatter_id: id,
      type: 1,
    };
    try {
      const response = await postApiCall("toggle-mute", payload);
      //console.log('response',response)
      if (response.status) {
        getChat();
      }
    } catch (error) {
      // console.log(error);
    }
  };

  personalChat?.data?.muted_by?.array?.forEach((item, index) => {
    if (item == user.employee_id) {
      setBeep(true);
      if (Beep) {
        setBeep(false);
      }
    }
  });

  return (
    <>
      {loader == true ? (
        <FullScreenLoader />
      ) : (
        <div className="bg-white user-chat w-100 overflow-hidden">
          <div className="user-chat-overlay"></div>
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <div id="users-chat" className="position-relative">
                <div
                  className="user-chat-topbar"
                  style={{
                    paddingTop: "15px",
                    paddingBottom: pinnedMessageCount ? "0px" : "10px",
                  }}
                >
                  <Header
                    name={user?.name}
                    profile={user?.profile?.path}
                    other={user?.email}
                    activity_status={user?.activity_status}
                  />
                  <PinAlert pinnedMessageCount={pinnedMessageCount} />
                </div>
                <div
                  className=" bg-white chat-conversation"
                  id="chat-conversation"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <i
                    className="bx bx-caret-down-circle text-muted ms-2"
                    onClick={scrollToBottom}
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      right: "40px",
                      fontSize: "20px",
                      borderRadius: "100%",
                      cursor: "pointer",
                    }}
                  ></i>
                  <ul
                    className="list-unstyled chat-conversation-list"
                    id="users-conversation"
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                      overflow: "auto",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={chat.length}
                      next={handleScroll}
                      /*endMessage={
                      <p style={{ textAlign: 'center' }}>
                          <b>Yay! You have seen it all</b>
                      </p>
                  }*/
                      hasMore={true}
                      //loader={<h5 className="text-center">Loading...</h5>}
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                      }} //To put endMessage and loader to the top.
                      inverse={true}
                      scrollableTarget="users-conversation"
                    >
                      <ChatList
                        dashboard={dashboard}
                        EditChat={EditChat}
                        chats={chat}
                        pinChatElement={pinChatElement}
                        removeChatElement={showDeletePopup}
                        editChatID={ID}
                        handelSubmit={handelUpdate}
                        message={editmessage}
                        setMessage={setEditmessage}
                        handleCancleMessage={handleCancleMessage}
                        Seen={Seen}
                        chatType={1}
                      />
                    </InfiniteScroll>
                  </ul>
                  <div ref={chatSection}></div>
                </div>
              </div>
              <PostMessage
                handelSubmit={handelSubmit}
                message={message}
                setMessage={setMessage}
                setFileList={setFileList}
                fileList={fileList}
                sending={sending}
                slug={slug}
              />
            </div>
            <div className="user-profile-sidebar">
              <div className="border-bottom">
                <Profile data={user} settings={user?.settings} type="other" />
              </div>
              <div className="p-4 user-profile-desc">
                <div className="text-center">
                  <div className="row">
                    <div className="col-sm col-4 d-flex gap-5">
                      <div>
                        <button
                          type="button"
                          className="btn avatar-sm p-0 favourite-btn"
                          onClick={() => markFavoriteElement(user?.id)}
                        >
                          <span className="avatar-title rounded bg-light text-body">
                            {user?.is_favorite === 1 ? (
                              <i
                                className="bx bx-heart"
                                style={{ color: "red" }}
                              ></i>
                            ) : (
                              <i className="bx bx-heart"></i>
                            )}
                          </span>
                        </button>
                        <h5 className="font-size-11 text-uppercase text-muted mt-2">
                          {user?.is_favorite === 1
                            ? "Remove Favorite"
                            : "Mark As Favorite"}
                        </h5>
                      </div>
                      {/*<div>
                        <button
                          type="button"
                          className="btn avatar-sm p-0 favourite-btn"
                          onClick={() => handleMuteUser(user?.id)}
                        >
                          <span className="avatar-title rounded bg-light text-body">
                            {personalChat?.data?.muted_by ? (
                              <i className="bx bx-volume"></i>
                            ) : (
                              <i className="bx bx-volume"></i>
                            )}
                          </span>
                        </button>
                        <h5 className="font-size-11 text-uppercase text-muted mt-2">
                          {MuteData ? "Unmute User" : "Mute User"}
                        </h5>
                      </div>*/}
                    </div>
                  </div>
                </div>
                {common_groups?.length > 0 && (
                  <div className="pt-4 border-top">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h5 className="font-size-11 text-muted text-uppercase d-flex justify-content-between align-items-center">
                          Group in common
                          <a
                            href="#"
                            className="font-size-11"
                            data-bs-toggle="modal"
                            // data-bs-target=".allImageModal"
                            onClick={() => handleShowFiles()}
                          >
                            {SeeAll ? "Show less" : "Show all"}
                          </a>
                        </h5>
                      </div>
                    </div>
                    <ul className="list-unstyled chat-list mx-n4">
                      {commonGroup}
                    </ul>
                  </div>
                )}
                {files?.length > 0 && (
                  <>
                    <hr className="my-4" />
                    <div>
                      <div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h5 className="font-size-11 text-muted text-uppercase d-flex justify-content-between align-items-center">
                              Attached Files
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target=".allFilesModal"
                                className="font-size-11 d-block mb-2 text-uppercase"
                                onClick={() => callFiles()}
                              >
                                Show all
                              </a>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <ChatFiles
                        data={files}
                        deleteFiles={deleteFiles}
                        ShowAllChatFiles={false}
                      />
                    </div>
                  </>
                )}
                {media?.length > 0 && (
                  <>
                    <hr className="my-4" />
                    <div className="privateMedia">
                      <div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h5 className="font-size-11 text-muted text-uppercase d-flex justify-content-between align-items-center">
                              Media
                              <a
                              href="#"
                              className="font-size-11 d-block mb-2 text-uppercase"
                              data-bs-toggle="modal"
                              data-bs-target=".allImageModal"
                              onClick={() => callFiles()}
                            >
                              Show all
                            </a>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <ChatMedia data={media} />
                    </div>
                  </>
                )}
                
              </div>
            </div>
            <PinPopup
              data={pinnedMessage}
              pinnedMessageCount={pinnedMessageCount}
              pinChatElement={pinChatElement}
            />
            <SearchChat
              id={id}
              pinChatElement={pinChatElement}
              removeChatElement={showDeletePopup}
              type={1}
            />
            <ShowAllFiles data={allFileList} deleteFiles={deleteFiles} />
            <ShowAllImage data={allImageList} deleteFiles={deleteFiles} />
            {deleteBoxShow && <DeleteConfirmation fElement={confirmDelete} />}
          </div>
        </div>
      )}
    </>
  );
}

export default MembersChat;
