/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { getApiCall, postApiCall, deleteApiCall } from "../../Service/Api";
import { FaTrashAlt, FaCheck, FaEdit, FaSave, FaTimes } from "react-icons/fa";
import FullScreenLoader from '../../FullScreenLoader';
const Feedback = () => {
  const [sections, setSections] = useState([]);
  const [listIndex, setListIndex] = useState("");
  const [newItemText, setNewItemText] = useState("");
  const [newItemTime, setNewItemTime] = useState("");
  const [editItemText, setEditItemText] = useState("");
  const [editItemTime, setEditItemTime] = useState("");
  const [loading, setLoading] = useState(true);
  const Today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    api();
  }, []);

  const api = async () => {
    setLoading(true);
    const response = await getApiCall("task-manager/suggestions?rundev=rundev");
    if (response.status) {
      setSections([
        {
          id: 1,
          title: "Development / UI",
          items: response.data,
        },
      ]);
      setLoading(false);
    }
  };

  const handleAddNewItem = async (sectionId) => {
    let payload = {
      suggestion: newItemText,
    };

    const response = await postApiCall(
      "task-manager/save-suggestions",
      payload
    );
    if (response.status) {
      api();
    }
    setNewItemText("");
  };

  const handleRemoveItem = async (sectionId, itemId) => {
    const response = await deleteApiCall("task-manager/delete-todo/" + itemId);
    if (response.status) {
      api();
    }
  };

  const handleMarkAsDone = async (sectionId, itemId) => {
    // console.log(itemId);
    // return;
    const response = await postApiCall(
      "task-manager/done-suggestion/" + itemId
    );
    if (response.status) {
      api();
    }
  };

  const handleEditItem = (sectionId, itemId, newListIndex) => {
    const updatedTodos = [...sections];
    updatedTodos[0].items[0].todo[0].editing = 0;
    if (listIndex != "") {
      updatedTodos[0].items[0].todo[listIndex].editing = 0;
    }
    setListIndex(newListIndex);
    updatedTodos[0].items[0].todo[newListIndex].editing = 1;
    setEditItemText(updatedTodos[0].items[0].todo[newListIndex].task_name);
    setEditItemTime(updatedTodos[0].items[0].todo[newListIndex].expected_hours);
    setSections(updatedTodos);
  };

  const handleSaveItem = async (sectionId, itemId) => {
    let payload = {
      task_name: editItemText,
      expected_hours: editItemTime,
    };
    const response = await postApiCall(
      "task-manager/manage-todos/" + itemId,
      payload
    );
    if (response.status) {
      api();
    }
  };

  const handleCancelEdit = (sectionId, itemId) => {
    const updatedTodos = [...sections];
    updatedTodos[0].items[0].todo[listIndex].editing = 0;
    setSections(updatedTodos);
  };

  const handleKeyPress = (event, sectionId) => {
    if (event.key === "Enter") {
      handleAddNewItem(sectionId);
    }
  };

  return (
    <>
      {loading ? <FullScreenLoader /> :
      <div className="bg-white  w-100 overflow-hidden position-relative">
        <div className="employe-report-header">
          <h4>Suggestion</h4>
        </div>
        <div
          className="chat-conversation"
          style={{ "overflow-y": "hidden", height: "83vh" }}
        >
          <div className="mt-0">
            <div className="accordion" id="accordionTodo">
              {sections.map((section, index) => (
                <div className="p-4">
                 
                  <ul
                    className="list-group list-group-flush custom-scroll-bar p-2"
                    style={{ "overflow-y": "scroll", height: "78vh" }}
                  >
                    {section?.items?.map((item, index) => (
                      <li
                        key={item.id}
                        className="mb-2 list-group-item feedback-group-items"
                      >
                        {item.editing ? (
                          <>
                            <div className="row align-items-center">
                              <div className="col-sm-8">
                                <input
                                  type="text"
                                  className="form-control d-inline-block"
                                  value={editItemText}
                                  onChange={(e) =>
                                    setEditItemText(e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-sm-4">
                                <input
                                  type="text"
                                  className="form-control d-inline-block"
                                  value={editItemTime}
                                  onChange={(e) =>
                                    setEditItemTime(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <button
                              className="btn btn-outline-primary mt-2"
                              onClick={() =>
                                handleSaveItem(section.id, item.id)
                              }
                            >
                              Save
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-outline-danger mt-2"
                              onClick={() =>
                                handleCancelEdit(section.id, item.id)
                              }
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <div className="row align-items-center">
                            <div
                              style={{
                                textDecoration: item.done
                                  ? "line-through"
                                  : "none",
                              }}
                              className="col-sm-11"
                            >
                              <p className="mb-0">{item.suggestion}</p>
                              <span>
                                {" "}
                                {item.user_name} - {item.created_at}{" "}
                                {item.done && item.updated_user != ""
                                  ? " - Updated by (" +
                                    item.updated_user +
                                    " - " +
                                    item.updated_time +
                                    ")"
                                  : ""}
                              </span>
                            </div>
                            <div className="col-sm-1">
                              <a
                                className="btn-sm m-l-2"
                                onClick={() =>
                                  handleMarkAsDone(section.id, item.id)
                                }
                                href="#!"
                              >
                                <FaCheck />
                              </a>
                            </div>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="px-4 mt-4 bottomFixed"  style={{"left":"6%"}}>
            <div className="input-group mb-1 gap-3">
                <input
                type="text"
                style={{"width":"60%"}}
                className="form-control w-80"
                placeholder="Enter new suggestion"
                value={newItemText}
                onChange={(e) => setNewItemText(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e, sections[0].id)}
                
                />
                <div className="input-group-append">
                    <button
                        className="btn btn-primary"
                        onClick={() => handleAddNewItem(sections[0].id)}
                    >
                        Add New
                    </button>
                </div>  
            </div>
        </div>
      </div>
      }
    </>
  );
};

export default Feedback;
