import React, { useEffect, useState } from "react";
import { getApiCall, postApiCall } from "../data/Index";
import InfiniteScroll from "react-infinite-scroll-component";
import FullScreenLoader from '../../FullScreenLoader';

function Files({ dashboard }) {

  const Today = new Date().toISOString().split("T")[0];
  const week = new Date();
  week.setDate(week.getDate() - 7);
  let Week = week.toISOString().split("T")[0];
  
  let [totalPage, setTotalPage] = useState(1);
  let [page, setPage] = useState(1);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [FromData, setFromData] = useState(Week);
  const [ToData, setToData] = useState(Today);

  useEffect(() => {
    getFiles();
  }, []);

  async function getFiles() {
    setLoading(true);
    const r = await getApiCall("logged-user-media?rundev=rundev&page=" + page+"&start_date="+Week+"&end_date="+Today);
    setFiles(r.data.data);
    setTotalPage(r.data.pagination.total_page);
    setLoading(false);
  }

  const handleScroll = async (event) => {
    const nextpage = page + 1;
    const response = await getApiCall(
      "logged-user-media?rundev=rundev&page=" + nextpage+"&start_date="+FromData+"&end_date="+ToData
    );
    if (response.data.data.length > 0) {
      setFiles((oldArray) => [...oldArray, ...response.data.data]);
      setPage(nextpage);
    }
  };

  

  const handleFilterData = async () => {
    setLoading(true);
    setPage(1);
    const response = await getApiCall(
      "logged-user-media?rundev=rundev&page=1&start_date="+FromData+"&end_date="+ToData
    );
    if (response.status) {
      setLoading(false);
      setFiles(response.data.data);
      setTotalPage(response.data.pagination.total_page);
    }else{
      setLoading(false);
    }
  };

  const handleResetData = () => {
    getFiles();
    setFromData(Week);
    setToData(Today);
  };
  return (
    <>
    {loading ? <FullScreenLoader /> :
      <>
      <div className="bg-white user-chat w-100 overflow-hidden">
        <div className="user-chat-overlay"></div>
        <div className="employe-report-header">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1 d-flex align-items-center justify-content-between">
              <h4 className="mb-0">Files</h4>
              <ul className="list-inline user-chat-nav text-end mb-0">
                <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                  <button
                    type="button"
                    className="btn nav-btn user-profile-show-1"
                    style={{ zIndex: "9", position: "relative" }}
                  >
                    <i className="bx bx-search"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/*<div className="input-group mb-3">
            <input
              type="text"
              className="form-control bg-light border-0 pe-0 radius-0"
              id="serachFiles"
              placeholder="Search here.."
              aria-label="Example text with button addon"
              aria-describedby="searchbtn-addon"
              autoComplete="off"
            />
            <button
              className="btn btn-light"
              type="button"
              id="searchbtn-addon"
            >
              <i className="bx bx-search align-middle"></i>
            </button>
          </div>*/}
        </div>
        <div
          className="px-4 pt-3 chat-room-list card-body-scroll overflow-scroll"
          id="users-conversation"
        >
          <div className="file-list">
            {files?.length !== 0 ? (
              <InfiniteScroll
                dataLength={totalPage*20}
                next={handleScroll}
                hasMore={true}
                scrollableTarget="users-conversation"
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                <div className="row">
                {files?.length > 0 &&
                  files?.map((file, index) => (
                    <div key={index} className="col-sm-4">
                        <div className="card">
                          <div className="card-header">
                              <span className="file-name">
                                {file.original_name}
                              </span>
                          </div>
                          <div className="card-body shadow text-center">
                            <a href={file.path} target="_blank">
                              {file.type == "media" ? (
                                <img
                                  src={file.path}
                                  alt="media img"
                                  className="img-fluid"
                                  style={{
                                    height: "150px",
                                    objectFit: "contain",
                                  }}
                                />
                              ) : file.extension == "zip" ? (
                                <img
                                  src="../../../assets/images/zip.png"
                                  alt="media img"
                                  className="img-fluid"
                                  style={{
                                    height: "150px",
                                    objectFit: "contain",
                                  }}
                                />
                              ) : file.extension == "pdf" ? (
                                <img
                                  src="../../../assets/images/pdf.png"
                                  alt="media img"
                                  className="img-fluid"
                                  style={{
                                    height: "150px",
                                    objectFit: "contain",
                                  }}
                                />
                              ) : (
                                <img
                                  src="../../../assets/images/none.png"
                                  alt="media img"
                                  className="img-fluid"
                                  style={{
                                    height: "150px",
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                              </a>
                          </div>
                          <div className="card-footer">
                              <span className="file-name">
                                {file.user.name} 
                                <a href={file.path} target="_blank" download={true} style={{float:'right',fontSize:'28px'}}><i className="bx bx-download"></i></a>
                                <br />
                                {file.time}
                              </span>
                          </div>
                        </div>
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <div className="container">
                <div className="row mt-3">
                  <div className="col-sm-12 text-center p-5">
                    <h2>Files in the system</h2>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="user-profile-sidebar" id="user-profile-sidebar">
        <div className="p-4 user-profile-desc" data-simplebar>
          <div className="text-center"><button type="button" className="btn btn-light user-profile-show d-none d-lg-block"><i className="bx bx-x"></i></button></div>
          <h4 className="mt-3">Filter</h4>
          <hr className="mb-4" />
          <div className="row">
            <div className="col-sm-12">
              <div className="mb-4">
                <label>From Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Start Date"
                  onChange={(e) => setFromData(e.target.value)}
                  max={Today}
                  value={FromData}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="mb-4">
                <label>To Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="End Date"
                  onChange={(e) => setToData(e.target.value)}
                  max={Today}
                  value={ToData}
                />
              </div>
            </div>
            <div className="d-flex gap-2">
              <div className="">
                <div className="mb-4">
                  <input
                    type="button"
                    className="btn btn-primary"
                    value="Filter"
                    onClick={handleFilterData}
                  />
                </div>
              </div>
              <div className="">
                <div className="mb-4">
                  <input
                    type="button"
                    className="btn btn-warning"
                    value="Reset Filter"
                    onClick={handleResetData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    }
    </>
  );
}

export default Files;
