import React, { useEffect, useState } from "react";
import { getApiCall } from "./data/Index";
import LeftSidebar from "./component/LeftSidebar";
import { Outlet, useLocation } from 'react-router-dom';

function Chat({dashboard, chatList}) {
    const [favoriteContacts, setFavoriteContacts] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [groups, setGroups] = useState([]);
    const [favoriteGroups, setFavoriteGroups] = useState([]);
    
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    useEffect(() => {
        setFavoriteContacts(chatList?.favorite_chats);
        setContacts(chatList?.chats);
        setGroups(chatList?.groups);
        setFavoriteGroups(chatList?.favorite_groups);
    }, []);
    useEffect(() => {
        getDashboardData();
    }, [dashboard]);
      
    async function getDashboardData() {
        const r = await getApiCall("dashboard?rundev=rundev");
        setFavoriteContacts(r.data.favorite_contacts);
        setContacts(r.data.contacts);
        setGroups(r.data.groups);
        setFavoriteGroups(r.data.favorite_groups);
    }

    return (
    <>
        <LeftSidebar contacts={contacts} favoriteContacts={favoriteContacts} groups={groups} favoriteGroups={favoriteGroups} />
        <Outlet />
        {typeof splitLocation[2]=='undefined' &&
            <div className="container-fluid bg-white">
                <div className="row mt-3">
                    <div className="col-sm-12 text-center p-5">
                        <h1>Connect, Grow and Inspire.</h1>
                        <p>Manage your conversion in an agile way.<br /> Using your digital channel in one place.</p>
                        
                    </div>
                </div>
            </div>
        }
    </>
    );
}

export default Chat;