import { height } from "@mui/system";
import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getApiCall, postApiCall, loginDetail } from "../data/Api";
import FullScreenLoader from '../../FullScreenLoader';
import ReportInOut from './ReportInOut';
import ProjectReport from './ProjectReport';
import ReportHR from './ReportHR';
import ReportHours from './ReportHours';
import ScreenReport from './ScreenReport';
import OfficeReport from './OfficeReport';
import ReportProjectEmployeeHours from './ReportProjectEmployeeHours';
function Employeereport() {
  const Today = new Date().toISOString().split("T")[0];
  const week = new Date();
  week.setDate(week.getDate() - 7);
  let Week = week.toISOString().split("T")[0];

  let [tab, setTab] = useState("inout");
  const [userData, setUserData] = useState("");
  const [FromData, setFromData] = useState(Week);
  const [ToData, setToData] = useState(Today);
  const [ProjectFilter, setProjectFilter] = useState("");
  const [userProjectData, setUserProjectData] = useState("");
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(loginDetail());
  const [reportTitle, setReportTitle] = useState("In / Out"); 
  useEffect(() => {
    getProject();
  }, []);

  async function getProject() {
    const response = await getApiCall("get-all-juniors?rundev=rundev");
    if (response.status) {
      setUserProjectData(response.data);
      if(profile?.user?.is_admin==true){
        setFromData(Today);
        setToData(Today);
        handleFilterData();
      }
    }
  }

  const handleSummeryData = async () => {
    //setUserData("");
    setProjectFilter("");
    setFromData(Week);
    setToData(Today);
  };

  const handleFilterData = async () => {
    setLoading(true);
    let payload = {
      from_date: FromData,
      to_date: ToData,
      user_id: ProjectFilter,
      rundev: "rundev",
    };
    const response = await postApiCall("get-daily-work-summary", payload);
    if (response) {
      setLoading(false);
      setUserData(response.data);
    }
  };

  const handleResetData = () => {
    handleSummeryData();
  };

  const openTab = (type,title) => {
    setTab(type);
    setReportTitle(title);
  };

  return (
    <div className="bg-white  user-chat w-100">
        <div className="user-chat-overlay"></div>{" "}
        <div className="employe-report-header mb-4">
          <div className="row">
            <div className="col-sm-4">
              <h4>{reportTitle} Report</h4>
            </div>
            <div className="col-sm-8" style={{ textAlign: "right" }}>
              <ul className="list-inline user-chat-nav mb-0" id="headerSubMenu">
                <li className="list-inline-item" onClick={() => openTab("inout","In / Out")}>
                  <Link to="#" className={tab == "inout" ? "active" : ""}>In / Out</Link>
                </li>
                <li className="list-inline-item" onClick={() => openTab("projects","Projects")}>
                  <Link to="#" className={tab == "projects" ? "active" : ""}>Projects</Link>
                </li>
                <li className="list-inline-item" onClick={() => openTab("hours","Employee Hours")}>
                  <Link to="#" className={tab == "hours" ? "active" : ""}>Employee Hours</Link>
                </li>
                <li className="list-inline-item" onClick={() => openTab("project_hours","Employee Project Hours")}>
                  <Link to="#" className={tab == "project_hours" ? "active" : ""}>Employee Project hours</Link>
                </li>
                <li className="list-inline-item" onClick={() => openTab("office","Office")}>
                  <Link to="#" className={tab == "office" ? "active" : ""}>Office</Link>
                </li>
                
                <li className="list-inline-item" onClick={() => openTab("HR","HR")}>
                  <Link to="#" className={tab == "HR" ? "active" : ""}>HR</Link>
                </li>
                {profile?.user?.is_admin && (
                <li className="list-inline-item" onClick={() => openTab("Screen","Screen")}>
                  <Link to="#" className={tab == "Screen" ? "active" : ""}>Screen</Link>
                </li>
                )}
                {profile?.user?.employee_id=='krishna' && (
                <li className="list-inline-item" onClick={() => openTab("Screen","Screen")}>
                  <Link to="#" className={tab == "Screen" ? "active" : ""}>Screen</Link>
                </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="px-4 user-profile-desc" data-simplebar>
          <div>
            {tab == "inout" && <ReportInOut userProjectData={userProjectData}/>}
          </div>
          <div>
            {tab == "projects" && <ProjectReport userProjectData={userProjectData}/>}
          </div>
          <div>
            {tab == "hours" && <ReportHours userProjectData={userProjectData}/>}
          </div>
          <div>
            {tab == "project_hours" && <ReportProjectEmployeeHours userProjectData={userProjectData}/>}
          </div>
          <div>
            {tab == "office" && <OfficeReport userProjectData={userProjectData}/>}
          </div>
          <div>
            {tab == "HR" && <ReportHR userProjectData={userProjectData}/>}
          </div>
          <div>
            {tab == "Screen" && <ScreenReport userProjectData={userProjectData}/>}
          </div>
          
        </div>
    </div>
  );
}

export default Employeereport;
