import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Header,
  ChatFiles,
  ChatMedia,
  PostMessage,
  PinPopup,
  PinAlert,
  ChatList,
  SearchChat,
  ShowAllFiles,
  ShowAllImage,
} from "../component/chat";
import Overview from "./Overview";
import Checklist from "./Checklist";
import Timeline from "./Timeline";

import ProjectTask from "../../Task/pages/ProjectTask";
import Report from "../../Summary/pages/Report";

import { getApiCall, postApiCall, loginDetail } from "../data/Index";
import DeleteConfirmation from "../component/DeleteConfirmation";
import InfiniteScroll from "react-infinite-scroll-component";
import FullScreenLoader from '../../FullScreenLoader';
function GroupChat({ dashboard, otherChat, pageType = "All" }) {
  let [tab, setTab] = useState("Chat");
  let [loader, setLoader] = useState(true);
  let [sending, setSending] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { slug } = useParams();
  const userTokenLogin = loginDetail();
  let [pageSlug, setPageSlug] = useState("");
  let [page, setPage] = useState(1);
  const [chat, setChat] = useState([]);
  const [group, setGroup] = useState([]);
  const [groupMember, setGroupMember] = useState([]);
  const [files, setFiles] = useState([]);
  const [media, setMedia] = useState([]);
  const [groupadmin, setGroupadmin] = useState([]);
  let [mode, setMode] = useState("");
  const [pinnedMessageCount, setPinnedMessageCount] = useState(0);
  const [pinnedMessage, setPinnedMessage] = useState([]);
  const [message, setMessage] = useState("");
  const [editmessage, setEditmessage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const [users, setUsers] = useState([]);
  const [fullDetail, setFullDetail] = useState([]);
  const [allFileList, setAllFileList] = useState([]);
  const [allImageList, setAllImageList] = useState([]);
  const chatSection = useRef(null);
  const [fireBottom, setFireBottom] = useState(1);

  // delete settelment
  const [deleteId, setDeleteId] = useState("");
  const [deleteRowIndex, setDeleteRowIndex] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [deleteBoxShow, setDeleteBoxShow] = useState(true);
  const [SelectedUser, setSelectedUser] = useState("");
  const [ID, setID] = useState("");
  const [editIndex, setEditIndex] = useState("");
  const isValueChecked = (value) => checkedState.includes(value);
  let [projectStage, setProjectStage] = useState(1);
  let [currentUserAdminStatus, setCurrentUserAdminStatus] = useState(false);
  
  useEffect(() => {
    //
    setPageSlug(slug);
    getChat();
    callOverview();
    fetchPinData();
    //fetchUsersData();
    //getSelectedUsers();
    //ShowUsersModal();
    setAllFileList([]);
    setAllImageList([]);
  }, [id, slug]);

  async function getChat() {
    setLoader(true);
    const response = await getApiCall(
      "group-chat-history?rundev=rundev&giveArray=giveArray&group_id=" + id
    );
    if (response.status) {
      setChat(response.data.chats.data);
      //setFullDetail(response.data);
      //setGroup(response.data.group);
      //setFiles(response.data.files);
      //setMedia(response.data.media);
      //setMode(response.data.mode);
      //const sortedData = response.data.group.group_members.sort((a, b) => a.name.localeCompare(b.name));
      //setGroupMember(sortedData);
      //setGroupadmin(response.data.group_admin);
      //setProjectStage(response.data.group.current_status);
      //setCurrentUserAdminStatus(response.data.group.is_current_user_admin);
      
      scrollToBottom();
      setLoader(false);
    } else {
      navigate("/chat");
      setLoader(false);
    }
  }

  useEffect(() => {
    if (typeof otherChat != "undefined") {
      pusherChatChange(otherChat);
    }
  }, [otherChat]);

  const pusherChatChange = (response) => {
    if (typeof response.type != "undefined") {
      if (response.type == 1) {
        const newFruits = chat?.filter(
          (fruit) => fruit.id === response.data.id
        );
        if (newFruits.length > 0) {
          return;
        }

        const userToken = loginDetail();
        if (
          response.data.user.employee_id != userToken.user.employee_id &&
          pageSlug == response.data.group_slug
        ) {
          setChat((oldArray) => [response.data, ...oldArray]);
        } else if (
          response.data.user.employee_id != userToken.user.employee_id
        ) {
        }

        //scrollToBottom();
      } else if (response.type == 3) {
        if (pageSlug == response.data.group_slug) {
          let index = chat.findIndex(
            (x) => x.chat_token == response.data.chat_token
          );
          const newFruits = chat;
          newFruits[index].message = response.data.message;
          setChat(newFruits);
        }
      } else if (response.type == 4 && pageSlug == response.data.group_slug) {
        // remove
        const newFruits = chat?.filter(
          (fruit) => fruit.chat_token != response.data.chat_token
        );
        setChat(newFruits);
      }
    }
  };

  async function fetchUsersData() {
    const response = await getApiCall("users?rundev=rundev");
    if (response) {
      const sortedData = response.sort((a, b) => a.name.localeCompare(b.name));
      setUsers(sortedData);
    }
  }

  const removeChatElement = async (id, index) => {
    const response = await postApiCall("delete-chat", { chat_id: id, type: 2 });
    if (response.status) {
      const newFruits = chat?.filter((fruit) => fruit.id != id);
      setChat(newFruits);
      fetchPinData();
    }
  };

  const pinChatElement = async (id, index) => {
    const response = await postApiCall("pin-msg", { message_id: id, type: 2 });
    if (response.status) {
      let newChat = chat;
      if (newChat[index]?.is_pinned === 1) {
        newChat[index].is_pinned = 0;
      } else {
        newChat[index].is_pinned = 1;
      }
      setChat(newChat);
      fetchPinData();
    }
  };

  const markFavoriteElement = async () => {
    const response = await postApiCall("mark-favorite", {
      chat_partner: id,
      type: 2,
    });
    if (response.status) {
      let newState = { ...group };
      if (group?.is_favorite == 1) {
        newState.is_favorite = 0;
      } else {
        newState.is_favorite = 1;
      }
      setGroup(newState);
      dashboard();
    }
  };

  async function fetchPinData() {
    const response = await getApiCall(
      "get-pinned-chats?rundev=rundev&chat_partner=" + id + "&type=2"
    );
    setPinnedMessageCount(response.data.length);
    setPinnedMessage(response.data);
  }

  const handelSubmit = async (e) => {
    setSending(true);
    const formData = new FormData();
    formData.append("group_id", id);
    formData.append("message", message);
    Object.keys(fileList).map(function (key) {
      formData.append("uploads[]", fileList[key]);
    });
    setMessage("");
    setFileList([]);
    const response = await postApiCall("group-chat", formData);
    if (response.status) {
      setChat((oldArray) => [response.data, ...oldArray]);
      /*if (response.data.documents?.chat_files.length > 0) {
        setFiles((oldArray) => [
          ...response.data.documents?.chat_files,
          ...oldArray,
        ]);
      }
      if (response.data.documents?.chat_media.length > 0) {
        setMedia((oldArray) => [
          ...response.data.documents?.chat_media,
          ...oldArray,
        ]);
      }*/
      scrollToBottom();
      dashboard();
      setSending(false);
    } else {
      setSending(false);
    }
  };

  const EditChat = async (id, index) => {
    setID(id);
    setEditIndex(index);
  };
  const handelUpdate = async (e) => {
    const response = await postApiCall("edit-chat", {
      message: editmessage,
      type: "2",
      chat_id: ID,
    });
    if (response.status) {
      let newChat = chat;
      newChat[editIndex].message = editmessage;
      setChat(newChat);

      setEditmessage("");
      setID("");
    }
    return;
  };
  const handleCancleMessage = () => {
    setEditmessage("");
    setID("");
  };

  const GroupMemberSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("group_id", id);
    checkedState?.forEach((tag) => data.append("users[]", tag));
    const response = await postApiCall("add-group-members", data);
    if (response) {
      getGroupMember();
      //getApiCall("users");
      window["closeModal"]();
      setCheckedState([]);
    }
  };

  const handleOnChange = (position) => {
    const index = checkedState.findIndex((day) => day === position);
    if (index > -1) {
      setCheckedState([
        ...checkedState.slice(0, index),
        ...checkedState.slice(index + 1),
      ]);
    } else {
      setCheckedState((oldArray) => [...oldArray, position]);
    }
  };

  const getGroupMember = async (e) => {
    const response = await getApiCall(
      "view-group-members?rundev=rundev&group_id=" + id
    );
    if (response) {
      const sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name));
      setGroupMember(sortedData);
      //getSelectedUsers();
    }
  };

  const deleteFromGroup = async (user, index) => {
    const response = await postApiCall("delete-group-member", {
      group_id: id,
      user_id: user,
    });
    if (response) {
      getGroupMember();
    }
  };

  const handleScroll = async (event) => {
    const nextpage = page + 1;
    const response = await getApiCall(
      "group-chat-history?rundev=rundev&group_id=" + id + "&page=" + nextpage
    );
    if (response.data.chats.data.length > 0) {
      setChat((oldArray) => [...oldArray, ...response.data.chats.data]);
      setPage(nextpage);
    }
  };

  const callFiles = async () => {
    setLoader(true);
    const response = await getApiCall(
      "get-media?rundev=rundev&chat_partner=" + id + "&type=2"
    );
    setAllFileList(response.files);
    setAllImageList(response.media);
    setLoader(false);
  };

  const callOverview = async () => {
    setLoader(true);
    const response = await getApiCall(
      "group-overview?rundev=rundev&group_id=" + id
    );
    if (response.status) {
      setFullDetail(response.data);
      setGroup(response.data.group);
      setMode(response.data.mode);
      const sortedData = response.data.group.group_members.sort((a, b) => a.name.localeCompare(b.name));
      setGroupMember(sortedData);
      setGroupadmin(response.data.group_admin);
      setProjectStage(response.data.group.current_status);
      setCurrentUserAdminStatus(response.data.group.is_current_user_admin);
      setLoader(false);

    }

  };

  
  const deleteFiles = async (path) => {
    //const response = await postApiCall('remove-files',{'chat_id': id,'type': 2,'file_path':path});
  };

  function showDeletePopup(type, id, index) {
    setDeleteId(id);
    setDeleteType(type);
    setDeleteRowIndex(index);
    window["showModal"]();
  }
  function confirmDelete() {
    if (deleteType == "removeChatElement") {
      removeChatElement(deleteId, deleteRowIndex);
    } else if (deleteType == "deleteFromGroup") {
      deleteFromGroup(deleteId, deleteRowIndex);
    } else if (deleteType == "leaveGroup") {
      leaveGroup();
    }
    window["closeModal"]();
    setDeleteId("");
    setDeleteType("");
  }
  useEffect(() => {
    chatSection.current?.scrollIntoView({ block: "end" });
  }, [fireBottom]);
  const scrollToBottom = () => {
    setFireBottom(fireBottom + 1);
  };

  const leaveGroup = async () => {
    const response = await postApiCall("left-group", { group_id: id });
    if (response) {
      dashboard();
      navigate("/chat");
    }
  };

  const getSelectedUsers = async () => {
   if(userTokenLogin.user.employee_type == 'inhouse'){
      const response = await getApiCall(
        "view-non-group-members?rundev=rundev&group_id=" + id
      );
      const sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name));
      setSelectedUser(sortedData);
    }
  };
  const ShowUsersModal = () => {
    setCheckedState([]);
    getSelectedUsers();

  };

  // drag and drop
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setFileList(files);
  };
  const openTab = (type) => {
    setTab(type);
    if (type == "Files" || type == "Media") {
      callFiles();
    }else if (type == "overview") {
      //callOverview();
    }
  };
  const updateGroupPosition = async (status) => {
    const response = await postApiCall("update-group-status", {
      group_id: id,
      status: status,
    });
    if (response) {
      setProjectStage(status);
    }
  };

  return (
    <>
      {loader == true ? ( <FullScreenLoader />
      ) : (
        <div className="bg-white  user-chat w-100 overflow-hidden">
          <div className="user-chat-overlay"></div>
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <div id="users-chat" className="position-relative">
                <div
                  className="user-chat-topbar"
                  style={{ marginTop: "unset", padding: "6px 10px", paddingBottom:'0' }}
                >
                  {pageType != "projectPage" && (
                    <Header
                      name={group?.name}
                      profile={mode}
                      other={group?.description}
                      enquiry_id={group?.enquiry_id}
                      headerFor="group"
                      openTab={openTab}
                      SelectedTab={tab}
                      projectStage={projectStage}
                    />
                  )}
                  <PinAlert pinnedMessageCount={pinnedMessageCount} />
                </div>
                {tab == "Chat" && (
                  <div
                    className=" bg-white chat-conversation"
                    id="chat-conversation"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    <i
                      className="bx bx-caret-down-circle text-muted ms-2"
                      onClick={scrollToBottom}
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        right: "40px",
                        fontSize: "20px",
                        borderRadius: "100%",
                        cursor: "pointer",
                      }}
                    ></i>

                    <ul
                      className="list-unstyled chat-conversation-list"
                      id="users-conversation"
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                        overflow: "auto",
                      }}
                    >
                      <InfiniteScroll
                        dataLength={chat.length}
                        next={handleScroll}
                        hasMore={true}
                        //loader={<h5 className="text-center">Loading...</h5>}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                        }} //To put endMessage and loader to the top.
                        inverse={true}
                        scrollableTarget="users-conversation"
                      >
                        <ChatList
                          dashboard={dashboard}
                          EditChat={EditChat}
                          chats={chat}
                          pinChatElement={pinChatElement}
                          removeChatElement={showDeletePopup}
                          editChatID={ID}
                          handelSubmit={handelUpdate}
                          message={editmessage}
                          setMessage={setEditmessage}
                          handleCancleMessage={handleCancleMessage}
                          chatType={2}
                        />
                      </InfiniteScroll>
                    </ul>
                    <div ref={chatSection}></div>
                  </div>
                )}
                {tab == "overview" && (
                  <Overview dashboard={dashboard} groupMember={groupMember} markFavoriteElement={markFavoriteElement} group={group} groupadmin={groupadmin} showDeletePopup={showDeletePopup} userTokenLogin={userTokenLogin} ShowUsersModal={ShowUsersModal} updateGroupPosition={updateGroupPosition} projectStage={projectStage} fullDetail={fullDetail}/>
                )}
                {tab == "Checklist" && <Checklist dashboard={dashboard} />}
                {tab == "Timeline" && <Timeline dashboard={dashboard} />}

                {tab == "Task" && (
                  <div className="chat-conversation">
                    <ProjectTask dashboard={dashboard} />
                  </div>
                )}
                {tab == "Report" && (
                  <Report dashboard={dashboard} groupMember={groupMember} />
                )}
                {tab == "Files" &&
                  (allFileList?.length !== 0 ? (
                    <div
                      className="p-4"
                      style={{ "overflow-y": "scroll", height: "90vh" }}
                    >
                      <ChatFiles data={allFileList} ShowAllChatFiles={true} deleteFiles={deleteFiles} />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center flex-column">
                      <img
                        src="../../../assets/images/empty-image.png"
                        alt="empty"
                        width={"40%"}
                      />
                      <p className="text-center">No documents found.</p>
                    </div>
                  ))}
                {tab == "Media" && (
                  <div
                    className="p-4"
                    style={{ "overflow-y": "scroll", height: "90vh" }}
                  >
                    {allImageList?.length !== 0 ? (
                      <ChatMedia data={allImageList} />
                    ) : (
                      <div className="d-flex align-items-center justify-content-center flex-column">
                        <img
                          src="../../../assets/images/empty-image.png"
                          alt="empty"
                          width={"40%"}
                        />
                        <p className="text-center">No photos found.</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {tab == "Chat" && (
                <PostMessage
                  handelSubmit={handelSubmit}
                  message={message}
                  setMessage={setMessage}
                  setFileList={setFileList}
                  fileList={fileList}
                  sending={sending}
                  slug={slug}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <PinPopup
        data={pinnedMessage}
        pinnedMessageCount={pinnedMessageCount}
        pinChatElement={pinChatElement}
      />
      <SearchChat
        id={id}
        pinChatElement={pinChatElement}
        removeChatElement={showDeletePopup}
        type={2}
      />
      <ShowAllFiles data={allFileList} />
      <ShowAllImage data={allImageList} />
      {deleteBoxShow && <DeleteConfirmation fElement={confirmDelete} />}
      <div
        className="modal fade"
        id="addcontactModall"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addgroup-exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <form
            onSubmit={GroupMemberSubmit}
            className="modal-content modal-header-colored shadow-lg border-0"
          >
            <div className="modal-header">
              <h5 className="modal-title text-white font-size-16">
                Add New Member
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-4">
              <ul className="list-unstyled chat-list">
                {/* res = arr1.filter(item => !arr2.includes(item)); */}

                {/* {console.log(res)} */}
                {SelectedUser &&
                  SelectedUser?.map((row, index) => (
                    <li key={index}>
                      <div className="d-flex">
                        <div className="flex-shrink-0 avatar-xs me-3">
                          <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                            <img
                              src={row.profile.path}
                              alt="media img"
                              className="rounded-circle avatar-xs"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="font-size-14 text-left">{row.name}</h5>
                        </div>
                        <div className="flex-shrink-0 ms-3">
                          <input
                            type="checkbox"
                            value={row.id}
                            onChange={() => handleOnChange(row.id)}
                            checked={isValueChecked(row.id)}
                          />
                        </div>
                      </div>
                      <hr className="my-2" />
                    </li>
                  ))}
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Add Members
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default GroupChat;
