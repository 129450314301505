import axios from 'axios';
import { toast } from 'react-toastify';
const baseUrl = 'https://prafullgupta.com/connectwork/api/chat-manager/';


const getApiCall = async (path,payload='') => { 
    let token = loginDetail();
    const response =  await axios.get(baseUrl+path+payload, { headers: { Authorization: `Bearer ${token.token}`,ContentType: 'application/json; charset=utf-8' } }).catch(function (error) {
         if(error.response.status==403){
             localStorage.removeItem('token');
             window.location.href='/';
             return false;
         }
    });
    const r = response?.data;
    if(r.status==false && r.message=='Unauthenticated.'){
        localStorage.removeItem('token');
        window.location.href='/';
        return false;
    }
    return await response?.data;
}
const postApiCall = async (path,payload='') => { 
    let token = loginDetail();
    return axios.post(baseUrl+path, payload,{ headers: { Authorization: `Bearer ${token.token}`, ContentType: 'multipart/form-data; charset=utf-8' }}).then(response => {
        if(path!='chat' && path!='group-chat' && path!='system-search' && path!='track-device-activity'){
            if(typeof response.data.message != 'undefined' && response.data.message!='This is data'){
                toast(response.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
        const r = response.data;
        if(r.status ==false && r.message=='Unauthenticated.'){
            localStorage.removeItem('token');
            return false;
        }
        return response.data;
    }, function (error) {
        if(error.response.status==403){
            localStorage.removeItem('token');
            window.location.href='/';
            return false;
        }
    });
}

const loginDetail = () => { 
    const tokenString = localStorage.getItem('token');
    if(tokenString){
        let userDetail = JSON.parse(tokenString);
        return userDetail;
    }
    return false;
}

export {
    getApiCall,
    postApiCall,
    loginDetail
}
