function Profile({data,settings,type}){
return (<div>
    <div className="user-profile-img">
        <img src={data?.profile?.path} className="profile-img" style={{ height: "160px" }} alt="" />
        <div className="overlay-content">
            <div>
                <div className="user-chat-nav p-2 ps-3">
                    <div className="d-flex w-100 align-items-center">
                        <div className="flex-grow-1">
                            {type=='self' ? 
                                <h5 className="text-white mt-3">My Profile</h5>
                            :
                            <button type="button" className="btn nav-btn text-white user-profile-show d-lg-block">
                                <i className="bx bx-x"></i>
                            </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="text-center p-3 p-lg-4 border-bottom pt-2 pt-lg-2 mt-n5 position-relative">
        <div className="mb-lg-3 mb-2">
            <img src={data?.profile?.path} className="rounded-circle avatar-lg img-thumbnail" alt="" />
        </div>

        <h5 className="font-size-16 mb-1 text-truncate">{data?.name}</h5>
        <p className="text-muted font-size-14 text-truncate mb-0">{data?.email}</p>
    </div>
    <div className="p-4" data-simplebar>
        <div className="text-muted">
            <p className="mb-4">Clock In Time: {settings?.clockInTime}</p>
            <p className="mb-4">Clock Out Time: {settings?.clockOutTime}</p>
            <p className="mb-4">Break Time: {settings?.breakTime}</p>
            <p className="mb-4">Other Break Time: {settings?.otherBreakTime}</p>
            <p className="mb-4">Tea Time: {settings?.teaTime}</p>
        </div>
    </div>
</div>);

}
export default Profile;