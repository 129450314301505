import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getApiCall, postApiCall } from "../data/Index";
function CreateChannel({ fetchData }) {
  /* channel create */
  const [channelName, setChannelName] = useState("");
  const [channelGroupType, setChannelGroupType] = useState(2);
  const [channelDescription, setChannelDescription] = useState("");
  const [channelMessage, setChannelMessage] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [users, setUsers] = useState([]);
  const [Checked, setChecked] = useState(true);

  useEffect(() => {
    fetchUsersData();
  }, []);

  const handleOnChange = (position) => {
    const index =
      checkedState && checkedState?.findIndex((day) => day === position);
    if (index > -1) {
      setCheckedState([
        ...checkedState.slice(0, index),
        ...checkedState.slice(index + 1),
      ]);
    } else {
      setCheckedState((oldArray) => [...oldArray, position]);
    }
  };

  const handleCreateChannelSubmit = async (e) => {
    e.preventDefault();
    setChannelMessage("");
    const data = new FormData();
    checkedState?.forEach((tag) => data.append("users[]", tag));
    let payload = {
      name: channelName,
      type: "1",
      description: channelDescription,
      users: checkedState,
    };

    const response = await postApiCall("create-group", payload);
    if (response.status) {
      window["closeModal"]();
      setChannelName("");
      setChannelDescription("");
      setCheckedState([]);
      fetchData();
      setChannelMessage(response.message);
    } else {
      setChannelMessage(response.message);
    }
  };

  async function fetchUsersData() {
    const response = await getApiCall("users?rundev=rundev");
    if (response) {
      const sortedData = response.sort((a, b) => a.name.localeCompare(b.name));
      setUsers(sortedData);
    }
  }
  const isValueChecked = (value) => checkedState.includes(value);

  return (
    <div>
      <div
        className="modal fade"
        id="addgroupModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addgroup-exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <form
            onSubmit={handleCreateChannelSubmit}
            className="modal-content modal-header-colored shadow-lg border-0 custom-class"
          >
            <div className="modal-header">
              <h5 className="modal-title text-white font-size-16">
                Create New Channel
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-4">
              {/* <p className="text-center m2">{channelMessage}</p> */}
              <div className="mb-4">
                <label htmlFor="addgroupname-input" className="form-label">
                  Name<span className="font-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="addgroupname-input"
                  placeholder="Enter Group Name"
                  value={channelName}
                  onChange={(e) => setChannelName(e.target.value)}
                  required
                />
              </div>
              {/* <div className="mb-4">
                <label htmlFor="addgroupname-input" className="form-label">
                  Select Channel Type<span className="font-danger">*</span>
                </label>
                <select
                  className="form-select"
                  id="addgroupname-input"
                  onChange={(e) => setChannelGroupType(e.target.value)}
                  required
                >
                  <option value="2">Public Channel</option>
                  <option value="1">Private Channel</option>
                </select>
              </div> */}
              <div className="mb-3">
                <label
                  htmlFor="addgroupdescription-input"
                  className="form-label"
                >
                  Description
                </label>
                <textarea
                  className="form-control"
                  id="addgroupdescription-input"
                  rows="3"
                  placeholder="Enter Description"
                  onChange={(e) => setChannelDescription(e.target.value)}
                  value={channelDescription}
                ></textarea>
              </div>
              {/* {channelGroupType == "1" && ( */}
              <div className="mb-3">
                <label className="form-label">Channel Member</label>
                {users?.map((row, index) => (
                  <div key={index} className="col-12 mb-2">
                    <div className="d-flex align-items-end">
                      <div className="flex-shrink-0 avatar-xs me-3">
                        <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                          <img
                            src={row.profile.path}
                            alt="media img"
                            className="rounded-circle avatar-xs"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-15 text-left">{row.name}</h5>
                      </div>
                      <div className="flex-shrink-0 ms-3">
                        <input
                          type="checkbox"
                          value={row.id}
                          onChange={() => handleOnChange(row.id)}
                          checked={isValueChecked(row.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* )} */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Create Channel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default CreateChannel;
