/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { getApiCall, postApiCall } from "../data/Index";
import PinList from "../component/PinList";
import { Link } from "react-router-dom";
import FullScreenLoader from '../../FullScreenLoader';
function Bookmarkpage() {
  const [bookmarks, setBookmarks] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    setLoading(true);
    const r = await getApiCall("get-all-pinned-chats?rundev=rundev");
    setBookmarks(r.data);
    setLoading(false);
  };

  const pinChatElement = async (id, type) => {
    const response = await postApiCall("pin-msg", {
      message_id: id,
      type: type,
    });
    //console.log("response",response)
    if (response.status) {
      getDashboardData();
    }
  };

  const [SearchToggle, setSearchToggle] = useState(false);

  const handleSearchToggle = () => {
    setSearchToggle(true);
    if (SearchToggle) {
      setSearchToggle(false);
    }
  };

  return (
    <>
    {loading ? <FullScreenLoader /> :
    <div className="bg-white user-chat w-100">
      <div className="user-chat-overlay"></div>{" "}
      <div className="employe-report-header mb-4 w-100 d-flex align-items-center justify-content-between">
        <h4 className="mb-0">Bookmark</h4>
        <i
          className="bx bx-search align-middle fs-4 cursor-pointer"
          onClick={handleSearchToggle}
        ></i>
      </div>
      <div className="px-4 pt-0">
        {SearchToggle && (
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control bg-light border-0 pe-0 radius-0"
              id="serachBookmark"
              placeholder="Search here.."
              aria-label="Example text with button addon"
              aria-describedby="searchbtn-addon"
              autoComplete="off"
            />
            <button
              className="btn btn-light bookmark-btn"
              type="button"
              id="searchbtn-addon"
            >
              <i className="bx bx-search align-middle"></i>
            </button>
          </div>
        )}
      </div>
      <div className="chat-message-list chat-bookmark-list" data-simplebar>
        <ul className="list-unstyled chat-conversation">
          {bookmarks?.length !== 0 ? (
            <PinList data={bookmarks} pinChatElement={pinChatElement} />
          ) : (
            <div className="container">
              <div className="row mt-3">
                <div className="col-sm-12 text-center p-5">
                  <h2>Bookmark of the system</h2>
                  <p>Search your conversion in an agile way.</p>
                </div>
              </div>
              
            </div>
          )}
        </ul>
      </div>
    </div>
    }
    </>
  );
}

export default Bookmarkpage;
