import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getApiCall, postApiCall, loginDetail } from "../data/Api";
import FullScreenLoader from '../../FullScreenLoader';
function AttendeeTodayTodo() {
  const [loading, setLoading] = useState(true);
  const Today = new Date().toISOString().split("T")[0];
  const [sections, setSections] = useState([]);
  const { format } = require("date-fns");
  const todayDate = format(new Date(), "eeee, LLLL d");
  useEffect(() => {
    handleSummeryData();
  }, []);

  const handleSummeryData = async () => {
    const response = await getApiCall(
      "get-juniors-todos?rundev=rundev&start_date=" +Today
    );
    if (response.status) {
      setLoading(false);
      setSections(response.data.juniors);
    }
  };
  return (
    <>
      {loading ? <FullScreenLoader /> :
        <div className="bg-white user-chat w-100 overflow-hidden">
          <div className="user-chat-overlay"></div>
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <div id="users-chat" className="position-relative p-2">
                <div className="p-1 p-lg-2">
                  <div className="row"><div className="col-sm-12"><h6 className="text-center">{todayDate}</h6></div></div>
                </div>
              </div>
              <div
                className="chat-conversation"
                id="chat-conversation"
                data-simplebar
                style={{ height: "calc(100vh - 100px)" }}
              >
                <div className="container mt-4">
                  <div className="accordion" id="accordionTodo">
                    {sections?.map((section, index) => (
                      <div className="accordion-item mb-3" key={index}>
                        <h2 className="accordion-header" id={`heading-${index}`}>
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapsetodo-${index}`}
                            aria-expanded="true"
                            aria-controls={`collapsetodo-${index}`}
                          >
                            {section.name}
                          </button>
                        </h2>
                        <div
                          id={`collapsetodo-${index}`}
                          className="accordion-collapse collapse show"
                          aria-labelledby={`heading-${index}`}
                          data-bs-parent="#accordionTodo"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-sm-12">
                                <table className="table">
                                  <thead style={{ background: "unset" }}>
                                    <th style={{ width: "80%" }}>Activity</th>
                                    <th>Hours</th>
                                    <th>Status</th>
                                  </thead>
                                  <tbody>
                                    {section?.todos?.map((row, indexRow) => (
                                      <tr key={indexRow} style={{ color: row.done===1 ? "#3076F6" : (row.done===2? "#ffa500":"")}}>
                                        <td>{row.task_name}</td>
                                        <td>{row.expected_hours}</td>
                                        <td>{row.done===1 ? "Done" : (row.done===2? "Currently Working":"Pending")}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default AttendeeTodayTodo;
