import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pusher from "pusher-js";
import useToken from "./Auth/useToken";
import NotificationSound from "./notification.mp3";
import { getApiCall, loginDetail,postApiCall } from "./ChatPortal/data/Api";
import Register from "./Auth/Register";
import Login from "./Auth/Login";
import Profile from "./Auth/Profile";
import Dashboard from "./Landing/Dashboard";
import LeftSideBarMenu from "./Landing/LeftSideBarMenu";
import Setting from "./Landing/Setting";
import Chat from "./ChatPortal/Chat";
import MembersChat from "./ChatPortal/pages/MembersChat";
import GroupChat from "./ChatPortal/pages/GroupChat";
import Files from "./ChatPortal/pages/Files";
import Bookmarkpage from "./ChatPortal/pages/Bookmarkpage";
import ImportantChat from "./ChatPortal/pages/ImportantChat";
import SummaryLanding from "./Summary/SummaryLanding";
import AttendeeTodaySummary from "./Summary/pages/AttendeeTodaySummary";
import AttendeeSummary from "./Summary/pages/AttendeeSummary";
import TodoLanding from "./Summary/TodoLanding";
import AttendeeTodo from "./Summary/pages/AttendeeTodo";
import Report from "./Summary/pages/Report";
import Employeereport from "./Summary/pages/Employeereport";
import AttendeeTodayTodo from "./Summary/pages/AttendeeTodayTodo";
import ProjectReport from "./Summary/pages/ProjectReport";
import { getMessaging, getToken } from "firebase/messaging";
import { Messaging } from "firebase/messaging";
import { messaging } from "./firebase";
import Searchpage from "./ChatPortal/pages/Search";
import Feedback from "./Summary/pages/Feedback";
import Footer from "./Footer";

function App() {
  const { token, setToken } = useToken();
  const userToken = loginDetail();
  let [pageSlug, setPageSlug] = useState(0);
  let [clockIn, setclockIn] = useState(false);
  const [isTrackingActive, setIsTrackingActive] = useState(false);
  const trackingIntervalRef = useRef(null);
  const [personalChat, setPersonalChat] = useState([]);
  const [otherChat, setOtherChat] = useState([]);
  const [visibilityState, setVisibilityState] = useState("visible");
  const audioPlayer = useRef(null);
  const [chatList, setChatList] = useState([]);
  let callTime = 1;
  //messaging
  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      //token
      const Firebase_token = await getToken(messaging, {
        vapidKey:
          "BHKaz4VBta30djw8gW-p2UDH7Z3fejVrt7HmgrZKe38fN7tEtPzp8klg9RTILOzhjnD2rNMP_8VmG1aBMhHnlqs"
        });
      // console.log(Firebase_token);
      localStorage.setItem("firebase-token", Firebase_token);
      
    } else if (permission === "denied") {
      // console.log("we have denied permission!, Please alow the permission.");
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  useEffect(() => {
    startActiveTracking();
  }, [isTrackingActive]); 

  async function startActiveTracking() {
    // Clear any existing interval
    clearInterval(trackingIntervalRef.current);

    if (isTrackingActive) {
      // Start new interval if tracking is not active
      trackingIntervalRef.current = setInterval(async () => {
        try {
          const response = await postApiCall("track-device-activity",{"action":"active","rundev":"rundev"});
          console.log("tr:", response);
        } catch (error) {
          console.error("Te:", error);
        }
      }, 600000); // Run every second
    }
  }

  async function dashboard() {
    setPageSlug(pageSlug + 1);
  }

  useEffect(() => {
    setVisibilityState(document.visibilityState);
  }, [visibilityState]);
  document.addEventListener("visibilitychange", (e) => {
    setVisibilityState(document.visibilityState);
  });


  useEffect(() => {
    if (callTime == 1) {
      callPusher();
    }
    callTime = 1 + callTime;
  }, [token]);

  async function callPusher() {
    // chat function calling one time when project load

    const userToken = loginDetail();
    if (userToken) {
      // console.log(userToken);
      const pusher = new Pusher("727315625cd81359da1a", { cluster: "ap2" });
      let channel = pusher.subscribe("chat-pipe-" + userToken.user.employee_id);

      channel.bind("App\\Events\\ChatBroadCast", function (response) {
        // console.log(response);
        setPersonalChat(response);
        // console.log(response);
        if (response.type == 1) {
          dashboard();
          if (response.data.user.employee_id != userToken?.user?.employee_id) {
            showTost("message");
          }
        }
      });

      const response = await getApiCall("getChatters?rundev=rundev");
      if (response.status) {
        setChatList(response.data);
        response.data?.favorite_groups?.map((row, index) => {
          channel = pusher.subscribe("group-pipe-" + row.slug);

          channel.bind("App\\Events\\GroupBroadCast", function (res) {
            // console.log(res);
            setOtherChat(res);
            if (res.type == 1) {
              dashboard();
              if (res.data.user.employee_id != userToken.user.employee_id) {
                showTost("message");
              }
            }
          });
        });
        response.data?.groups?.map((row, index) => {
          channel = pusher.subscribe("group-pipe-" + row.slug);
          channel.bind("App\\Events\\GroupBroadCast", function (res) {
            setOtherChat(res);
            //console.log(res);
            if (res.type == 1) {
              dashboard();
              if (res.data.user.employee_id != userToken.user.employee_id) {
                showTost("message");
              }
            }
          });
        });
      }
    }
  }

  const showTost = (msg) => {
    if (visibilityState) {
      const notificationElement = document.getElementById("notification");
      if (notificationElement) {
        notificationElement.muted = false;
        notificationElement.play().catch(error => {
          console.error("Audio playback failed:", error);
        });
      }

      //document.getElementById("notification").muted = false;
      //document.getElementById("notification").play();
    }
  };
  return (
    <Router>
      <>
        <audio id="notification" ref={audioPlayer} src={NotificationSound} />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
        {token ? (
          <div className="layout-wrapper bg-grey d-lg-flex">
            
            <LeftSideBarMenu />
            {userToken.user.employee_type == 'inhouse' && clockIn==false ? (
              <Routes>
                <Route path="*" element={<Dashboard setclockIn={setclockIn} setIsTrackingActive={setIsTrackingActive}/>} />
              </Routes>
            ) : (
            <Routes>
              <Route exact path="/" element={<Dashboard setclockIn={setclockIn} setIsTrackingActive={setIsTrackingActive}/>} />
              <Route
                exact
                path="/profile"
                element={<Profile dashboard={dashboard} />}
              />
              {userToken.user.employee_type == 'inhouse' && (
                <Route
                  exact
                  path="setting"
                  element={<Setting dashboard={dashboard} />}
                />
              )}
              <Route
                exact
                path="inbox"
                element={<ImportantChat dashboard={dashboard} />}
              />
              <Route
                exact
                path="bookmark"
                element={<Bookmarkpage dashboard={dashboard} />}
              />
              {userToken.user.employee_type == 'inhouse' && (
              <Route
                exact
                path="feedback"
                element={<Feedback dashboard={dashboard} />}
              />
              )}
              {userToken?.user?.is_admin && userToken.user.employee_type == 'inhouse' && (
                <>
                <Route
                    exact
                    path="files"
                    element={<Files dashboard={dashboard} />}
                />
                <Route
                    exact
                    path="projectreport"
                    element={<ProjectReport dashboard={dashboard} />}
                />
                </>
              )}
              
              {userToken.user.employee_type == 'inhouse' && (
                <Route
                  exact
                  path="search"
                  element={<Searchpage dashboard={dashboard} />}
                />
              )}
              <Route path="/chat" element={<Chat dashboard={dashboard} chatList={chatList}/>}>
                <Route
                  exact
                  path="g/:slug/:id"
                  element={
                    <GroupChat dashboard={dashboard} otherChat={otherChat} />
                  }
                />
                <Route
                  exact
                  path="m/:slug/:id"
                  element={
                    <MembersChat
                      dashboard={dashboard}
                      personalChat={personalChat}
                    />
                  }
                />
              </Route>
              {userToken.user.employee_type == 'inhouse' && (
              <Route
                path="/report"
                element={<Report dashboard={dashboard} />}
              ></Route>
            )}
              {userToken.user.employee_type == 'inhouse' && (
              <Route
                path="/employeereport"
                element={<Employeereport dashboard={dashboard} />}
              ></Route>
              )}
              
              <Route
                path="/summary"
                element={<SummaryLanding dashboard={dashboard} />}
              >
                <Route
                  exact
                  path="todayssummary"
                  element={<AttendeeTodaySummary dashboard={dashboard} />}
                />
                <Route
                  exact
                  path="m/:slug/:id/:name"
                  element={<AttendeeSummary dashboard={dashboard} />}
                />
              </Route>

              <Route
                path="/todo"
                element={<TodoLanding dashboard={dashboard} />}
              >
                <Route
                  exact
                  path="todaystodo"
                  element={<AttendeeTodayTodo dashboard={dashboard} />}
                />
                
                <Route
                  exact
                  path="m/:slug/:id/:name"
                  element={<AttendeeTodo dashboard={dashboard} />}
                />
              </Route>
              <Route path="*" element={<Dashboard setclockIn={setclockIn}/>} />
            </Routes>
            )}
          </div>
        ) : (
          <Routes>
            <Route exact path="/" element={<Login setToken={setToken} />} />
            <Route
              exact
              path="/login"
              element={<Login setToken={setToken} />}
            />
            <Route
              exact
              path="/create-account"
              element={<Register setToken={setToken} />}
            />
            <Route path="*" element={<Login setToken={setToken} />} />
          </Routes>
        )}
      </>
    </Router>
  );
}

export default App;
