import React, { useEffect, useState } from "react";
import { getApiCall, postApiCall, loginDetail } from "../Service/Api";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Select from "react-select";

function Setting({ dashboard }) {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { format } = require("date-fns");
  const today = format(new Date(), "eeee, LLLL d");
  const userToken = loginDetail();
  const [data, setData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShoww = () => setShow(true);
  const [ModalId, setModalId] = useState("");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (id) => {
    setShowModal(true);
    setModalId(id);
  };
  const [Image, setImage] = useState("");
  const [Name, setName] = useState("");
  const [mentor, setMentor] = useState("");
  const [Email, setEmail] = useState("");
  const [users, setUser] = useState([]);
  const [EmployeID, setEmployeID] = useState("");
  const [Address, setAddress] = useState("");
  const [Number, setNumber] = useState("");
  const [LastName, setLastName] = useState("");
  const [employeeType, setEmployeeType] = useState("");

  useEffect(() => {
    api();
  }, []);

  const api = async () => {
    const response = await getApiCall(
      "chat-manager/get-workspace?rundev=rundev"
    );
    // console.log(response);
    setUser(response.data);
  };
  const UpdateProfile = async () => {
    const formData = new FormData();

    formData.append("email", Email);
    formData.append("name", Name);
    formData.append("employee_id", Email);
    formData.append("employe_id", EmployeID);
    formData.append("address", Address);
    formData.append("number", Number);
    formData.append("last_name", LastName);
    formData.append("profile", Image[0]);
    formData.append("employee_type", employeeType);

    mentor?.forEach((tag) => formData.append("mentor_id[]", tag.value));
    const response = await postApiCall(
      "chat-manager/workspace-add-user",
      formData
    );
    if (response.status) {
      setShow(false);
    }
  };

  const Options = [];
  users?.users?.map((item) => {
    Options.push({ value: item.id, label: item.name });
  });

  const handleChange = (id) => {
    setMentor(id);
  };

  return (
    <>
      <Modal size={"md"} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="" style={{ padding: "10px 0" }}>
            <div className="form-input-div">
              <label>Upload Profile Picture</label>
              <br />
              <input
                type="file"
                onChange={(e) => {
                  setImage(e.target.files);
                }}
                className="form-control profile-inputs"
              />
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6 form-input-div">
                <label>First Name *</label>
                <br />
                <input
                  type="text"
                  value={Name || ""}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  className="form-control profile-inputs"
                />
              </div>

              <div className="col-sm-12 col-lg-6 form-input-div">
                <label>Last Name *</label>
                <br />
                <input
                  type="text"
                  value={LastName || ""}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  className="form-control profile-inputs"
                />
              </div>

              <div className="col-sm-12 col-lg-6 form-input-div">
                <label>Email *</label>
                <br />
                <input
                  type="email"
                  value={Email || ""}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  className="form-control profile-inputs"
                />
              </div>

              <div className="col-sm-12 col-lg-6 form-input-div">
                <label>Mentor</label>
                <br />
                {/* <select
                  className="form-select"
                  onChange={(e) => {
                    setMentor(e.target.value);
                  }}
                >
                  <option value="">Select Employee</option>
                  {users?.users?.map((row, index) => (
                    <option key={row.id} value={row.id}>
                      {row.name}{" "}
                    </option>
                  ))}
                </select> */}
                <Select
                  isMulti
                  name="colors"
                  options={Options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={mentor}
                  onChange={handleChange}
                />
              </div>

              <div className="col-sm-12 col-lg-6 form-input-div">
                <label>Phone Number</label>
                <br />
                <input
                  type="text"
                  value={Number || ""}
                  onChange={(e) => {
                    setNumber((v) =>
                      e.target.validity.valid ? e.target.value : v
                    );
                  }}
                  pattern="[0-9]*"
                  maxLength={10}
                  className="form-control profile-inputs"
                />
              </div>

              <div className="col-sm-12 col-lg-6 form-input-div">
                <label>Employe Id *</label>
                <br />
                <input
                  type="text"
                  value={EmployeID || ""}
                  onChange={(e) => {
                    setEmployeID(e.target.value);
                  }}
                  className="form-control profile-inputs"
                />
              </div>
              <div className="col-sm-12 col-lg-6 form-input-div">
                <label>Employee Type</label>
                <br />
                <select
                  className="form-select"
                  onChange={(e) => {
                    setEmployeeType(e.target.value);
                  }}
                >
                  <option value="">Select Employee Type</option>
                  <option value="inhouse">Inhouse</option>
                  <option value="outsider">Outsider</option>
                </select>
              </div>

              <div className="col-sm-12 col-lg-12 form-input-div">
                <label>Address</label>
                <br />
                <input
                  type="text"
                  value={Address || ""}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  className="form-control profile-inputs"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={UpdateProfile}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* View profile modal */}
      <Modal size={"md"} show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {users?.users?.map((item, i) => {
            return (
              <>
                {ModalId == item.id ? (
                  <div className="row">
                    <div className="text-center col-sm-12 mb-4">
                      <img
                        src={item?.profile?.path}
                        alt="image"
                        style={{ width: "150px", borderRadius: "10px" }}
                      />
                    </div>
                    <div className="col-sm-12 col-lg-12 form-input-div">
                      <label>Name:</label>
                      <br />
                      <input
                        type="text"
                        value={item.name || ""}
                        className="form-control profile-inputs"
                        disabled
                      />
                    </div>
                    <div className="col-sm-12 col-lg-12 form-input-div">
                      <label>Email:</label>
                      <br />
                      <input
                        type="text"
                        value={item.email || ""}
                        className="form-control profile-inputs"
                        disabled
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="w-100">
        <div className="profile-header">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ padding: "7px 25px" }}
          >
            <h1 className="d-flex align-items-center profile-heading">
              Team Member
            </h1>
          </div>
        </div>
        <div>
          <div className="container-fluid">
            <div className="row mt-2 d-flex align-items-center justify-content-center">
              {/*<div className="col-sm-6">
                <div className="card border">
                  <div className="card-body">
                    <h6>
                      <strong>{users.workspace_name}</strong>
                    </h6>
                    <hr />
                    <div
                      className="row"
                      style={{ minHeight: "300px", overflow: "auto" }}
                    >
                      <div className="col-sm-12">
                        <table className="table ">
                          <tbody>
                            <tr>
                              <td className="pt-3 pb-3">
                                <strong>Enquiry Allowed</strong>
                              </td>
                              <td>{users.maximum_enquiry_allowed}</td>
                            </tr>
                            <tr>
                              <td className="pt-3 pb-3">
                                <strong>Projects Allowed</strong>
                              </td>
                              <td>{users.maximum_projects_allowed}</td>
                            </tr>
                            <tr>
                              <td className="pt-3 pb-3">
                                <strong>Tasks Allowed</strong>
                              </td>
                              <td>{users.maximum_tasks_allowed}</td>
                            </tr>
                            <tr>
                              <td className="pt-3 pb-3">
                                <strong>User Allowed</strong>
                              </td>
                              <td>{users.maximum_user_allowed}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr />
                      <button className="btn btn-primary">Upgrade</button>
                    </div>
                  </div>
                </div>
              </div>*/}
              {users?.users?.length > 0 && (
                <div className="col-sm-8">
                  <div className="card border">
                    <div
                      className="card-body card-body-scroll"
                      style={{ height: "calc(85vh)", overflow: "auto" }}
                    >
                      <h6 className="d-flex align-items-center justify-content-between">
                        Team Member{" "}
                        {userToken.user.is_admin == true ? (
                          <Link className="text-right" onClick={handleShoww}>
                            Add new member
                          </Link>
                        ) : (
                          ""
                        )}
                      </h6>
                      <hr />
                      <div
                        className="row card-body-scroll"
                        // style={{ height: "300px", overflow: "auto" }}
                      >
                        {users?.users?.map((row, index) => (
                          <div key={index}>
                            <div className="col-sm-12">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 avatar-xs me-2">
                                  <span className="avatar-title chat-avatar chat-user-img online align-self-center rounded-circle bg-soft-light text-dark">
                                    <img
                                      className="rounded-circle avatar-xs"
                                      src={row?.profile?.path}
                                    />
                                    {row.activity_status == 1 && (
                                      <span className="user-status"></span>
                                    )}
                                  </span>
                                </div>
                                <div className="overflow-hidden" style={{width:'90%'}}>
                                  <p className="text-truncate mb-0">
                                    {row.name}
                                  </p>
                                </div>
                                <div className="dropdown">
                                  <button
                                    className="btn nav-btn dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="bx bx-dots-vertical-rounded"></i>
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item d-flex justify-content-between align-items-center user-profile-show"
                                      href="#"
                                      onClick={() => handleShowModal(row.id)}
                                    >
                                      View Profile{" "}
                                      <i className="bx bx-user text-muted"></i>
                                    </a>
                                    {userToken.user.is_admin == true ? (
                                      <Link
                                        to={
                                          "/summary/m/" +
                                          row.employee_id +
                                          "/" +
                                          row.id +
                                          "/" +
                                          row.name.split(" ")
                                        }
                                      >
                                        <a
                                          className="dropdown-item d-flex justify-content-between align-items-center user-profile-show"
                                          href="#"
                                        >
                                          Summary{" "}
                                          <i className="bx bx-user text-muted"></i>
                                        </a>
                                      </Link>
                                    ) : (
                                      ""
                                    )}

                                    {userToken.user.is_admin == true ? (
                                      <a
                                        className="dropdown-item d-flex justify-content-between align-items-center"
                                        href="#"
                                      >
                                        Archive{" "}
                                        <i className="bx bx-archive text-muted"></i>
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Setting;
