import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { getApiCall, postApiCall } from "../data/Api";
function LeftBar({ type = "Summner", link = "m" }) {
  const [group, setGroup] = useState([]);
  const [JrData, setJrData] = useState("");
  const [Users, setUsers] = useState("");

  useEffect(() => {
    handleMyData();
    jr();
  }, []);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  async function handleMyData() {
    const response = await postApiCall("get-profile");
    if (response) {
      setUsers(response.data);
    }
  }
  const groupList = (
    <li
      key={Users.id}
      className={
        Users.employee_id == splitLocation[3] ? "active hideToggler" : ""
      }
    >
      <Link to={`./${link}/${Users.employee_id}/${Users.id}/${Users.name}`}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs me-2">
            <span className="avatar-title chat-avatar chat-user-img online align-self-center rounded-circle bg-soft-light text-dark">
              <img
                className="rounded-circle avatar-xs"
                src={Users?.profile?.path}
              />
              {Users.activity_status == 1 && (
                <span className="user-status"></span>
              )}
            </span>
          </div>
          <div className="overflow-hidden">
            <p className="text-truncate mb-0">{Users.name}</p>
          </div>
        </div>
      </Link>
    </li>
  );

  const jr = async () => {
    const response = await getApiCall("get-all-juniors?rundev=rundev");
    setJrData(response.data);
  };

  const chatList =
    JrData &&
    JrData.map((item) => (
      <li
        key={item.id}
        className={item.employee_id == splitLocation[3] ? "active" : ""}
      >
        <Link to={`./m/${item.employee_id}/${item.id}/${item.name}`}>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 avatar-xs me-2">
              <span className="avatar-title chat-avatar chat-user-img online align-self-center rounded-circle bg-soft-light text-dark">
                <img
                  className="rounded-circle avatar-xs"
                  src={item.profile.path}
                />
                {item.activity_status == 1 && (
                  <span className="user-status"></span>
                )}
              </span>
            </div>
            <div className="overflow-hidden">
              <p className="text-truncate mb-0">{item.name}</p>
            </div>
          </div>
        </Link>
      </li>
    ));

  return (
    <div className="chat-leftsidebar">
      <div className="tab-content">
        <div className="tab-pane show active">
          <div className="px-4 pt-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="mb-4">{type}</h4>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control border-0 pe-0 radius-0"
                id="serachChatUser"
                placeholder="Search here.."
                aria-label="Example text with button addon"
                aria-describedby="searchbtn-addon"
                autoComplete="off"
              />
              <button
                className="btn bg-white"
                type="button"
                id="searchbtn-addon"
              >
                <i className="bx bx-search align-middle"></i>
              </button>
            </div>
          </div>
          <div className="mb-4" data-simplebar>
            <div className="chat-room-list">
              <div className="chat-message-list">
                <ul className="list-unstyled chat-list chat-user-list mb-3">
                  <li>{groupList}</li>
                </ul>
              </div>
              <div className="d-flex align-items-center px-4 mt-2 mb-2">
                <div className="flex-grow-1">
                  {chatList?.length !== 0 && (
                    <h4 className="mb-0 font-size-11 text-muted text-uppercase">
                      Attendee
                    </h4>
                  )}
                </div>
              </div>
              {chatList?.length !== 0 && (
              <div className="chat-message-list">
                <ul
                  className="list-unstyled chat-list chat-user-list mb-3">
                  {"todo" == splitLocation[1] ? (
                    <li
                      className={
                        "todaystodo" == splitLocation[2] ? "active" : ""
                      }
                    >
                      <Link to={`todaystodo`}>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 avatar-xs me-2">
                            <span className="avatar-title chat-avatar chat-user-img online align-self-center rounded-circle bg-soft-light text-dark">
                              <i className="bx bx-check"></i>
                            </span>
                          </div>
                          <div className="overflow-hidden">
                            <p className="text-truncate mb-0">
                              Today's To-do List
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {"summary" == splitLocation[1] ? (
                    <li
                      className={
                        "todayssummary" == splitLocation[2] ? "active" : ""
                      }
                    >
                      <Link to={`todayssummary`}>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 avatar-xs me-2">
                            <span className="avatar-title chat-avatar chat-user-img online align-self-center rounded-circle bg-soft-light text-dark">
                              <i className="bx bx-check"></i>
                            </span>
                          </div>
                          <div className="overflow-hidden">
                            <p className="text-truncate mb-0">
                              Today's Summary
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {chatList}
                </ul>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftBar;
