function DeleteConfirmation({fElement}) {
    return (
        <div className="modal fade" id="confirmDeleteModal" tabIndex="-1" role="dialog" aria-labelledby="addgroup-exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content modal-header-colored shadow-lg border-0">
                    <div className="modal-header">
                        <h5 className="modal-title text-white font-size-16">Alert!</h5>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div className="modal-body p-4">
                        <h4 className="text-center">Are You Sure! You Want To Do This</h4>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-link" data-bs-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary" onClick={fElement}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default DeleteConfirmation;
  