import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import CreateChannel from "./CreateChannel";
import { getApiCall, loginDetail } from "../data/Index";

function LeftSidebar({ contacts, favoriteContacts, groups, favoriteGroups }) {
  const [group, setGroup] = useState([]);
  const userToken = loginDetail();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  let isMounted = true;
  useEffect(() => {
    setGroup(groups);
  }, [groups]);

  useEffect(() => {
    
    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  async function fetchData() {
    try {
      const r = await getApiCall("getChatters?rundev=rundev");
      if (isMounted) {
        setGroup(r.data.groups);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const favChatList = favoriteContacts?.map((favChat) => (
    <li
      key={`fav-${favChat.id}`}
      className={favChat.employee_id === splitLocation[3] ? "active" : ""}
    >
      <Link to={`./m/${favChat.employee_id}/${favChat.id}`}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs me-2">
            <span className="avatar-title chat-avatar chat-user-img online align-self-center rounded-circle bg-soft-light text-dark">
              <img
                className="rounded-circle avatar-xs"
                src={favChat.profile.path}
                alt={`${favChat.name} avatar`}
              />
              {favChat?.activity_status === 1 && (
                <span className="user-status"></span>
              )}
            </span>
          </div>
          <div className="overflow-hidden">
            <p className="text-truncate mb-0">{favChat.name}</p>
          </div>
          {favChat.total_unseen_chats > 0 && (
            <div className="ms-auto">
              <span className="badge badge-soft-dark rounded p-1">
                {favChat.total_unseen_chats}
              </span>
            </div>
          )}
        </div>
      </Link>
    </li>
  ));

  const favGroupChatList = favoriteGroups?.map((favChat) => (
    <li
      key={`fav-group-${favChat.id}`}
      className={favChat.slug === splitLocation[3] ? "active" : ""}
    >
      <Link to={`./g/${favChat.slug}/${favChat.id}`}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs me-2">
            <span className="avatar-title rounded-circle bg-soft-light text-dark">
              {favChat?.mode === "private" ? (
                <i className="bx bx-lock"></i>
              ) : (
                "#"
              )}
            </span>
          </div>
          <div className="overflow-hidden">
            <p className="text-truncate mb-0">{favChat.name}</p>
          </div>
          {favChat.total_unseen_chats > 0 && (
            <div className="ms-auto">
              <span className="badge badge-soft-dark rounded p-1">
                {favChat.total_unseen_chats}
              </span>
            </div>
          )}
        </div>
      </Link>
    </li>
  ));

  const chatList = contacts?.map((directChat) => (
    <li
      key={`direct-${directChat.id}`}
      className={directChat.employee_id === splitLocation[3] ? "active" : ""}
    >
      <Link to={`./m/${directChat.employee_id}/${directChat.id}`}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs me-2">
            <span className="avatar-title chat-avatar chat-user-img online align-self-center rounded-circle bg-soft-light text-dark">
              <img
                className="rounded-circle avatar-xs"
                src={directChat.profile.path}
                alt={`${directChat.name} avatar`}
              />
              {directChat.activity_status === 1 && (
                <span className="user-status"></span>
              )}
            </span>
          </div>
          <div className="overflow-hidden">
            <p className="text-truncate mb-0">{directChat.name}</p>
          </div>
          {directChat.total_unseen_chats > 0 && (
            <div className="ms-auto">
              <span className="badge badge-soft-dark rounded p-1">
                {directChat.total_unseen_chats}
              </span>
            </div>
          )}
        </div>
      </Link>
    </li>
  ));

  const groupList = group?.map((groupChat) => (
    <li
      key={`group-${groupChat.id}`}
      className={groupChat.slug === splitLocation[3] ? "active" : ""}
    >
      <Link to={`./g/${groupChat.slug}/${groupChat.id}`}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs me-2">
            <span className="avatar-title rounded-circle bg-soft-light text-dark">
              {groupChat?.mode === "private" ? (
                <i className="bx bx-lock"></i>
              ) : (
                "#"
              )}
            </span>
          </div>
          <div className="overflow-hidden">
            <p className="text-truncate mb-0">{groupChat.name}</p>
          </div>
          {groupChat.total_unseen_chats > 0 && (
            <div className="ms-auto">
              <span className="badge badge-soft-dark rounded p-1">
                {groupChat.total_unseen_chats}
              </span>
            </div>
          )}
        </div>
      </Link>
    </li>
  ));

  return (
    <div className="chat-leftsidebar">
      <div className="tab-content">
        <div className="tab-pane show active">
          <div className="px-4 pt-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="mb-4">Channel</h4>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control border-0 pe-0 radius-0"
                id="serachChatUser"
                placeholder="Search here.."
                aria-label="Example text with button addon"
                aria-describedby="searchbtn-addon"
                autoComplete="off"
              />
              <button
                className="btn bg-white"
                type="button"
                id="searchbtn-addon"
              >
                <i className="bx bx-search align-middle"></i>
              </button>
            </div>
          </div>
          <div className="chat-room-list" data-simplebar>
            <div className="d-flex align-items-center px-4 mt-2 mb-2">
              {favoriteContacts?.length > 0 || favoriteGroups?.length > 0 ? (
                <div className="flex-grow-1">
                  <h4 className="mb-0 font-size-11 text-muted text-uppercase">
                    Favourites
                  </h4>
                </div>
              ) : null}
            </div>
            <div className="chat-message-list">
              {favoriteContacts?.length > 0 || favoriteGroups?.length > 0 ? (
                <ul
                  className="list-unstyled chat-list chat-user-list mb-3"
                  id="favourite-users"
                >
                  {favoriteContacts?.length > 0 && favChatList}
                  {favoriteGroups?.length > 0 && favGroupChatList}
                </ul>
              ) : null}
            </div>
            {userToken.user.employee_type === "inhouse" && (
              <>
                <div className="d-flex align-items-center px-4 mt-2 mb-2">
                  {chatList?.length !== 0 ? (
                    <div className="flex-grow-1">
                      <h4 className="mb-0 font-size-11 text-muted text-uppercase">
                        Direct Messages
                      </h4>
                    </div>
                  ) : null}
                </div>
                <div className="chat-message-list">
                  <ul
                    className="list-unstyled chat-list chat-user-list mb-3"
                    id="direct-users"
                  >
                    {chatList}
                  </ul>
                </div>
              </>
            )}

            <div className="d-flex align-items-center px-4 mt-2 mb-2">
              <div className="flex-grow-1">
                <h4 className="mb-0 font-size-11 text-muted text-uppercase">
                  Channels
                </h4>
              </div>
              <div className="flex-shrink-0">
                {userToken.user.employee_type === "inhouse" && (
                  <div
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="bottom"
                    title="Create group"
                  >
                    <button
                      type="button"
                      className="btn btn-soft-primary btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#addgroupModal"
                    >
                      <i className="bx bx-plus"></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="chat-message-list">
              <ul
                className="list-unstyled chat-list chat-user-list mb-3"
                id="channelList"
              >
                {groupList}
              </ul>
            </div>
          </div>
          <CreateChannel fetchData={fetchData} />
        </div>
      </div>
    </div>
  );
}

export default LeftSidebar;
