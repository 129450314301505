import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getApiCall, postApiCall, loginDetail } from "../data/Api";
import InfiniteScroll from "react-infinite-scroll-component";
import FullScreenLoader from '../../FullScreenLoader';

function AttendeeTodo() {

  const Today = new Date().toISOString().split("T")[0];
  const week = new Date();
  week.setDate(week.getDate() - 7);
  let Week = week.toISOString().split("T")[0];

  const [SummeryData, setSummeryData] = useState("");
  const [mergedProjects, setMergedProjects] = useState([]);
  const [sections, setSections] = useState([]);
  const [userData, setUserData] = useState("");
  const [FromData, setFromData] = useState(Week);
  const [ToData, setToData] = useState(Today);
  const [loading, setLoading] = useState(true);
  const chatSection = useRef(null);
  const { id } = useParams();
  const [fireBottom, setFireBottom] = useState(1);

  useEffect(() => {
    handleSummeryData();
  }, [id]);
  useEffect(() => {
    chatSection.current?.scrollIntoView({ block: "end" });
  }, [fireBottom]);
  const scrollToBottom = () => {
    setFireBottom(fireBottom + 1);
  };

  const handleSummeryData = async () => {
    setLoading(true);
    const response = await getApiCall(
      "get-todos?rundev=rundev&user_id=" +
        id +
        "&start_date=" +
        FromData +
        "&end_date=" +
        ToData
    );
    if (response.status) {
      setLoading(false);
      setSections(response.data);
      scrollToBottom();
    } else {
      setLoading(false);
      setSections([]);
    }
  };

  const handleResetData = async () => {
    setFromData(Week);
    setToData(Today);
    handleSummeryData();
    setLoading(true);
    const response = await getApiCall(
      "get-todos?rundev=rundev&user_id=" +
        id +
        "&start_date=" +
        Week +
        "&end_date=" +
        Today
    );
    if (response.status) {
      setLoading(false);
      setSections(response.data);
      scrollToBottom();
    }else{
      setLoading(false);
      setSections([]);
    }
  };

  return (
    <>
    {loading ? <FullScreenLoader /> :
      <div className="bg-white user-chat w-100 overflow-hidden">
        <div className="user-chat-overlay"></div>

        <div className="chat-content d-lg-flex">
          <div className="w-100 overflow-hidden position-relative">
            <div id="users-chat" className="employe-report-header position-relative p-2">
              <div className="p-1 p-lg-2">
                <div className="row align-items-center">
                  <div className="col-sm-4 col-8">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 d-block d-lg-none me-3">
                        <Link to="/todo">
                          <a
                            href="#"
                            className="user-chat-remove font-size-18 p-1"
                          >
                            <i className="bx bx-chevron-left align-middle"></i>
                          </a>
                        </Link>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <h6 className="text-truncate mb-0 font-size-18">
                              <a
                                href="#"
                                className="user-profile-show text-reset"
                              >
                                To-do
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-8 col-4">
                    <ul className="list-inline user-chat-nav text-end mb-0">
                      <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                        <button
                          type="button"
                          className="btn nav-btn user-profile-show-1"
                        >
                          <i className="bx bx-search"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-white chat-conversation"
              id="chat-conversation"
              data-simplebar
              style={{ height: "calc(100vh - 100px)" }}
            >
              <div className="container mt-4 mb-1">
                
                {sections?.map((section, index) => (
                  <>
                    <h6 className="font-weight-bold theme-color">
                      {section.date}
                    </h6>
                    <table className="w-100">
                      <thead>
                        <tr>
                        <th style={{ width: "80%" }}>Activity</th>
                        <th>Hours</th>
                        <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {section?.todo?.map((row, indexRow) => (
                          <tr key={indexRow}>
                            <td>{row.task_name}</td>
                            <td>{row.expected_hours}</td>
                            <td>{row.done===1 ? "Done" : (row.done===2? "Currently Working":"Pending")}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <hr className="my-4"></hr>
                  </>
                ))}
              </div>
              <div ref={chatSection}></div>
            </div>
          </div>
          <div className="user-profile-sidebar" id="user-profile-sidebar">
            <div className="p-4 user-profile-desc" data-simplebar>
            <div className="text-center"><button type="button" className="btn btn-light user-profile-show d-none d-lg-block"><i className="bx bx-x"></i></button></div>
              <h4 className="mt-3">Filter</h4>
              <hr className="mb-4" />
              <div className="row">
                <div className="col-sm-12">
                  <div className="mb-4">
                    <label>From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Start Date"
                      onChange={(e) => setFromData(e.target.value)}
                      max={Today}
                      value={FromData}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="mb-4">
                    <label>To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="End Date"
                      onChange={(e) => setToData(e.target.value)}
                      max={Today}
                      value={ToData}
                    />
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div className="">
                    <div className="mb-4">
                      <input
                        type="button"
                        className="btn btn-primary"
                        value="Filter"
                        onClick={() => handleSummeryData()}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="mb-4">
                      <input
                        type="button"
                        className="btn btn-warning"
                        value="Reset Filter"
                        onClick={() => handleResetData()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    </>
  );
}

export default AttendeeTodo;
