import React, { useEffect, useState } from "react";
import LeftBar from "./component/LeftBar";
import { Outlet, useLocation } from 'react-router-dom';

function TodoLanding({dashboard}) {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    
    return (
    <>
        <LeftBar type='To-do list' link='m' />
        <Outlet />
        {typeof splitLocation[2]=='undefined' &&
            <div className="container bg-white">
                <div className="row mt-3">
                    <div className="col-sm-12 text-center p-5">
                        <h1>Connect, Grow and Inspire.</h1>
                        <p>Manage your summary in an agile way.<br /> Using your digital channel in one place.</p>
                        
                    </div>
                </div>
            </div>
        }
    </>
    );
}

export default TodoLanding;