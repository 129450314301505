import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyADiwC2_ML2EeI9lcSjJU7JvI6e06c98tg",
  authDomain: "dcm-system-7d992.firebaseapp.com",
  projectId: "dcm-system-7d992",
  storageBucket: "dcm-system-7d992.appspot.com",
  messagingSenderId: "181516427576",
  appId: "1:181516427576:web:dc781e77d854e18eaea1cd"
};
//measurementId: "G-7LMG972VTP",

// Initialize Firebase
const firebaseapp = initializeApp(firebaseConfig);
//const analytics = getAnalytics(firebaseapp);
export const messaging = getMessaging(firebaseapp);
